export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Email: { input: GQL_Client_Email; output: GQL_Client_Email };
  JSON: { input: GQL_Client_JSON; output: GQL_Client_JSON };
  JSONObject: { input: GQL_Client_JSONObject; output: GQL_Client_JSONObject };
  LocalDate: { input: GQL_Client_LocalDate; output: GQL_Client_LocalDate };
  LocalTime: { input: GQL_Client_LocalTime; output: GQL_Client_LocalTime };
  NonEmptyString: {
    input: GQL_Client_NonEmptyString;
    output: GQL_Client_NonEmptyString;
  };
  NonNegativeFloat: {
    input: GQL_Client_NonNegativeFloat;
    output: GQL_Client_NonNegativeFloat;
  };
  NonNegativeInt: {
    input: GQL_Client_NonNegativeInt;
    output: GQL_Client_NonNegativeInt;
  };
  PhoneNumber: {
    input: GQL_Client_PhoneNumber;
    output: GQL_Client_PhoneNumber;
  };
  PositiveFloat: {
    input: GQL_Client_PositiveFloat;
    output: GQL_Client_PositiveFloat;
  };
  PositiveInt: {
    input: GQL_Client_PositiveInt;
    output: GQL_Client_PositiveInt;
  };
  TrimmedNonEmptyString: {
    input: GQL_Client_TrimmedNonEmptyString;
    output: GQL_Client_TrimmedNonEmptyString;
  };
  UUID: { input: GQL_Client_UUID; output: GQL_Client_UUID };
  Upload: { input: GQL_Client_Upload; output: GQL_Client_Upload };
  Username: { input: GQL_Client_Username; output: GQL_Client_Username };
  ZipCode: { input: GQL_Client_ZipCode; output: GQL_Client_ZipCode };
};

/**
 * An "accounting system", i.e. a system that an mgmt company uses to manage some
 * of its data (especially related to accounting use cases). HomeWiseDocs
 * integrates with these accounting systems. We can import data into HWD and push
 * data from HWD to the accounting system.
 */
export type AccountingSystem = {
  __typename: "AccountingSystem";
  code: AccountingSystemCode;
  /**
   * List of data sources this accounting system provides. Some systems only
   * enable us to import certain kinds of data.
   */
  dataSources: Array<AccountingSystemDataSource>;
  displayName: Scalars["String"]["output"];
};

export enum AccountingSystemCode {
  AppFolio = "AppFolio",
  Asyst = "Asyst",
  Buildium = "Buildium",
  C3 = "C3",
  CINC = "CINC",
  Caliber = "Caliber",
  EUnify = "EUnify",
  Jenark = "Jenark",
  TOPS = "TOPS",
  VMS = "VMS",
  Vantaca = "Vantaca",
}

export enum AccountingSystemDataSource {
  DEMAND = "DEMAND",
  OWNER = "OWNER",
  QUESTIONNAIRE = "QUESTIONNAIRE",
}

/**
 * Error codes that can result from attempting to import data from an accounting
 * system.
 *
 * Note: The accounting system APIs typically don't provide explicit, actionable
 * errors. As a result, in many cases, we have to infer what we _think_ caused
 * the error and try to select the best error code to represent that case. So if
 * the client receives an error code of this type, it shouldn't view the error as
 * gospel. In particular, any user-facing error messages shouldn't be overly
 * prescriptive.
 */
export enum AccountingSystemImportErrorCode {
  /**
   * The accounting system API responded with a 404 HTTP status code.
   *
   * This may mean the acctg system failed to find the HOA or that there is a
   * config error, e.g. we have the wrong connection URL in HWD.
   *
   * However, it might also indicate a bug within the acctg system, e.g. their
   * server's routing is broken.
   */
  ACCTG_SYSTEM_404 = "ACCTG_SYSTEM_404",
  /**
   * Auth error, e.g. the API responded with a 401 or 403 HTTP status code...or
   * the content of the response otherwise seems to indicate that the credentials
   * were invalid.
   */
  ACCTG_SYSTEM_AUTH_ERROR = "ACCTG_SYSTEM_AUTH_ERROR",
  ACCTG_SYSTEM_FAILED_TO_FIND_HOA = "ACCTG_SYSTEM_FAILED_TO_FIND_HOA",
  /**
   * Any other type of error caused by a request to the accounting system. For
   * example, this may represent a 4xx/5xx response code (except for those types
   * of errors that already have specific/dedicated enum members).
   */
  ACCTG_SYSTEM_GENERIC_ERROR = "ACCTG_SYSTEM_GENERIC_ERROR",
  ACCTG_SYSTEM_RETURNED_MULTIPLE_HOAS = "ACCTG_SYSTEM_RETURNED_MULTIPLE_HOAS",
  /**
   * The accounting system's API failed to return data in a reasonable amount of
   * time, so we aborted the request.
   */
  ACCTG_SYSTEM_TIMED_OUT = "ACCTG_SYSTEM_TIMED_OUT",
  /**
   * Most accounting system APIs use a "code" or ID to identify the HOA. However,
   * we don't currently require the mgmt company to enter each HOA's accounting
   * system code. If the mgmt company hasn't entered the HOA's code, the server
   * will return this error.
   */
  HOA_CODE_MISSING = "HOA_CODE_MISSING",
  /** Auth error related to HWD, e.g. the user lacks the required permission(s). */
  HWD_AUTH_ERROR = "HWD_AUTH_ERROR",
  /**
   * An internal HomeWiseDocs server error that was _not_ caused by the
   * accounting system.
   */
  HWD_ERROR = "HWD_ERROR",
  /**
   * The company (or possibly the HOA) hasn't been set up to integrate with an
   * accounting system. In this case, the client probably should not have allowed
   * the user to try to import data from the accounting system.
   */
  INTEGRATION_NOT_CONFIGURED = "INTEGRATION_NOT_CONFIGURED",
  /**
   * The data in the response was invalid.
   *
   * Example causes:
   * - a required field is missing
   * - a value's type is invalid
   * - a value is nonsense (e.g. min assessment amount exceeds max assessment amount)
   */
  INVALID_ACCTG_SYSTEM_RESPONSE_DATA = "INVALID_ACCTG_SYSTEM_RESPONSE_DATA",
  /**
   * The content of the API response was malformed and could not be parsed.
   *
   * For example, if an XML-based API returns a malformed XML document with a
   * missing closing tag, we'll return this code.
   */
  MALFORMED_ACCTG_SYSTEM_RESPONSE = "MALFORMED_ACCTG_SYSTEM_RESPONSE",
}

export type AcknowledgeHomeownerPrivacyRightsError = BaseMutationError & {
  __typename: "AcknowledgeHomeownerPrivacyRightsError";
  code: AcknowledgeHomeownerPrivacyRightsErrorCode;
  message: Scalars["String"]["output"];
};

export enum AcknowledgeHomeownerPrivacyRightsErrorCode {
  REQUESTOR_ALREADY_ACKNOWLEDGED = "REQUESTOR_ALREADY_ACKNOWLEDGED",
  REQUESTOR_NOT_FOUND = "REQUESTOR_NOT_FOUND",
}

export type AcknowledgeHomeownerPrivacyRightsMutationResponse =
  MutationResponse & {
    __typename: "AcknowledgeHomeownerPrivacyRightsMutationResponse";
    error: Maybe<AcknowledgeHomeownerPrivacyRightsError>;
    success: Scalars["Boolean"]["output"];
  };

export type Acknowledgement = {
  __typename: "Acknowledgement";
  id: Scalars["ID"]["output"];
  message: Scalars["String"]["output"];
};

export type AddNewUserToRequestorOrgMutationInput = {
  city: Scalars["TrimmedNonEmptyString"]["input"];
  email: Scalars["Email"]["input"];
  firstName: Scalars["TrimmedNonEmptyString"]["input"];
  lastName: Scalars["TrimmedNonEmptyString"]["input"];
  password: Scalars["NonEmptyString"]["input"];
  phone: Scalars["PhoneNumber"]["input"];
  state: Scalars["TrimmedNonEmptyString"]["input"];
  streetAddress: Scalars["TrimmedNonEmptyString"]["input"];
  username: Scalars["Username"]["input"];
  zip: Scalars["ZipCode"]["input"];
};

export type AddNewUserToRequestorOrgResponse = MutationResponse & {
  __typename: "AddNewUserToRequestorOrgResponse";
  error: Maybe<GenericMutationError>;
  /**
   * The user who was just added to the account (if the mutation succeeded).
   * This allows the client to update its list of users for this org in the cache
   */
  newUser: Maybe<Requestor>;
  success: Scalars["Boolean"]["output"];
};

export enum AddRushErrorCode {
  CREDIT_CARD_INFO_REQUIRED = "CREDIT_CARD_INFO_REQUIRED",
  DOCS_ONLY_ORDER = "DOCS_ONLY_ORDER",
  HOA_NOT_ACTIVE = "HOA_NOT_ACTIVE",
  INACTIVE_RUSH_OPTION = "INACTIVE_RUSH_OPTION",
  MGMT_CO_COMPLETED_SOME_PRODUCT = "MGMT_CO_COMPLETED_SOME_PRODUCT",
  NO_PROCESSING_REQUIRED = "NO_PROCESSING_REQUIRED",
  NO_RUSH_OPTIONS_AVAILABLE = "NO_RUSH_OPTIONS_AVAILABLE",
  ORDER_ALREADY_RUSHED = "ORDER_ALREADY_RUSHED",
  ORDER_COMPLETED = "ORDER_COMPLETED",
  ORDER_CONTAINS_LSQ = "ORDER_CONTAINS_LSQ",
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  PAYMENT_ERROR = "PAYMENT_ERROR",
  PRICING_CHANGED = "PRICING_CHANGED",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type AddRushInput = {
  /**
   * Note: This input is nullable in case the rush option is free (which should
   * be very uncommon) or due At Close.
   */
  creditCardInfo?: InputMaybe<CreditCardInfo>;
  orderUUID: Scalars["UUID"]["input"];
  rushId: Scalars["ID"]["input"];
};

export type AddRushMutationData = {
  __typename: "AddRushMutationData";
  dueDate: Scalars["String"]["output"];
  orderUUID: Scalars["String"]["output"];
  paidAt: Scalars["String"]["output"];
  paymentMethod: PaymentMethod;
  rushAddedAt: Scalars["String"]["output"];
  totalAtCloseFee: Scalars["Float"]["output"];
  totalPaid: Scalars["Float"]["output"];
  totalPrice: Scalars["Float"]["output"];
  totalUpFrontFee: Scalars["Float"]["output"];
};

export type AddRushMutationData_dueDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddRushMutationData_paidAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddRushMutationData_rushAddedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddRushMutationError = BaseMutationError & {
  __typename: "AddRushMutationError";
  code: AddRushErrorCode;
  message: Scalars["String"]["output"];
};

export type AddRushMutationResponse = MutationResponse & {
  __typename: "AddRushMutationResponse";
  error: Maybe<AddRushMutationError>;
  success: Scalars["Boolean"]["output"];
  /**
   * The fields in the order that have been updated as a result of adding the rush.
   * This is useful for the client to update its cache.
   * Note: the client should update hasRush and addedRushOptions itself.
   */
  updatedOrderData: Maybe<AddRushMutationData>;
};

export type AddRushPricingSummary = {
  __typename: "AddRushPricingSummary";
  /**
   * The amount of the credit card fee if payment is due up front and the fee
   * is charged for this association. Otherwise, this should be null.
   */
  creditCardFee: Maybe<Price>;
  paymentDue: PaymentDueType;
  refundPolicyInfo: RefundPolicyInfo;
  rush: PricingSummaryBreakdown;
  /**
   * If we're charging tax on this order, this is a human-friendly name of the
   * tax. If no tax, this should be null.
   */
  taxDisplayName: Maybe<Scalars["String"]["output"]>;
  total: PricingSummaryBreakdown;
};

export type AddRushPricingSummaryError = {
  __typename: "AddRushPricingSummaryError";
  code: AddRushPricingSummaryErrorCode;
  message: Scalars["String"]["output"];
};

export enum AddRushPricingSummaryErrorCode {
  DOCS_ONLY_ORDER = "DOCS_ONLY_ORDER",
  HOA_NOT_ACTIVE = "HOA_NOT_ACTIVE",
  INACTIVE_RUSH_OPTION = "INACTIVE_RUSH_OPTION",
  MGMT_CO_COMPLETED_SOME_PRODUCT = "MGMT_CO_COMPLETED_SOME_PRODUCT",
  NO_PROCESSING_REQUIRED = "NO_PROCESSING_REQUIRED",
  NO_RUSH_OPTIONS_AVAILABLE = "NO_RUSH_OPTIONS_AVAILABLE",
  ORDER_ALREADY_RUSHED = "ORDER_ALREADY_RUSHED",
  ORDER_COMPLETED = "ORDER_COMPLETED",
  ORDER_CONTAINS_LSQ = "ORDER_CONTAINS_LSQ",
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type AddRushPricingSummaryResult =
  | AddRushPricingSummary
  | AddRushPricingSummaryError;

/**
 * If there are any available rush options that can be added to the order, contains all of the
 * available rush options. Else, contains the reason why a rush cannot be added to the order.
 */
export type AddedRushOptionsInfo = {
  __typename: "AddedRushOptionsInfo";
  addedRushOptions: Maybe<Array<RushOption>>;
  isAddedRushAvailable: Scalars["Boolean"]["output"];
  reasonNotAvailable: Maybe<Scalars["String"]["output"]>;
};

export type Address = {
  __typename: "Address";
  city: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
  streetAddress: Scalars["String"]["output"];
  unit: Maybe<Scalars["String"]["output"]>;
  zip: Scalars["String"]["output"];
};

export enum AddressStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  INACTIVE_HOA = "INACTIVE_HOA",
}

export type AddressVerificationError = {
  __typename: "AddressVerificationError";
  errorCode: AddressVerificationErrorCode;
};

export enum AddressVerificationErrorCode {
  /**
   * Indicates that we were unable to verify that the address exists. The
   * address _might_ technically exist even though we couldn't verify it. For
   * example, it might be a lot sale, in which case the "address" would
   * essentially be a placeholder like "Lot #3".
   */
  ADDRESS_NOT_FOUND = "ADDRESS_NOT_FOUND",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type AddressVerificationResult =
  | AddressVerificationError
  | VerifiedHomeWiseAddress
  | VerifiedHomeWiseAddressWrongHOA
  | VerifiedPostalAddress;

export type AllOrders = {
  __typename: "AllOrders";
  orders: Array<Order>;
  totalNumOrders: Scalars["Int"]["output"];
};

export type Announcement = {
  __typename: "Announcement";
  /**
   * ID of the announcement. The client can use this to determine if the user has already
   * seen the announcement (in case we only want to show it once).
   */
  id: Scalars["ID"]["output"];
  /** The announcement interval. Includes start and end dates, both in HWD_TIME_ZONE. */
  interval: DateTimeInterval;
  message: Scalars["String"]["output"];
};

export enum ApiRequestorOrgType {
  APPRAISER = "APPRAISER",
  CLOSING_AGENT = "CLOSING_AGENT",
  COMMERCIAL_INSURANCE = "COMMERCIAL_INSURANCE",
  HOMEOWNER = "HOMEOWNER",
  LENDER = "LENDER",
  OTHER = "OTHER",
  REALTOR = "REALTOR",
}

export type AttachDocumentToOrderMutationResponse = MutationResponse & {
  __typename: "AttachDocumentToOrderMutationResponse";
  attachment: Maybe<OrderAttachment>;
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type BaseMutationError = {
  message: Scalars["String"]["output"];
};

/**
 * Includes the types of questions that can be created and updated through the
 * admin interface.
 */
export type BasicFollowUpQnrQuestion =
  | FollowUpDropdownQnrQuestion
  | FollowUpMultiSelectQnrQuestion
  | FollowUpTextLikeQnrQuestion;

export type BasicSaveQnrDataError = BaseMutationError & {
  __typename: "BasicSaveQnrDataError";
  code: BasicSaveQnrDataErrorCode;
  message: Scalars["String"]["output"];
};

export enum BasicSaveQnrDataErrorCode {
  CONTACT_NOT_FOUND = "CONTACT_NOT_FOUND",
  EMPLOYEE_NOT_ELIGIBLE_TO_BE_CONTACT = "EMPLOYEE_NOT_ELIGIBLE_TO_BE_CONTACT",
  HOA_NOT_FOUND = "HOA_NOT_FOUND",
  INPUT_INVALID = "INPUT_INVALID",
  LSQ_ALREADY_SENT_TO_HWD_FORMS = "LSQ_ALREADY_SENT_TO_HWD_FORMS",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  /**
   * We were unable to find the product and/or related info (such as the HOA)
   * using the specified order product UUID.
   */
  ORDER_PRODUCT_INFO_NOT_FOUND = "ORDER_PRODUCT_INFO_NOT_FOUND",
  SYSTEM_ERROR = "SYSTEM_ERROR",
  USER_NOT_ASSIGNED_TO_QNR_FOR_HOA = "USER_NOT_ASSIGNED_TO_QNR_FOR_HOA",
  USER_NOT_FOUND = "USER_NOT_FOUND",
}

export type BatchGroupAmountsByTimeframeInput = {
  batchGroupUUID: Scalars["UUID"]["input"];
  /** The start date of the timeframe. */
  fromDate: CalendarDateInput;
  /** The end date of the timeframe. */
  toDate: CalendarDateInput;
};

export type BulkSaveStructuralSafetyInput = {
  mgmtCompanyUUID: Scalars["ID"]["input"];
  questionsToSave: Array<SaveStructuralSafetyQuestionInput>;
};

export type BulkSaveStructuralSafetyMutationResponse = {
  __typename: "BulkSaveStructuralSafetyMutationResponse";
  success: Scalars["Boolean"]["output"];
};

export type Bundle = ProductInterface & {
  __typename: "Bundle";
  description: Maybe<Scalars["String"]["output"]>;
  /**
   * If true, display the bundles savings in the requestor order process. There are 2 cases where
   * we should not display savings:
   * 1) savings are non-positive
   * 2) the bundle contains a form which the user type can't order a la carte. In that case, it would
   * be somewhat misleading to show savings, because the requestor wouldn't have an alternative to the
   * bundle anyways.
   */
  displaySavings: Scalars["Boolean"]["output"];
  hasMissingDoc: Scalars["Boolean"]["output"];
  hwdFee: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  items: Array<BundleItem>;
  label: Scalars["String"]["output"];
  mgmtCompanyFee: Scalars["Float"]["output"];
  paymentDue: PaymentDueType;
  popupMessage: Maybe<Scalars["String"]["output"]>;
  price: Scalars["Float"]["output"];
  priceBeforeDiscount: Scalars["Float"]["output"];
  /** See ProductInterface */
  separateHwdFeeLabel: Maybe<Scalars["String"]["output"]>;
};

export type BundleItem = {
  __typename: "BundleItem";
  category: ProductCategoryType;
  id: Scalars["ID"]["output"];
  isForm: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  missing: Scalars["Boolean"]["output"];
  productId: Scalars["ID"]["output"];
};

export type Buyer = {
  __typename: "Buyer";
  addressInfo: Maybe<BuyerAddress>;
  email: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  fullName: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  phone: Maybe<Scalars["String"]["output"]>;
};

export type BuyerAddress = {
  __typename: "BuyerAddress";
  city: Maybe<Scalars["String"]["output"]>;
  country: Maybe<Scalars["String"]["output"]>;
  state: Maybe<Scalars["String"]["output"]>;
  streetAddress: Maybe<Scalars["String"]["output"]>;
  unit: Maybe<Scalars["String"]["output"]>;
  zip: Maybe<Scalars["String"]["output"]>;
};

export type BuyerInput = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  stateOrProvince?: InputMaybe<Scalars["String"]["input"]>;
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
  unit?: InputMaybe<Scalars["String"]["input"]>;
  zipOrPostalCode?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * Input data for a calendar date. Useful when you don't care about an actual timestamp, and you
 * just want a timezone-independent calendar date.
 */
export type CalendarDateInput = {
  /** The year, e.g. 2018. */
  fullYear: Scalars["Int"]["input"];
  /**
   * 1-based index of the day of the month.
   * For example, this should be 1 on the 1st day of the month and 2 on the 2nd day of the month.
   */
  oneIndexedDayOfMonth: Scalars["Int"]["input"];
  /**
   * 0-based index of the month. For example, January corresponds to 0 and December corresponds to 11.
   * We use a 0-based month index b/c JavaScript's getMonth() method uses a 0-based index.
   */
  zeroIndexedMonth: Scalars["Int"]["input"];
};

export enum CancelOrderErrorCode {
  DOC_COMPLETED = "DOC_COMPLETED",
  HWD_PROCESSING_LSQ = "HWD_PROCESSING_LSQ",
  /** The order is on hold or the manager has started processing a form in the order. */
  ON_HOLD = "ON_HOLD",
  ORDER_ALREADY_CANCELED = "ORDER_ALREADY_CANCELED",
  ORDER_COMPLETED = "ORDER_COMPLETED",
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
}

export type CancelOrderMutationError = BaseMutationError & {
  __typename: "CancelOrderMutationError";
  code: CancelOrderErrorCode;
  message: Scalars["String"]["output"];
};

export type CancelOrderMutationResponse = MutationResponse & {
  __typename: "CancelOrderMutationResponse";
  error: Maybe<CancelOrderMutationError>;
  success: Scalars["Boolean"]["output"];
};

/**
 * TODO: return a string to the client instead of the enum.
 * Then replace the cancelOrder mutation's enum input with only the
 * requestor-facing options (i.e. the 1st 5 enum members).
 */
export enum CancellationReason {
  ACCOUNT_IN_COLLECTIONS = "ACCOUNT_IN_COLLECTIONS",
  ADDRESS_BELONGS_TO_ANOTHER_HOA = "ADDRESS_BELONGS_TO_ANOTHER_HOA",
  ADDRESS_MISSING_UNIT = "ADDRESS_MISSING_UNIT",
  DUPLICATE_ORDER = "DUPLICATE_ORDER",
  HOA_MANAGED_BY_OTHER_COMPANY = "HOA_MANAGED_BY_OTHER_COMPANY",
  HOA_NO_LONGER_MANAGED = "HOA_NO_LONGER_MANAGED",
  INCORRECT_ADDRESS = "INCORRECT_ADDRESS",
  INCORRECT_HOA = "INCORRECT_HOA",
  INCORRECT_ORDER_ITEMS = "INCORRECT_ORDER_ITEMS",
  INCORRECT_SELLER_NAME = "INCORRECT_SELLER_NAME",
  MUST_ORDER_FROM_SUB_HOA = "MUST_ORDER_FROM_SUB_HOA",
  OTHER = "OTHER",
  TRANSACTION_CANCELLED = "TRANSACTION_CANCELLED",
}

export type CancelledOrder = Order & {
  __typename: "CancelledOrder";
  /**
   * The time when the order was acknowledged (if there was an acknowledgement).
   * Restricted to authenticated users to limit PII disclosure.
   */
  acknowledgedAt: Maybe<Scalars["String"]["output"]>;
  /**
   * The notice that the requestor acknowledged when they placed the order.
   * Restricted to authenticated users to limit PII disclosure.
   */
  acknowledgedNotice: Maybe<Scalars["String"]["output"]>;
  /**
   * Suppose the user didn't use rush processing when they placed the order, but
   * now they'd like to add rush processing to the order. Then, we should use
   * this resolver to figure out what rush options, if any, are available. If there
   * aren't any rush options, this resolver can be used to tell the user the reason.
   */
  addedRushOptionsInfo: AddedRushOptionsInfo;
  attachments: Array<OrderAttachment>;
  bundles: Array<OrderBundle>;
  buyer: Maybe<Buyer>;
  /**
   * If true, the buyer intends to move into the property. Only applies to resale
   * transactions. Restricted to authenticated users to limit PII disclosure.
   */
  buyerOccupied: Maybe<Scalars["Boolean"]["output"]>;
  /** Restricted to authenticated users to limit PII disclosure. */
  cancellationComments: Maybe<Scalars["String"]["output"]>;
  cancellationDate: Scalars["String"]["output"];
  /**
   * Reason for canceling the order.
   * Note: The corresponding input is now non-nullable. However, this wasn't
   * always the case. As a result, this field must be nullable.
   */
  cancellationReason: Maybe<CancellationReason>;
  confirmationEmailSentTo: Scalars["String"]["output"];
  confirmationNumber: Scalars["String"]["output"];
  currentOwner: CurrentOwner;
  /**
   * The estimated closing date of the real estate transaction. We don't always collect this field.
   * For example, we don't ask the requestor to enter it if the order doesn't contain any forms.
   *
   * Note: We do NOT need to convert this to HWD's timezone using a schema directive. It's an HWD
   * calendar date, so it's inherently in HWD's timezone already.
   */
  estimatedClosingDate: Maybe<Scalars["String"]["output"]>;
  hasRush: Scalars["Boolean"]["output"];
  hoa: HOA;
  individualItems: Array<OrderItem>;
  /**
   * The loan #. This only applies if the user ordered a questionnaire. Restricted to
   * authenticated users to limit PII disclosure.
   */
  loanNumber: Maybe<Scalars["String"]["output"]>;
  orderDate: Scalars["String"]["output"];
  paidAt: Maybe<Scalars["String"]["output"]>;
  paymentMethod: PaymentMethod;
  presaleDocumentsInfo: Maybe<Array<PresaleDocumentsGroup>>;
  /**
   * URL that can be used to view the order's receipt (which we often refer to as "order statement" since
   * it's technically not a receipt).
   * Unless the order is still awaiting payment, then this field should be non-null.
   */
  receiptUrl: Maybe<Scalars["String"]["output"]>;
  requestor: Requestor;
  resaleFormOptionValues: Maybe<Array<ResaleFormOptionValue>>;
  rushAddedAt: Maybe<Scalars["String"]["output"]>;
  shareWithEmails: Maybe<Array<Scalars["String"]["output"]>>;
  shippingInfo: Maybe<ShippingInfo>;
  subjectPropertyAddress: Scalars["String"]["output"];
  totalAtCloseFee: Scalars["Float"]["output"];
  totalPaid: Scalars["Float"]["output"];
  totalPrice: Scalars["Float"]["output"];
  /**
   * Total amount of money that was due at up front (USD).
   * Note: this doesn't necessarily mean the up front fees have been paid yet. For example, this
   * fee still has to be paid for order that are still awaiting payment (e.g. 3rd party pay)
   */
  totalUpFrontFee: Scalars["Float"]["output"];
  transactionType: TransactionType;
  upFrontPayStatus: UpFrontPayStatus;
  uuid: Scalars["String"]["output"];
};

export type CancelledOrder_acknowledgedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CancelledOrder_cancellationDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CancelledOrder_estimatedClosingDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CancelledOrder_orderDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CancelledOrder_paidAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CancelledOrder_rushAddedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CancelledOrders = {
  __typename: "CancelledOrders";
  orders: Array<CancelledOrder>;
  totalNumOrders: Scalars["Int"]["output"];
};

export type CantUpdateReason = {
  __typename: "CantUpdateReason";
  code: RequestOrderUpdateErrorCode;
  reason: Scalars["String"]["output"];
};

export type ChangePasswordMutationResponse = MutationResponse & {
  __typename: "ChangePasswordMutationResponse";
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

export enum ClientAppType {
  MANAGER_APP = "MANAGER_APP",
  REQUESTOR_APP = "REQUESTOR_APP",
}

export type CommercialUseQnrDataInput = {
  squareFeet: Scalars["Float"]["input"];
  units: Scalars["Int"]["input"];
  useType: CommercialUseType;
  /**
   * If the user selected 'OTHER' as the type of commercial use, this field
   * should represent the type of use as a write-in.
   *
   * OTOH, if the user did _not_ select 'OTHER', then this should be null.
   */
  writeInUseType?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * If 'dirty' is true, 'allUses' should be a non-empty array describing each type
 * of commercial use.
 *
 * If 'dirty' is false, 'allUses' should be null (it will be ignored).
 */
export type CommercialUseResponseSaveQnrDataInput = {
  allUses?: InputMaybe<Array<CommercialUseQnrDataInput>>;
  dirty: Scalars["Boolean"]["input"];
};

export type CommercialUseSaveQnrDataInput = {
  comment: StringFieldResponseInput;
  response: CommercialUseResponseSaveQnrDataInput;
};

export enum CommercialUseType {
  CLEANERS = "CLEANERS",
  GYM = "GYM",
  OFFICE = "OFFICE",
  OTHER = "OTHER",
  PARKING = "PARKING",
  RESTAURANT = "RESTAURANT",
  SALON_SPA = "SALON_SPA",
  STORE = "STORE",
}

export type CompanyEmployee = {
  __typename: "CompanyEmployee";
  /**
   * Users can save their filter settings in the Analytics part of the app and
   * then query for them here. Note: this resolver will only allow a user to
   * query for their own settings.
   */
  analyticsFilterSettings: CompanyEmployeeAnalyticsFilterSettings;
  /**
   * HOA's that the employee is assigned to. Note: we treat the "master"
   * employee as if they're assigned to all HOA's.
   */
  assignedHoas: Array<HOA>;
  employeeType: EmployeeType;
  firstName: Scalars["String"]["output"];
  fullName: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  permissions: CompanyEmployeePermissions;
  phoneInfo: PhoneInfo;
  status: CompanyEmployeeStatus;
  title: Maybe<Scalars["String"]["output"]>;
  username: Scalars["String"]["output"];
  uuid: Scalars["ID"]["output"];
};

export type CompanyEmployee_assignedHoasArgs = {
  anyProductType?: InputMaybe<Scalars["Boolean"]["input"]>;
  specificProductType?: InputMaybe<ContactProductType>;
  statuses?: InputMaybe<Array<HoaStatus>>;
};

export type CompanyEmployeeAnalyticsFilterSettings = {
  __typename: "CompanyEmployeeAnalyticsFilterSettings";
  /** The employee's Key Metrics filter settings (if they've saved the settings). */
  keyMetricsFilters: Maybe<MgmtKeyMetricsFilterSettings>;
  /** The employee's Product Performance filter settings (if they've saved the settings). */
  productPerfFilters: Maybe<MgmtProductPerfFilterSettings>;
  /** The employee's Product Processsing filter settings (if they've saved the settings). */
  productProcessingFilters: Maybe<MgmtProductProcessingFilterSettings>;
  /** The employee's Rush Performance filter settings (if they've saved the settings). */
  rushPerfFilters: Maybe<MgmtRushPerfFilterSettings>;
  /** The employee's Trends filter settings (if they've saved the settings). */
  trendsFilters: Maybe<MgmtTrendsFilterSettings>;
};

export type CompanyEmployeePermissions = {
  __typename: "CompanyEmployeePermissions";
  addBatchGroup: Scalars["Boolean"]["output"];
  batchGroupsSetup: Scalars["Boolean"]["output"];
  deleteBatchGroup: Scalars["Boolean"]["output"];
  editBatchGroup: Scalars["Boolean"]["output"];
  editQnrData: Scalars["Boolean"]["output"];
  emailContactsSettings: Scalars["Boolean"]["output"];
  processQnr: Scalars["Boolean"]["output"];
  qnrSettings: Scalars["Boolean"]["output"];
  remittanceAdjustments: Scalars["Boolean"]["output"];
  remittanceReports: Scalars["Boolean"]["output"];
  remittanceSettings: Scalars["Boolean"]["output"];
  remittanceSystem: Scalars["Boolean"]["output"];
  requestorAnnouncementConfig: Scalars["Boolean"]["output"];
  runRemittance: Scalars["Boolean"]["output"];
  vendorIdSettings: Scalars["Boolean"]["output"];
  viewQnr: Scalars["Boolean"]["output"];
  viewRevenue: Scalars["Boolean"]["output"];
};

export enum CompanyEmployeeStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type CompletedOrder = Order & {
  __typename: "CompletedOrder";
  /**
   * The time when the order was acknowledged (if there was an acknowledgement).
   * Restricted to authenticated users to limit PII disclosure.
   */
  acknowledgedAt: Maybe<Scalars["String"]["output"]>;
  /**
   * The notice that the requestor acknowledged when they placed the order.
   * Restricted to authenticated users to limit PII disclosure.
   */
  acknowledgedNotice: Maybe<Scalars["String"]["output"]>;
  /**
   * Suppose the user didn't use rush processing when they placed the order, but
   * now they'd like to add rush processing to the order. Then, we should use
   * this resolver to figure out what rush options, if any, are available. If there
   * aren't any rush options, this resolver can be used to tell the user the reason.
   */
  addedRushOptionsInfo: AddedRushOptionsInfo;
  attachments: Array<OrderAttachment>;
  bundles: Array<OrderBundle>;
  buyer: Maybe<Buyer>;
  /**
   * If true, the buyer intends to move into the property. Only applies to resale
   * transactions. Restricted to authenticated users to limit PII disclosure.
   */
  buyerOccupied: Maybe<Scalars["Boolean"]["output"]>;
  completedAt: Scalars["String"]["output"];
  confirmationEmailSentTo: Scalars["String"]["output"];
  confirmationNumber: Scalars["String"]["output"];
  currentOwner: CurrentOwner;
  /** This url allows users to download all ordered products from the app. */
  downloadAllUrl: Scalars["String"]["output"];
  /**
   * "
   * Note: We do NOT need to convert this to HWD's timezone using a schema directive. It's an HWD
   * calendar date, so it's inherently in HWD's timezone already.
   */
  dueDate: Scalars["String"]["output"];
  /**
   * The estimated closing date of the real estate transaction. We don't always collect this field.
   * For example, we don't ask the requestor to enter it if the order doesn't contain any forms.
   *
   * Note: We do NOT need to convert this to HWD's timezone using a schema directive. It's an HWD
   * calendar date, so it's inherently in HWD's timezone already.
   */
  estimatedClosingDate: Maybe<Scalars["String"]["output"]>;
  hasRush: Scalars["Boolean"]["output"];
  hoa: HOA;
  individualItems: Array<OrderItem>;
  /**
   * The loan #. This only applies if the user ordered a questionnaire. Restricted to
   * authenticated users to limit PII disclosure.
   */
  loanNumber: Maybe<Scalars["String"]["output"]>;
  /** See comment associated with PendingOrder's modifyBuyerActionInfo resolver */
  modifyBuyerActionInfo: OrderActionInfo;
  orderDate: Scalars["String"]["output"];
  paidAt: Maybe<Scalars["String"]["output"]>;
  paymentMethod: PaymentMethod;
  presaleDocumentsInfo: Maybe<Array<PresaleDocumentsGroup>>;
  /**
   * URL that can be used to view the order's receipt (which we often refer to as "order statement" since
   * it's technically not a receipt).
   * Unless the order is still awaiting payment, then this field should be non-null.
   */
  receiptUrl: Maybe<Scalars["String"]["output"]>;
  requestor: Requestor;
  resaleFormOptionValues: Maybe<Array<ResaleFormOptionValue>>;
  rushAddedAt: Maybe<Scalars["String"]["output"]>;
  shareWithEmails: Maybe<Array<Scalars["String"]["output"]>>;
  shippingInfo: Maybe<ShippingInfo>;
  subjectPropertyAddress: Scalars["String"]["output"];
  totalAtCloseFee: Scalars["Float"]["output"];
  totalPaid: Scalars["Float"]["output"];
  totalPrice: Scalars["Float"]["output"];
  /**
   * Total amount of money that was due at up front (USD).
   * Note: this doesn't necessarily mean the up front fees have been paid yet. For example, this
   * fee still has to be paid for order that are still awaiting payment (e.g. 3rd party pay)
   */
  totalUpFrontFee: Scalars["Float"]["output"];
  transactionType: TransactionType;
  upFrontPayStatus: UpFrontPayStatus;
  uuid: Scalars["String"]["output"];
};

export type CompletedOrder_acknowledgedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompletedOrder_completedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompletedOrder_dueDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompletedOrder_estimatedClosingDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompletedOrder_modifyBuyerActionInfoArgs = {
  perfSensitiveEnforceUpdateRequestLogic?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

export type CompletedOrder_orderDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompletedOrder_paidAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompletedOrder_rushAddedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompletedOrders = {
  __typename: "CompletedOrders";
  orders: Array<CompletedOrder>;
  totalNumOrders: Scalars["Int"]["output"];
};

/**
 * Mgmt company employees can be assigned as "contacts" for certain types of
 * products (and for specific HOA's). This enum represents the types of contact
 * assignments we permit.
 *
 * Note: this enum is somewhat similar to 'ProductCategoryType' (both structurally
 * and conceptually), but there are important differences:
 * 1) 'ProductCategoryType' includes docs and TRID. But we don't support
 * contact assignments for docs and TRID.
 * 2) 'ProductCategoryType' differentiates between lender and appraiser
 * questionnaires, but we do _not_ do that for contact assignments. We have a
 * single/generic qnr contact setting that applies to both lender and appr qnrs.
 */
export enum ContactProductType {
  COMPLIANCE_INSPECTION = "COMPLIANCE_INSPECTION",
  DEMAND = "DEMAND",
  QUESTIONNAIRE = "QUESTIONNAIRE",
  REPORT = "REPORT",
  RESALE_DISCLOSURE = "RESALE_DISCLOSURE",
}

/** The type of management company employee who would like to contact our sales team */
export enum ContactSalesEmployeeType {
  BOARD_MEMBER = "BOARD_MEMBER",
  FINANCIAL = "FINANCIAL",
  MANAGER = "MANAGER",
  OTHER = "OTHER",
  OWNER = "OWNER",
  PROCESSOR = "PROCESSOR",
  TECHNOLOGY = "TECHNOLOGY",
}

/**
 * Different types of management company software. The sales team finds it useful
 * to know what software a management company currently uses when they reach out for a demo.
 */
export enum ContactSalesManagementSoftware {
  APPFOLIO = "APPFOLIO",
  ASYST = "ASYST",
  BUILDIUM = "BUILDIUM",
  C3 = "C3",
  CALIBER = "CALIBER",
  CINC = "CINC",
  CONDO_MANAGER = "CONDO_MANAGER",
  EUNIFY = "EUNIFY",
  JENARK = "JENARK",
  OTHER = "OTHER",
  PROPERTYWARE = "PROPERTYWARE",
  QUICKBOOKS = "QUICKBOOKS",
  RENT_MANAGER = "RENT_MANAGER",
  SKYLINE = "SKYLINE",
  TOPS = "TOPS",
  VANTACA = "VANTACA",
  VMS = "VMS",
  YARDI = "YARDI",
}

export enum ContactSalesNumHoas {
  ABOVE_30 = "ABOVE_30",
  FROM_11_TO_30 = "FROM_11_TO_30",
  UNDER_10 = "UNDER_10",
}

export enum ContactSalesNumUnits {
  ABOVE_5K = "ABOVE_5K",
  FROM_2K_TO_5K = "FROM_2K_TO_5K",
  UNDER_2K = "UNDER_2K",
}

/**
 * Contact Settings input for the 'saveQnrData' mutation.
 *
 * Note: if 'dirty' is true for a given field, then the corresponding 'proposedValue'
 * _must_ be non-null. The HOA must have contact settings, so the settings can't be
 * unselected.
 */
export type ContactSettingsSaveQnrDataInput = {
  primaryContactEmployeeUUID: StringFieldResponseInput;
  signatureContactEmployeeUUID: StringFieldResponseInput;
};

export type ConvergeLightboxParams = {
  __typename: "ConvergeLightboxParams";
  /**
   * Parameters that the client should pass to Converge's Lightbox:
   *
   * PayWithConverge.open(lightboxParams, callbacks);
   *
   * Note that we use the JSON type here to avoid needing to schematize the
   * Lightbox params. This way, the client can be agnostic about the data and
   * simply pass it through to Lightbox. That will be especially useful if we
   * start using Lightbox differently and need to change the structure of the
   * params.
   */
  lightboxParams: Scalars["JSON"]["output"];
};

export type CopyHoaQnrDataMutationError = BaseMutationError & {
  __typename: "CopyHoaQnrDataMutationError";
  code: CopyHoaQnrDataMutationErrorCode;
  message: Scalars["String"]["output"];
};

export enum CopyHoaQnrDataMutationErrorCode {
  HOA_NOT_FOUND = "HOA_NOT_FOUND",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  SOURCE_AND_TARGET_DIFFERENT_TYPES = "SOURCE_AND_TARGET_DIFFERENT_TYPES",
  SOURCE_AND_TARGET_SAME_HOA = "SOURCE_AND_TARGET_SAME_HOA",
  SOURCE_HOA_PRISTINE = "SOURCE_HOA_PRISTINE",
  SYSTEM_ERROR = "SYSTEM_ERROR",
  TARGET_HOA_DIRTY = "TARGET_HOA_DIRTY",
  USER_NOT_ASSIGNED_TO_QNR_FOR_HOA = "USER_NOT_ASSIGNED_TO_QNR_FOR_HOA",
  USER_NOT_FOUND = "USER_NOT_FOUND",
}

export type CopyHoaQnrDataMutationResponse = MutationResponse & {
  __typename: "CopyHoaQnrDataMutationResponse";
  error: Maybe<CopyHoaQnrDataMutationError>;
  question: Maybe<QnrQuestion>;
  success: Scalars["Boolean"]["output"];
};

export type CopyHoaQnrSettingsMutationError = BaseMutationError & {
  __typename: "CopyHoaQnrSettingsMutationError";
  code: CopyHoaQnrSettingsMutationErrorCode;
  message: Scalars["String"]["output"];
};

export enum CopyHoaQnrSettingsMutationErrorCode {
  BAD_TARGET_HOAS_INPUT = "BAD_TARGET_HOAS_INPUT",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  SOURCE_HOA_NOT_FOUND = "SOURCE_HOA_NOT_FOUND",
  SYSTEM_ERROR = "SYSTEM_ERROR",
  USER_NOT_FOUND = "USER_NOT_FOUND",
}

export type CopyHoaQnrSettingsMutationResponse = MutationResponse & {
  __typename: "CopyHoaQnrSettingsMutationResponse";
  error: Maybe<CopyHoaQnrSettingsMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type CoreFollowUpQnrQuestionInfo = {
  __typename: "CoreFollowUpQnrQuestionInfo";
  createdAt: Scalars["String"]["output"];
  customOutputLabel: Maybe<Scalars["String"]["output"]>;
  /**
   * The question's expiration settings, or null if responses to this question
   * shouldn't expire.
   */
  expirationSettings: Maybe<QnrQuestionExpirationSettings>;
  /** Notes for internal use by HWD employees. */
  hwdNotes: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  mgmtInputHelperText: Maybe<Scalars["String"]["output"]>;
  releasedAt: Maybe<Scalars["String"]["output"]>;
  /**
   * UUID(s) of the primary question's dropdown option(s) that should trigger
   * this follow-up question to appear.
   */
  triggeredByPrimaryDropdownOptionUUIDs: Array<Scalars["ID"]["output"]>;
};

export type CoreFollowUpQnrQuestionInfo_createdAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CoreFollowUpQnrQuestionInfo_releasedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CoreFollowUpQuestionInfoForResponse = {
  __typename: "CoreFollowUpQuestionInfoForResponse";
  label: Scalars["String"]["output"];
  /** Helper text displayed to the management company when they're inputting data. */
  mgmtInputHelperText: Maybe<Scalars["String"]["output"]>;
  /**
   * UUID(s) of the primary question's dropdown option(s) that should trigger
   * this follow-up question to appear.
   */
  triggeredByPrimaryDropdownOptionUUIDs: Array<Scalars["ID"]["output"]>;
};

export type CoreQuestionInfo = {
  __typename: "CoreQuestionInfo";
  createdAt: Scalars["String"]["output"];
  /** If defined, the custom label to be displayed on the output (i.e. the generated PDF). */
  customOutputLabel: Maybe<Scalars["String"]["output"]>;
  /**
   * The question's expiration settings, or null if responses to this question
   * shouldn't expire.
   */
  expirationSettings: Maybe<QnrQuestionExpirationSettings>;
  /** Notes for internal use by HWD employees. */
  hwdNotes: Maybe<Scalars["NonEmptyString"]["output"]>;
  /**
   * Whether this question should be included when the user selects the
   * default 'Quick Update' filter
   */
  isDefaultQuickUpdateMember: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  /** Helper text to display to the mgmt company when they're inputting data. */
  mgmtInputHelperText: Maybe<Scalars["String"]["output"]>;
  releasedAt: Maybe<Scalars["String"]["output"]>;
  scope: QnrQuestionScope;
  /**
   * Which questionnaire template(s) the question should be included on. A
   * question should be included on at least 1 template.
   */
  templates: Array<QnrTemplate>;
};

export type CoreQuestionInfo_createdAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CoreQuestionInfo_releasedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type CoreQuestionInfoForResponse = {
  __typename: "CoreQuestionInfoForResponse";
  /**
   * If true, this question should be displayed when the user applies the 'Quick Update'
   * filter.
   *
   * This flag accounts for both the default HWD settings and custom HOA-level
   * settings. If the mgmt company has customized this question's Quick Update
   * setting for the HOA, then this field will use that custom setting. O/w, this
   * field will fall back to the question's default HWD setting.
   */
  isQuickUpdateMember: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  /** Helper text displayed to the management company when they're inputting data. */
  mgmtInputHelperText: Maybe<Scalars["String"]["output"]>;
};

export type CreateDemandPayeeResponse = {
  __typename: "CreateDemandPayeeResponse";
  demandPayee: Maybe<DemandPayee>;
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

export enum CreateFollowUpQnrQuestionErrorCode {
  DROPDOWN_OPTIONS_INVALID = "DROPDOWN_OPTIONS_INVALID",
  EXPIRATION_SETTINGS_INVALID = "EXPIRATION_SETTINGS_INVALID",
  PRIMARY_QUESTION_NOT_FOUND = "PRIMARY_QUESTION_NOT_FOUND",
  QUESTION_CODE_ALREADY_EXISTS = "QUESTION_CODE_ALREADY_EXISTS",
  QUESTION_CODE_INVALID = "QUESTION_CODE_INVALID",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type CreateFollowUpQnrQuestionInput = {
  apiCode: Scalars["NonEmptyString"]["input"];
  customOutputLabel?: InputMaybe<Scalars["String"]["input"]>;
  dropdownOptionsSource?: InputMaybe<DropdownQnrQuestionOptionsSource>;
  expirationSettings?: InputMaybe<QnrQuestionExpirationSettingsInput>;
  hwdNotes?: InputMaybe<Scalars["String"]["input"]>;
  label: Scalars["NonEmptyString"]["input"];
  mgmtInputHelperText?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * If 'dropdownOptionsSource' is 'PREDEFINED', the included predefined options.
   * Else, null.
   */
  predefinedDropdownOptions?: InputMaybe<
    Array<IncludedPredefinedDropdownOptionInput>
  >;
  primaryQuestionApiCode: Scalars["NonEmptyString"]["input"];
  responseRequired: Scalars["Boolean"]["input"];
  textLikeResponseType?: InputMaybe<TextLikeQnrQuestionResponseType>;
  /**
   * UUID(s) of the primary question's dropdown option(s) that should trigger
   * this follow-up question to appear.
   */
  triggeredByPrimaryDropdownOptionUUIDs: Array<Scalars["UUID"]["input"]>;
  type: CreateQnrQuestionType;
};

export type CreateFollowUpQnrQuestionMutationError = BaseMutationError & {
  __typename: "CreateFollowUpQnrQuestionMutationError";
  code: CreateFollowUpQnrQuestionErrorCode;
  message: Scalars["String"]["output"];
};

export type CreateFollowUpQnrQuestionMutationResponse = MutationResponse & {
  __typename: "CreateFollowUpQnrQuestionMutationResponse";
  error: Maybe<CreateFollowUpQnrQuestionMutationError>;
  question: Maybe<BasicFollowUpQnrQuestion>;
  success: Scalars["Boolean"]["output"];
};

export enum CreatePrimaryQnrQuestionErrorCode {
  DROPDOWN_OPTIONS_INVALID = "DROPDOWN_OPTIONS_INVALID",
  EXPIRATION_SETTINGS_INVALID = "EXPIRATION_SETTINGS_INVALID",
  QUESTION_CODE_ALREADY_EXISTS = "QUESTION_CODE_ALREADY_EXISTS",
  QUESTION_CODE_INVALID = "QUESTION_CODE_INVALID",
  SECTION_NOT_FOUND = "SECTION_NOT_FOUND",
  SUBSECTION_NOT_IN_SECTION = "SUBSECTION_NOT_IN_SECTION",
  SYSTEM_ERROR = "SYSTEM_ERROR",
  TEMPLATES_INVALID_EMPTY = "TEMPLATES_INVALID_EMPTY",
  TEMPLATES_NOT_SUBSET = "TEMPLATES_NOT_SUBSET",
}

export type CreatePrimaryQnrQuestionInput = {
  apiCode: Scalars["NonEmptyString"]["input"];
  customOutputLabel?: InputMaybe<Scalars["String"]["input"]>;
  dropdownOptionsSource?: InputMaybe<DropdownQnrQuestionOptionsSource>;
  expirationSettings?: InputMaybe<QnrQuestionExpirationSettingsInput>;
  hwdNotes?: InputMaybe<Scalars["String"]["input"]>;
  isDefaultQuickUpdateMember: Scalars["Boolean"]["input"];
  label: Scalars["NonEmptyString"]["input"];
  mgmtInputHelperText?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * If 'dropdownOptionsSource' is 'PREDEFINED', the included predefined options.
   * Else, null.
   */
  predefinedDropdownOptions?: InputMaybe<
    Array<IncludedPredefinedDropdownOptionInput>
  >;
  responseRequired: Scalars["Boolean"]["input"];
  scope: QnrQuestionScope;
  sectionApiCode: Scalars["NonEmptyString"]["input"];
  subsectionApiCode?: InputMaybe<Scalars["NonEmptyString"]["input"]>;
  /**
   * Which template(s) the question should display on. Must not be empty. Also,
   * must be compatible with the template settings of the specified 'sectionApiCode'
   * and 'subsectionApiCode' input variables.
   */
  templates: Array<QnrTemplate>;
  textLikeResponseType?: InputMaybe<TextLikeQnrQuestionResponseType>;
  type: CreateQnrQuestionType;
};

export type CreatePrimaryQnrQuestionMutationError = BaseMutationError & {
  __typename: "CreatePrimaryQnrQuestionMutationError";
  code: CreatePrimaryQnrQuestionErrorCode;
  message: Scalars["String"]["output"];
};

export type CreatePrimaryQnrQuestionMutationResponse = MutationResponse & {
  __typename: "CreatePrimaryQnrQuestionMutationResponse";
  error: Maybe<CreatePrimaryQnrQuestionMutationError>;
  question: Maybe<QnrQuestion>;
  success: Scalars["Boolean"]["output"];
};

export enum CreateQnrQuestionType {
  DROPDOWN = "DROPDOWN",
  MULTI_SELECT = "MULTI_SELECT",
  TEXT_LIKE = "TEXT_LIKE",
}

export type CreateRequestorAnnouncementInput = {
  /** Input for a Date Time Interval. Dates and Times entered here are assumed to be in HWD_TIME_ZONE. */
  interval: DateTimeIntervalInput;
  message: Scalars["TrimmedNonEmptyString"]["input"];
};

export type CreateRequestorAnnouncementResponse = {
  __typename: "CreateRequestorAnnouncementResponse";
  announcement: Maybe<Announcement>;
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type CreditCardBillingInfo = {
  cardHolderEmail: Scalars["Email"]["input"];
  city: Scalars["TrimmedNonEmptyString"]["input"];
  country: Scalars["TrimmedNonEmptyString"]["input"];
  firstName: Scalars["TrimmedNonEmptyString"]["input"];
  lastName: Scalars["TrimmedNonEmptyString"]["input"];
  state: Scalars["TrimmedNonEmptyString"]["input"];
  streetAddress: Scalars["TrimmedNonEmptyString"]["input"];
  zip: Scalars["TrimmedNonEmptyString"]["input"];
};

export type CreditCardFeeDisabled = {
  __typename: "CreditCardFeeDisabled";
  _: Maybe<Scalars["String"]["output"]>;
};

export type CreditCardFeeInfo = CreditCardFeeDisabled | FlatCreditCardFee;

export type CreditCardInfo = {
  billingInfo?: InputMaybe<CreditCardBillingInfo>;
  cardHolderEmail?: InputMaybe<Scalars["Email"]["input"]>;
  cardNumber?: InputMaybe<Scalars["TrimmedNonEmptyString"]["input"]>;
  cvv?: InputMaybe<Scalars["TrimmedNonEmptyString"]["input"]>;
  expMonth?: InputMaybe<Scalars["TrimmedNonEmptyString"]["input"]>;
  expYear?: InputMaybe<Scalars["TrimmedNonEmptyString"]["input"]>;
  /**
   * If the user is paying by credit card, then this should be the data we
   * received in Lightbox's 'onApproval' callback (assuming Lightbox succeeded).
   *
   * Note that we use the JSON scalar type. This way, the client does not need to
   * be concerned with the structure of the Lightbox data.
   */
  lightboxApprovalData?: InputMaybe<Scalars["JSON"]["input"]>;
};

/**
 * Information about the current owner of the property.
 * Almost all of the info needs to be nullable because of how the website used to
 * handle TRID orders. In the past, users didn't have to enter any info about the
 * current owner for TRID orders, so we can't guarantee that we have the info.
 *
 *
 * To limit disclosure of PII, the current owner's email and phone are restricted
 * to authenticated users. However, unlike the buyer and requestor, the current owner's
 * address is _not_ restricted to authenticated users. That address is the
 * subject property address of any forms that will be ordered and is part of the
 * basic information that third parties need to know about an order.
 */
export type CurrentOwner = {
  __typename: "CurrentOwner";
  addressInfo: CurrentOwnerAddress;
  email: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  fullName: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  phone: Maybe<Scalars["String"]["output"]>;
};

export type CurrentOwnerAddress = {
  __typename: "CurrentOwnerAddress";
  city: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
  streetAddress: Scalars["String"]["output"];
  unit: Maybe<Scalars["String"]["output"]>;
  zip: Scalars["String"]["output"];
};

/**
 * Verification status of the current owner address
 * The orders placed prior to the implemention of the address verification will have NOT_VERIFIED status.
 */
export enum CurrentOwnerAddressVerificationStatus {
  BYPASSED_WRONG_HOA = "BYPASSED_WRONG_HOA",
  NOT_VERIFIED = "NOT_VERIFIED",
  VERIFIED_HOMEWISE = "VERIFIED_HOMEWISE",
  VERIFIED_POSTAL_SERVICE = "VERIFIED_POSTAL_SERVICE",
  VERIFIED_POSTAL_SERVICE_BY_DROPPING_UNIT = "VERIFIED_POSTAL_SERVICE_BY_DROPPING_UNIT",
  VERIFIED_POSTAL_SERVICE_MISSING_UNIT = "VERIFIED_POSTAL_SERVICE_MISSING_UNIT",
}

export type CurrentOwnerInput = {
  city: Scalars["TrimmedNonEmptyString"]["input"];
  email: Scalars["Email"]["input"];
  firstName: Scalars["TrimmedNonEmptyString"]["input"];
  lastName: Scalars["TrimmedNonEmptyString"]["input"];
  /**
   * The current owner's phone number (optional).
   * Even though the field is optional, it must be a valid
   * phone number if specified.
   */
  phone?: InputMaybe<Scalars["String"]["input"]>;
  state: Scalars["TrimmedNonEmptyString"]["input"];
  streetAddress: Scalars["TrimmedNonEmptyString"]["input"];
  unit?: InputMaybe<Scalars["String"]["input"]>;
  zip: Scalars["ZipCode"]["input"];
};

export type DateQnrQuestionResponse = {
  __typename: "DateQnrQuestionResponse";
  value: Maybe<Scalars["String"]["output"]>;
};

export type DateTimeInterval = {
  __typename: "DateTimeInterval";
  end: LocalDateTime;
  start: LocalDateTime;
};

export type DateTimeIntervalInput = {
  end: LocalDateTimeInput;
  start: LocalDateTimeInput;
};

export type DeleteDemandPayeeInput = {
  id: Scalars["ID"]["input"];
};

export type DeleteDemandPayeeResponse = {
  __typename: "DeleteDemandPayeeResponse";
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

export enum DeleteQnrQuestionErrorCode {
  QUESTION_NOT_FOUND = "QUESTION_NOT_FOUND",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type DeleteQnrQuestionMutationError = BaseMutationError & {
  __typename: "DeleteQnrQuestionMutationError";
  code: DeleteQnrQuestionErrorCode;
  message: Scalars["String"]["output"];
};

export type DeleteQnrQuestionMutationResponse = MutationResponse & {
  __typename: "DeleteQnrQuestionMutationResponse";
  error: Maybe<DeleteQnrQuestionMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type DeleteRemittanceBatchGroupInput = {
  /** The UUID of the Remittance Batch Group. */
  uuid: Scalars["UUID"]["input"];
};

export type DeleteRemittanceBatchGroupMutationResponse = MutationResponse & {
  __typename: "DeleteRemittanceBatchGroupMutationResponse";
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type DeleteRequestorAnnouncementInput = {
  id: Scalars["ID"]["input"];
};

export type DeleteRequestorAnnouncementResponse = {
  __typename: "DeleteRequestorAnnouncementResponse";
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type DemandPayee = {
  __typename: "DemandPayee";
  address: Address;
  contactName: Scalars["String"]["output"];
  email: Maybe<Scalars["String"]["output"]>;
  fax: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  mailingAddress: Maybe<Address>;
  name: Scalars["String"]["output"];
  phone: Maybe<Scalars["String"]["output"]>;
  website: Maybe<Scalars["String"]["output"]>;
};

export type DemandPayeeAddressInput = {
  city: Scalars["TrimmedNonEmptyString"]["input"];
  state: Scalars["TrimmedNonEmptyString"]["input"];
  streetAddress: Scalars["TrimmedNonEmptyString"]["input"];
  zip: Scalars["ZipCode"]["input"];
};

export type DemandPayeeInput = {
  address: DemandPayeeAddressInput;
  contactName: Scalars["TrimmedNonEmptyString"]["input"];
  email?: InputMaybe<Scalars["Email"]["input"]>;
  fax?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
  mailingAddress?: InputMaybe<DemandPayeeAddressInput>;
  name: Scalars["TrimmedNonEmptyString"]["input"];
  phone?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
  website?: InputMaybe<Scalars["TrimmedNonEmptyString"]["input"]>;
};

export type Developer = {
  __typename: "Developer";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type DocumentAttachmentInfo = {
  attachmentType: OrderAttachmentDocumentType;
  file: Scalars["Upload"]["input"];
};

export type DropdownQnrQuestion = {
  __typename: "DropdownQnrQuestion";
  apiCode: Scalars["String"]["output"];
  core: CoreQuestionInfo;
  dropdownOptions: Array<DropdownQnrQuestionOption>;
  dropdownOptionsSource: DropdownQnrQuestionOptionsSource;
  followUpQuestions: Array<FollowUpQnrQuestion>;
  responseRequired: Scalars["Boolean"]["output"];
};

export type DropdownQnrQuestionAccountingSystemData = {
  __typename: "DropdownQnrQuestionAccountingSystemData";
  acctgSystemValue: DropdownQnrQuestionResponse;
  /**
   * The latest response saved in HWD, or null if the HOA doesn't have a response
   * to this question saved in HWD yet.
   */
  latestHwdResponse: Maybe<DropdownQnrQuestionResponse>;
  /** The date of the latest response saved in HWD. */
  latestHwdResponseDate: Maybe<Scalars["String"]["output"]>;
};

export type DropdownQnrQuestionAccountingSystemData_latestHwdResponseDateArgs =
  {
    format?: InputMaybe<Scalars["String"]["input"]>;
  };

export type DropdownQnrQuestionOption = {
  __typename: "DropdownQnrQuestionOption";
  label: Scalars["String"]["output"];
  /**
   * If the dropdown option is a predefined variant, the predefined variant.
   * Else, null (the dropdown option is a custom option).
   */
  predefinedVariant: Maybe<DropdownQnrQuestionPredefinedOptionVariant>;
  /**
   * If true, the manager must specify a comment if this dropdown option is
   * selected.
   */
  requiresComment: Scalars["Boolean"]["output"];
  uuid: Scalars["ID"]["output"];
};

export enum DropdownQnrQuestionOptionsSource {
  CUSTOM = "CUSTOM",
  PREDEFINED = "PREDEFINED",
}

export enum DropdownQnrQuestionPredefinedOptionVariant {
  NO = "NO",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  SEE_COMMENT = "SEE_COMMENT",
  UNKNOWN = "UNKNOWN",
  YES = "YES",
}

export type DropdownQnrQuestionResponse = {
  __typename: "DropdownQnrQuestionResponse";
  selectedOptionLabel: Scalars["String"]["output"];
  selectedOptionUUID: Scalars["ID"]["output"];
};

export type DropdownQnrQuestionWithResponse = {
  __typename: "DropdownQnrQuestionWithResponse";
  apiCode: Scalars["String"]["output"];
  comment: Maybe<Scalars["String"]["output"]>;
  core: CoreQuestionInfoForResponse;
  dropdownOptions: Array<DropdownQnrQuestionOption>;
  expirationInfo: Maybe<QnrResponseExpirationInfo>;
  followUpQuestionsWithResponses: Array<FollowUpQnrQuestionWithResponse>;
  responseRequired: Scalars["Boolean"]["output"];
  /** UUID of the selected option, or null if no option is selected */
  selectedOptionUUID: Maybe<Scalars["ID"]["output"]>;
};

export type DropdownQuestionSaveDataInput = {
  apiCode: Scalars["String"]["input"];
  comment: StringFieldResponseInput;
  response: StringFieldResponseInput;
};

export type ECheckInfo = {
  bankAccountNumber: Scalars["TrimmedNonEmptyString"]["input"];
  bankRoutingNumber: Scalars["TrimmedNonEmptyString"]["input"];
  firstName: Scalars["TrimmedNonEmptyString"]["input"];
  lastName: Scalars["TrimmedNonEmptyString"]["input"];
};

export enum EmployeeType {
  MASTER = "MASTER",
  SUB = "SUB",
}

export type Fee = {
  __typename: "Fee";
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  paymentDue: PaymentDueType;
  price: Scalars["Float"]["output"];
};

export type File = {
  __typename: "File";
  encoding: Scalars["String"]["output"];
  filename: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  mimetype: Scalars["String"]["output"];
};

/**
 * Info about the credit card fee when it's a flat fee.
 *
 * Right now, we never use a %-based fee. But one could imagine that as a
 * possibility, so this type's name emphasizes that it represents a flat fee.
 */
export type FlatCreditCardFee = {
  __typename: "FlatCreditCardFee";
  label: Scalars["String"]["output"];
  paymentDue: PaymentDueType;
  totalFee: Scalars["Float"]["output"];
};

export type FloatFieldResponseInput = {
  dirty: Scalars["Boolean"]["input"];
  proposedValue?: InputMaybe<Scalars["Float"]["input"]>;
};

export type FloatQnrQuestionResponse = {
  __typename: "FloatQnrQuestionResponse";
  value: Maybe<FloatQnrQuestionResponseValue>;
};

export type FloatQnrQuestionResponseValue = {
  __typename: "FloatQnrQuestionResponseValue";
  formatted: Scalars["String"]["output"];
  raw: Scalars["Float"]["output"];
};

export type FloatQuestionSaveDataInput = {
  apiCode: Scalars["String"]["input"];
  comment: StringFieldResponseInput;
  response: FloatFieldResponseInput;
};

export type FollowUpBespokeQnrQuestion = {
  __typename: "FollowUpBespokeQnrQuestion";
  apiCode: Scalars["String"]["output"];
  core: CoreFollowUpQnrQuestionInfo;
};

/** A follow-up question that is a dropdown. */
export type FollowUpDropdownQnrQuestion = {
  __typename: "FollowUpDropdownQnrQuestion";
  apiCode: Scalars["String"]["output"];
  core: CoreFollowUpQnrQuestionInfo;
  /**
   * Note that these dropdown options cannot be associated with any nested
   * follow-up questions. We don't support nested follow-up questions.
   */
  dropdownOptions: Array<DropdownQnrQuestionOption>;
  dropdownOptionsSource: DropdownQnrQuestionOptionsSource;
  responseRequired: Scalars["Boolean"]["output"];
};

export type FollowUpDropdownQnrQuestionWithResponse = {
  __typename: "FollowUpDropdownQnrQuestionWithResponse";
  apiCode: Scalars["String"]["output"];
  comment: Maybe<Scalars["String"]["output"]>;
  core: CoreFollowUpQuestionInfoForResponse;
  dropdownOptions: Array<DropdownQnrQuestionOption>;
  expirationInfo: Maybe<QnrResponseExpirationInfo>;
  responseRequired: Scalars["Boolean"]["output"];
  /** UUID of the selected option, or null if no option is selected */
  selectedOptionUUID: Maybe<Scalars["ID"]["output"]>;
};

export type FollowUpMultiSelectQnrQuestion = {
  __typename: "FollowUpMultiSelectQnrQuestion";
  apiCode: Scalars["String"]["output"];
  core: CoreFollowUpQnrQuestionInfo;
  options: Array<MultiSelectQnrQuestionOption>;
  responseRequired: Scalars["Boolean"]["output"];
};

export type FollowUpMultiSelectQnrQuestionWithResponse = {
  __typename: "FollowUpMultiSelectQnrQuestionWithResponse";
  apiCode: Scalars["String"]["output"];
  comment: Maybe<Scalars["String"]["output"]>;
  core: CoreFollowUpQuestionInfoForResponse;
  expirationInfo: Maybe<QnrResponseExpirationInfo>;
  optionsWithResponses: Array<MultiSelectQnrQuestionOptionWithResponse>;
  responseRequired: Scalars["Boolean"]["output"];
};

export type FollowUpQnrQuestion =
  | FollowUpBespokeQnrQuestion
  | FollowUpDropdownQnrQuestion
  | FollowUpMultiSelectQnrQuestion
  | FollowUpTextLikeQnrQuestion;

export type FollowUpQnrQuestionWithExpirationSettings = {
  __typename: "FollowUpQnrQuestionWithExpirationSettings";
  apiCode: Scalars["String"]["output"];
  expSettings: Maybe<QnrQuestionExpirationSettings>;
  hwdDefaultExpSettings: Maybe<QnrQuestionExpirationSettings>;
  label: Scalars["String"]["output"];
  /**
   * The primary question's dropdown option(s) that should trigger this follow-up
   * question to appear.
   */
  triggeredByPrimaryDropdownOptions: Array<DropdownQnrQuestionOption>;
};

export type FollowUpQnrQuestionWithResponse =
  | FollowUpDropdownQnrQuestionWithResponse
  | FollowUpMultiSelectQnrQuestionWithResponse
  | FollowUpTextLikeQnrQuestionWithResponse
  | QnrCommercialUsesWithResponse
  | QnrMultiUnitOwnersWithResponse;

/** A follow-up question that is text-like. */
export type FollowUpTextLikeQnrQuestion = {
  __typename: "FollowUpTextLikeQnrQuestion";
  apiCode: Scalars["String"]["output"];
  core: CoreFollowUpQnrQuestionInfo;
  responseRequired: Scalars["Boolean"]["output"];
  responseType: TextLikeQnrQuestionResponseType;
};

export type FollowUpTextLikeQnrQuestionWithResponse = {
  __typename: "FollowUpTextLikeQnrQuestionWithResponse";
  apiCode: Scalars["String"]["output"];
  comment: Maybe<Scalars["String"]["output"]>;
  core: CoreFollowUpQuestionInfoForResponse;
  expirationInfo: Maybe<QnrResponseExpirationInfo>;
  response: TextLikeQnrQuestionResponse;
  responseRequired: Scalars["Boolean"]["output"];
};

export type FormUpdateInfo = {
  __typename: "FormUpdateInfo";
  form: IndividualItem;
  /**
   * The HOA that the form was originally ordered for. This is useful for sub/master
   * orders where we want to label the HOA that each form corresponds to.
   */
  hoa: HOA;
};

export type GenerateLightboxParamsBillingAddressInput = {
  city: Scalars["NonEmptyString"]["input"];
  country: Scalars["NonEmptyString"]["input"];
  postalCode: Scalars["NonEmptyString"]["input"];
  state: Scalars["NonEmptyString"]["input"];
  streetAddress: Scalars["NonEmptyString"]["input"];
};

export type GenerateLightboxParamsBillingInfoInput = {
  address: GenerateLightboxParamsBillingAddressInput;
  firstName: Scalars["NonEmptyString"]["input"];
  lastName: Scalars["NonEmptyString"]["input"];
};

export type GenerateLightboxParamsError = {
  __typename: "GenerateLightboxParamsError";
  message: Scalars["NonEmptyString"]["output"];
};

export type GenerateLightboxParamsInput = {
  amount: Scalars["PositiveFloat"]["input"];
  billingInfo: GenerateLightboxParamsBillingInfoInput;
};

export type GenerateLightboxParamsResult =
  | ConvergeLightboxParams
  | GenerateLightboxParamsError;

/**
 * A mutation error with a basic String error code. If you want to return
 * specific error codes to the client, it's better to implement BaseMutationError
 * using a custom enum for the code. That makes it easier for the client to take
 * a specific action based on which error occurred.
 */
export type GenericMutationError = BaseMutationError & {
  __typename: "GenericMutationError";
  code: Maybe<Scalars["String"]["output"]>;
  message: Scalars["String"]["output"];
};

export type HOA = {
  __typename: "HOA";
  /**
   * The accounting system this HOA is purportedly integrated with (or null if
   * this HOA isn't integrated with an accounting system).
   *
   * Note: the mgmt company may be integrated with multiple accounting systems.
   * However, a given HOA can't be integrated with multiple accounting systems.
   */
  accountingSystem: Maybe<AccountingSystem>;
  /** Hardcoded acknowledgements that should be shown to the requestor on the Transaction Details page */
  acknowledgements: Maybe<Array<Acknowledgement>>;
  allDevelopers: Maybe<Array<Developer>>;
  assignedEmployees: Array<CompanyEmployee>;
  /**
   * Bundles and individual items available for a given type of transaction and requestor.
   * If specified, the 'developerId' may influence pricing.
   *
   * Note: If you want to get the products that will be offered when this HOA is being
   * viewed as a master HOA, then the HOA must come from the masterHoas resolver (which
   * is also inside the HOA type resolver). For example, you can query for the sub HOA
   * and get the available products for each of its master HOA's:
   * hoa(uuid: $subHoaUUID) {
   *   masterHoas {
   *     availableProducts(transactionType: $transactionType) {
   *       ...
   *     }
   *   }
   * }
   *
   * In the query above, the 'availableProducts' data for each master HOA will account
   * for the fact that each of those HOA's is being viewed as a master HOA.
   */
  availableProducts: Products;
  city: Scalars["String"]["output"];
  company: ManagementCompany;
  /** The FIPS 6-4 of the county that the HOA resides in. */
  countyCode: Scalars["String"]["output"];
  /**
   * Info about the credit card fee that should be charged if the user pays with
   * a credit card for something related to this HOA.
   *
   * Note: We do _not_ require the user to be authenticated to query this field.
   * We need to query this field for 3rd Party Pay and Add Rush, which don't
   * require auth.
   */
  creditCardFeeInfo: CreditCardFeeInfo;
  hardCopyShippingInfo: HardCopyShippingInfo;
  internalId: Scalars["ID"]["output"];
  /**
   * An "internal key" that the mgmt company may want to specify to help them
   * identify and track this HOA.
   *
   * In a sense, "internal" is a misnomer since the manager may select this key
   * based on some _other_ system (e.g. their accounting system). But the term
   * "internal" is meant to convey that our system won't _use_ this key for
   * anything other than helping the manager identify/track the HOA within HWD.
   * For example, we do _not_ use this key for accounting integrations.
   */
  internalKey: Maybe<Scalars["String"]["output"]>;
  /**
   * Imagine an order where *all* of the payment is due at closing (i.e. not due now).
   * If this field is true, then we should ask the requestor if they'd prefer to pay now instead of
   * at closing. There are several management companies that do NOT want requestors to have this option. Furthermore,
   * requestors placing an order in Arizona HOAs should never have the pay now option.
   */
  isPayNowEnabled: Scalars["Boolean"]["output"];
  /**
   * Additional fee a requestor must pay if they want to upload their own Lender-Specific
   * Questionnaire form. Note that this fee gets charged on top of the 'lsqFee' amount.
   *
   * If we should not charge an additional fee for custom uploads, then this will be null.
   */
  lsqCustomUploadFee: Maybe<LsqCustomUploadFee>;
  /** Additional fee a requestor must pay for ordering a Lender-Specific Questionnaire. */
  lsqFee: LsqFee;
  /**
   * Master HOA's that govern this "sub" HOA.
   * Note: Each master HOA has its own upFrontFormFees, but the sub HOA will always determine
   * whether we display the HWD fee separately.
   */
  masterHoas: Array<HOA>;
  messages: Maybe<HoaMessages>;
  name: Scalars["String"]["output"];
  /**
   * The processing options define how quickly a requestor can choose to have
   * their order completed. If they choose a rush processing option, they'll
   * have to pay a rush fee.
   */
  processingOptions: ProcessingOptions;
  questionnaires: HoaQuestionnaires;
  /** Information from the remittance system associated with the HOA. */
  remittance: HoaRemittance;
  state: Scalars["String"]["output"];
  status: HoaStatus;
  taxId: Scalars["String"]["output"];
  /** The transaction info fields define what fields the user should be able to see while ordering. */
  transactionInfoFields: TransactionInfoFields;
  type: HoaType;
  units: Maybe<Array<HOAUnit>>;
  /**
   * Additional fees the HOA charges up front. These fees depend on what forms the user is ordering.
   * These do not include fees the HOA wants to charge at close.
   *
   * Example fee labels: Transfer Fee, Capital Contribution Fee
   *
   * Note: These are not the only added fees that may apply to an order. For example, the order
   * may also include shipping fees, rush fees, and custom questionnaire fees. None of those fees will
   * be included in this resolver.
   */
  upFrontFormFees: Array<Fee>;
  uuid: Scalars["ID"]["output"];
  zip: Scalars["String"]["output"];
};

export type HOA_acknowledgementsArgs = {
  selectedCategories?: InputMaybe<Array<ProductCategoryType>>;
  transactionType?: InputMaybe<TransactionType>;
};

export type HOA_assignedEmployeesArgs = {
  includeImplicitMasterAssignment: Scalars["Boolean"]["input"];
  productType?: InputMaybe<ContactProductType>;
};

export type HOA_availableProductsArgs = {
  developerId?: InputMaybe<Scalars["ID"]["input"]>;
  transactionType: TransactionType;
};

export type HOA_hardCopyShippingInfoArgs = {
  selectedCategories?: InputMaybe<Array<ProductCategoryType>>;
};

export type HOA_lsqFeeArgs = {
  developerId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type HOA_messagesArgs = {
  developerId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type HOA_processingOptionsArgs = {
  customQuestionnaire: Scalars["Boolean"]["input"];
  developerId?: InputMaybe<Scalars["ID"]["input"]>;
  missingDoc: Scalars["Boolean"]["input"];
  selectedFormCategories: Array<ProductCategoryType>;
  transactionType: TransactionType;
};

export type HOA_transactionInfoFieldsArgs = {
  selectedCategories: Array<ProductCategoryType>;
  transactionType: TransactionType;
};

export type HOA_upFrontFormFeesArgs = {
  masterHoasInput?: InputMaybe<Array<MasterHoaFeeInput>>;
  subHoaInput?: InputMaybe<HoaFeeInput>;
};

export type HOAUnit = {
  __typename: "HOAUnit";
  city: Scalars["String"]["output"];
  hoa: HOA;
  id: Scalars["ID"]["output"];
  state: Scalars["String"]["output"];
  status: AddressStatus;
  streetAddress: Scalars["String"]["output"];
  unit: Maybe<Scalars["String"]["output"]>;
  uuid: Scalars["ID"]["output"];
  zip: Scalars["String"]["output"];
};

export type HOAUnit_hoaArgs = {
  includeNonActiveHoas?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type HardCopyShippingFees = {
  __typename: "HardCopyShippingFees";
  /** Fee for shipping a hard copy of the ordered product(s). */
  standardHardCopyShippingFee: Scalars["Float"]["output"];
  usbFlashDrive: Scalars["Float"]["output"];
};

export type HardCopyShippingInfo = {
  __typename: "HardCopyShippingInfo";
  fees: HardCopyShippingFees;
  /**
   * If true, then hard copy shipping is available.
   * Note: hard copy shipping is not available for any property that is in AZ,
   * managed by The Evergreen Harvard Group, or managed by BRIGS, LLC.
   */
  isAvailable: Scalars["Boolean"]["output"];
  paymentDue: PaymentDueType;
};

export type HoaFeeInput = {
  demand: Scalars["Boolean"]["input"];
  developerId?: InputMaybe<Scalars["ID"]["input"]>;
  resaleDisclosure: Scalars["Boolean"]["input"];
  transactionType: TransactionType;
};

export type HoaGroup = {
  __typename: "HoaGroup";
  displayName: Scalars["String"]["output"];
  /**
   * HOAs in the group.
   *
   * Note: This resolver uses the same variables as the 'ManagementCompany'
   * type's 'allHoas' resolver.
   */
  hoas: Array<HOA>;
  uuid: Scalars["UUID"]["output"];
};

export type HoaGroup_hoasArgs = {
  hoaTypes?: InputMaybe<Array<HoaType>>;
  statuses?: InputMaybe<Array<HoaStatus>>;
};

export type HoaMessages = {
  __typename: "HoaMessages";
  defaultOrderMessage: Maybe<Scalars["String"]["output"]>;
  questionnaireMessage: Maybe<Scalars["String"]["output"]>;
  specialOrderMessage: Maybe<Scalars["String"]["output"]>;
  updateRequestMessage: Maybe<Scalars["String"]["output"]>;
};

export type HoaOrderCount = {
  __typename: "HoaOrderCount";
  breakdown: OrderCountBreakdown;
  breakdownOverTime: Array<OrderCountDataPoint>;
  hoa: HOA;
};

/** An HOA's questionnaire data from the accounting system. */
export type HoaQnrAccountingSystemData = {
  __typename: "HoaQnrAccountingSystemData";
  /**
   * A pretty-printed version of the raw API response. For example, if the
   * accounting API is XML-based, then this will be a formatted version of the
   * XML document returned by the API. When an HWD admin is logged in, the client
   * can display this to help the admin verify/debug accounting API issues. The
   * client should most likely display this content in a <pre> tag with
   * text-align: left.
   *
   * This field is nullable in case the pretty-print operation fails for some
   * reason.
   */
  prettyApiResponse: Maybe<Scalars["String"]["output"]>;
  /**
   * The list of questions whose values are being imported from the accounting
   * system.
   *
   * This list does not include empty responses from the accounting system. In
   * other words, if the accounting system does not provide a question or if it
   * provides an empty or nullish response for the question, then the question
   * will not be included in this list.
   */
  questions: Array<QnrQuestionWithAccountingSystemData>;
  /**
   * The raw API response. For example, if the acctg system's API is XML-based,
   * then this will be a formatted version of the XML document returned by the
   * API. When an HWD admin is logged in, we can display this in the UI to help
   * the admin verify/debug acctg integration issues.
   * Note: The client app should most likely display this content in a <pre> tag
   * with text-align: left.
   */
  rawApiResponse: Scalars["String"]["output"];
};

export type HoaQnrAccountingSystemDataResult =
  | HoaQnrAccountingSystemData
  | HoaQnrAccountingSystemError;

/** An accounting system API error that prevented us from getting any data. */
export type HoaQnrAccountingSystemError = {
  __typename: "HoaQnrAccountingSystemError";
  code: AccountingSystemImportErrorCode;
  message: Scalars["String"]["output"];
  /**
   * See corresponding field in HoaQnrAccountingSystemData.
   *
   * This field is nullable in case we do not receive a response from the API or
   * the response can't be pretty-printed because it's malformed.
   */
  prettyApiResponse: Maybe<Scalars["String"]["output"]>;
  /**
   * See corresponding field in HoaQnrAccountingSystemData.
   *
   * This field is nullable in case we do not receive a response from the API,
   * e.g. if the network request times out.
   */
  rawApiResponse: Maybe<Scalars["String"]["output"]>;
};

/** An association's questionnaire data. */
export type HoaQnrData = {
  __typename: "HoaQnrData";
  expirationSummary: HoaQnrDataExpirationSummary;
  generalComments: Maybe<Scalars["String"]["output"]>;
  sections: Array<QnrSection>;
};

export type HoaQnrDataError = {
  __typename: "HoaQnrDataError";
  code: HoaQnrDataErrorCode;
  message: Scalars["String"]["output"];
};

export enum HoaQnrDataErrorCode {
  NOT_AUTHORIZED_TO_VIEW_QNR = "NOT_AUTHORIZED_TO_VIEW_QNR",
}

export type HoaQnrDataExpirationSummary = {
  __typename: "HoaQnrDataExpirationSummary";
  /**
   * The earliest expiration date out of all the questions, or null if no
   * responses have been entered for expirable questions yet (e.g. if the HOA is
   * new).
   *
   * This date will be in the past if a response has already expired.
   *
   * Note: We do NOT need to convert this to HWD's timezone using a schema
   * directive. The server should represent this as an HWD calendar date, so it
   * should inherently be in HWD's timezone already.
   */
  earliestQuestionExpDate: Maybe<Scalars["String"]["output"]>;
  hasExpiredResponse: Scalars["Boolean"]["output"];
};

export type HoaQnrDataExpirationSummary_earliestQuestionExpDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type HoaQnrDataResult = HoaQnrData | HoaQnrDataError;

export type HoaQnrExpirationSettings = {
  __typename: "HoaQnrExpirationSettings";
  sectionsWithExpirationSettings: Array<QnrSectionWithExpirationSettings>;
};

export type HoaQnrExpirationSettingsError = {
  __typename: "HoaQnrExpirationSettingsError";
  code: HoaQnrExpirationSettingsErrorCode;
  message: Scalars["String"]["output"];
};

export enum HoaQnrExpirationSettingsErrorCode {
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
}

export type HoaQnrExpirationSettingsResult =
  | HoaQnrExpirationSettings
  | HoaQnrExpirationSettingsError;

export type HoaQnrQuickUpdateSettings = {
  __typename: "HoaQnrQuickUpdateSettings";
  sectionsWithQuickUpdateSettings: Array<QnrSectionWithQuickUpdateSettings>;
};

export type HoaQnrQuickUpdateSettingsError = {
  __typename: "HoaQnrQuickUpdateSettingsError";
  code: HoaQnrQuickUpdateSettingsErrorCode;
  message: Scalars["String"]["output"];
};

export enum HoaQnrQuickUpdateSettingsErrorCode {
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
}

export type HoaQnrQuickUpdateSettingsResult =
  | HoaQnrQuickUpdateSettings
  | HoaQnrQuickUpdateSettingsError;

export type HoaQuestionnaires = {
  __typename: "HoaQuestionnaires";
  /** The association's questionnaire responses from the accounting system API. */
  accountingSystemData: HoaQnrAccountingSystemDataResult;
  contactSettings: QnrContactSettings;
  /**
   * The association's questionnaire data.
   * Note: If the 'qnrType' query variable is 'LENDER', then the type of lender
   * questionnaire template will be inferred based on the type of association.
   */
  data: HoaQnrDataResult;
  expirationSettings: HoaQnrExpirationSettingsResult;
  /**
   * Number of units in the HOA, according to the Questionnaire data. If the # of
   * units hasn't been specified yet, this will return null.
   *
   * Note: You could also get this value by inspecting the results of the 'data'
   * resolver, but this field is much more convenient if you're only interested
   * in the number of units.
   */
  numUnits: Maybe<Scalars["PositiveInt"]["output"]>;
  quickUpdateSettings: HoaQnrQuickUpdateSettingsResult;
  sampleStdLenderQnrUrl: Scalars["String"]["output"];
};

export type HoaQuestionnaires_accountingSystemDataArgs = {
  qnrType: QnrType;
};

export type HoaQuestionnaires_dataArgs = {
  qnrType: QnrType;
};

export type HoaRemittance = {
  __typename: "HoaRemittance";
  /** Vendor settings information associated with the HOA. */
  vendorSettings: Maybe<HoaRemittanceVendorSettings>;
};

export type HoaRemittanceVendorSettings = RemittanceVendorSettingsInterface & {
  __typename: "HoaRemittanceVendorSettings";
  createdAt: Scalars["String"]["output"];
  updatedAt: Maybe<Scalars["String"]["output"]>;
  /**
   * If this association's management company has the 'payToAssociations' flag
   * enabled in their remittance settings, then this value should generally match
   * the association's vendor ID in Epicor, a third-party payment platform.
   * Epicor is used to remit the funds collected on each management company's
   * behalf when requestors place orders on HomeWiseDocs.
   *
   * Almost all management companies choose to have their entire remittance sent
   * to a single account. But for the few companies with the 'payToAssociations'
   * flag enabled, the funds collected for each order are sent directly to the
   * association the order was placed for.
   */
  vendorId: Maybe<Scalars["String"]["output"]>;
};

export type HoaRemittanceVendorSettings_createdAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type HoaRemittanceVendorSettings_updatedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type HoaRemittanceVendorSettingsInput = {
  hoaUuid: Scalars["UUID"]["input"];
  /**
   * The vendor ID of the association. May be blank if a vendor ID hasn't been
   * generated for this association in the payment processing system yet.
   */
  hoaVendorId: Scalars["String"]["input"];
};

export type HoaRevenue = {
  __typename: "HoaRevenue";
  breakdown: RevenueBreakdown;
  breakdownOverTime: Array<RevenueDataPoint>;
  hoa: HOA;
};

export enum HoaStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  NEW = "NEW",
}

export enum HoaType {
  CONDO = "CONDO",
  COOP = "COOP",
  PUD = "PUD",
}

export type HwdAdmin = {
  __typename: "HwdAdmin";
  announcement: Maybe<Announcement>;
  mgmtCompanies: Array<ManagementCompany>;
  questionnaires: HwdAdminQnrInfo;
};

export type HwdAdmin_announcementArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type HwdAdminQnrInfo = {
  __typename: "HwdAdminQnrInfo";
  /** A simplified, template-agnostic version of the questionnaire layout. */
  layout: SimpleQnrLayout;
  /** Master list of all questionnaire questions. */
  masterQuestionsList: Array<QnrQuestion>;
};

export type ImpersonateMgmtCompanyInput = {
  targetCompanyUUID: Scalars["UUID"]["input"];
};

export type ImpersonateMgmtCompanyResponse = {
  __typename: "ImpersonateMgmtCompanyResponse";
  success: Scalars["Boolean"]["output"];
};

export type IncludedPredefinedDropdownOptionInput = {
  predefinedVariant: DropdownQnrQuestionPredefinedOptionVariant;
  /**
   * If true, the manager must specify a comment if this dropdown option is
   * selected.
   * Note: If 'predefinedVariant' is 'SEE_COMMENT', then this field will be ignored.
   * A 'SEE_COMMENT' dropdown option always requires a comment.
   */
  requiresComment: Scalars["Boolean"]["input"];
};

export type IndividualItem = ProductInterface & {
  __typename: "IndividualItem";
  category: ProductCategoryType;
  description: Maybe<Scalars["String"]["output"]>;
  hwdFee: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  isForm: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  mgmtCompanyFee: Scalars["Float"]["output"];
  paymentDue: PaymentDueType;
  popupMessage: Maybe<Scalars["String"]["output"]>;
  price: Scalars["Float"]["output"];
  productId: Scalars["ID"]["output"];
  /** See ProductInterface */
  separateHwdFeeLabel: Maybe<Scalars["String"]["output"]>;
};

export type InfoForUpdateRequest = {
  __typename: "InfoForUpdateRequest";
  /**
   * The due date of an update to a form that is not a compliance inspection. Note: when a multiple-form order
   * update includes both compliance inspections and non-inspection forms, please use the later due date between
   * "defaultDueDate" and "inspectionDueDate" as the due date for the entire order update.
   */
  defaultDueDate: Scalars["String"]["output"];
  /**
   * List of forms from the original order that were ordered from the sub HOA.
   * This only returns the forms that are actually available for update.
   */
  formsAvailableForUpdate: Array<FormUpdateInfo>;
  /** The due date of an update to a compliance inspection. */
  inspectionDueDate: Scalars["String"]["output"];
  /**
   * List of forms from the original order that were ordered from any master HOA's (if applicable)
   * This only returns the forms that are actually available for update.
   */
  masterFormsAvailableForUpdate: Array<FormUpdateInfo>;
  /**
   * List of docs that have been updated since the original order. If the requestor places an order
   * update, then these docs will be included for free.
   */
  updatedDocs: Array<UpdatedDoc>;
};

export type InfoForUpdateRequest_defaultDueDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type InfoForUpdateRequest_inspectionDueDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type IntFieldResponseInput = {
  dirty: Scalars["Boolean"]["input"];
  proposedValue?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IntQnrQuestionResponse = {
  __typename: "IntQnrQuestionResponse";
  value: Maybe<IntQnrQuestionResponseValue>;
};

export type IntQnrQuestionResponseValue = {
  __typename: "IntQnrQuestionResponseValue";
  formatted: Scalars["String"]["output"];
  raw: Scalars["Int"]["output"];
};

export type IntQuestionSaveDataInput = {
  apiCode: Scalars["String"]["input"];
  comment: StringFieldResponseInput;
  response: IntFieldResponseInput;
};

export type Lender = {
  __typename: "Lender";
  emphasizeDuringOrderProcess: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  questionnaires: Array<LenderQuestionnaire>;
};

export type LenderQuestionnaire = {
  __typename: "LenderQuestionnaire";
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  previewUrl: Scalars["String"]["output"];
};

/** Local Date and Time. This could be used for dates/times we want to represent in HWD_TIME_ZONE. */
export type LocalDateTime = {
  __typename: "LocalDateTime";
  date: Scalars["LocalDate"]["output"];
  time: Scalars["LocalTime"]["output"];
};

/** Local Date and Time Input. This could be used for inputting dates/times we want to represent in HWD_TIME_ZONE. */
export type LocalDateTimeInput = {
  date: Scalars["LocalDate"]["input"];
  time: Scalars["LocalTime"]["input"];
};

export type LoginMutationError = BaseMutationError & {
  __typename: "LoginMutationError";
  code: LoginMutationErrorCode;
  message: Scalars["String"]["output"];
};

export enum LoginMutationErrorCode {
  /**
   * The client passed valid credentials for a HomeWiseDocs admin. However, the
   * user was trying to log into the requestor site (which doesn't allow admins
   * to log in).
   */
  ADMIN_ATTEMPT_ON_REQ_SITE = "ADMIN_ATTEMPT_ON_REQ_SITE",
  /**
   * Invalid username or password. Don't specify which one is wrong. We don't
   * want to leak that info to the client side.
   */
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  /**
   * The client passed valid credentials for a mgmt company user. However, the
   * user was trying to log into the requestor site (which doesn't allow mgmt
   * company users to log in).
   */
  MGMT_ATTEMPT_ON_REQ_SITE = "MGMT_ATTEMPT_ON_REQ_SITE",
  /**
   * The client passed valid credentials for a requestor. However, the user was
   * trying to log into the manager site (which doesn't allow requestors to log
   * in).
   */
  REQ_ATTEMPT_ON_MGMT_SITE = "REQ_ATTEMPT_ON_MGMT_SITE",
}

export type LoginMutationResponse = MutationResponse & {
  __typename: "LoginMutationResponse";
  error: Maybe<LoginMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type LsqCustomUploadFee = {
  __typename: "LsqCustomUploadFee";
  hwdFee: Scalars["Float"]["output"];
  mgmtCompanyFee: Scalars["Float"]["output"];
  paymentDue: PaymentDueType;
  totalFee: Scalars["Float"]["output"];
};

export type LsqFee = {
  __typename: "LsqFee";
  hwdFee: Scalars["Float"]["output"];
  mgmtCompanyFee: Scalars["Float"]["output"];
  totalFee: Scalars["Float"]["output"];
};

export type LsqOrderInfoBorrowerInput = {
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
};

export type LsqOrderInfoInput = {
  borrower?: InputMaybe<LsqOrderInfoBorrowerInput>;
  /**
   * If the requestor is ordering a Lender-Specific Questionnaire and they chose
   * to upload their own form, then this should be the ID of the upload (which
   * is returned by the 'uploadOwnLsqTemplate' mutation).
   */
  customLsqUploadId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * If the requestor is ordering a Lender-Specific Questionnaire and they
   * selected a form that was already in our system, then this should be the ID
   * of the form they selected.
   */
  existingLsqFormId?: InputMaybe<Scalars["ID"]["input"]>;
  lender?: InputMaybe<LsqOrderInfoLenderInput>;
};

export type LsqOrderInfoLenderAddressInput = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
  unit?: InputMaybe<Scalars["String"]["input"]>;
};

export type LsqOrderInfoLenderInput = {
  address?: InputMaybe<LsqOrderInfoLenderAddressInput>;
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  contactFirstName?: InputMaybe<Scalars["String"]["input"]>;
  contactLastName?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fax?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type ManagementCompany = {
  __typename: "ManagementCompany";
  /**
   * The accounting system(s) used by the management company.
   *
   * If the company isn't integrated with any accounting systems, the list will
   * be empty.
   *
   * If the company uses multiple accounting systems (e.g. b/c they recently
   * acquired another company that uses a different system), then the system used
   * for the "default" integration will be listed first.
   *
   * If the company has multiple _integrations_ for the same _system_ (e.g.
   * because they recently acquired another company that happens to use the same
   * system), the integrations will be deduped. In other words, a given accounting
   * system will not occur more than once in this list.
   */
  accountingSystems: Array<AccountingSystem>;
  /** HOA's that are managed by this management company. */
  allHoas: Array<HOA>;
  analytics: MgmtAnalytics;
  analyticsDateFilterDateRanges: MgmtAnalyticsDateFilterDateRanges;
  createdAt: Scalars["String"]["output"];
  demands: MgmtCompanyDemands;
  /** List of mgmt company employees, sorted alphabetically by name. */
  employees: Array<CompanyEmployee>;
  hoaGroups: Array<HoaGroup>;
  internalId: Scalars["ID"]["output"];
  logoUrl: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  phone: Scalars["String"]["output"];
  questionnaires: MgmtCompanyQuestionnaires;
  /** Information from the remittance system associated with the Management Company. */
  remittance: MgmtCompanyRemittance;
  settings: MgmtSettings;
  uuid: Scalars["ID"]["output"];
};

export type ManagementCompany_allHoasArgs = {
  hoaTypes?: InputMaybe<Array<HoaType>>;
  statuses: Array<HoaStatus>;
};

export type ManagementCompany_createdAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type ManagementCompany_employeesArgs = {
  statuses?: InputMaybe<Array<CompanyEmployeeStatus>>;
};

export type ManagementCompanyNoticeSetting = {
  __typename: "ManagementCompanyNoticeSetting";
  display: Scalars["Boolean"]["output"];
  displayNoticeInState: Maybe<Scalars["String"]["output"]>;
  notice: Maybe<Scalars["String"]["output"]>;
};

export type ManagementCompanyNoticeSettings = {
  __typename: "ManagementCompanyNoticeSettings";
  acknowledgement: Maybe<ManagementCompanyNoticeSetting>;
  demand: Maybe<ManagementCompanyNoticeSetting>;
  questionnaire: Maybe<ManagementCompanyNoticeSetting>;
};

export type MasterHoaCartInput = {
  bundleIds: Array<Scalars["ID"]["input"]>;
  individualItemIds: Array<Scalars["ID"]["input"]>;
  masterHoaUUID: Scalars["ID"]["input"];
};

export type MasterHoaFeeInput = {
  feeInput: HoaFeeInput;
  masterHoaUUID: Scalars["ID"]["input"];
};

export type MatchedAddress = {
  __typename: "MatchedAddress";
  city: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
  streetAddress: Scalars["String"]["output"];
  unit: Maybe<Scalars["String"]["output"]>;
  zip: Scalars["String"]["output"];
};

export type MgmtAnalytics = {
  __typename: "MgmtAnalytics";
  keyMetrics: MgmtKeyMetrics;
  productPerformanceByHOA: Array<MgmtProductPerfForHOA>;
  productProcessingByEmployee: Array<MgmtProductProcessingForEmployee>;
  rushPerformanceByProduct: MgmtRushProductPerfMap;
  trends: MgmtTrends;
};

export type MgmtAnalytics_keyMetricsArgs = {
  filters: MgmtKeyMetricsFiltersInput;
};

export type MgmtAnalytics_productPerformanceByHOAArgs = {
  filters: MgmtProductPerfFiltersInput;
};

export type MgmtAnalytics_productProcessingByEmployeeArgs = {
  filters: MgmtProductProcessingFiltersInput;
};

export type MgmtAnalytics_rushPerformanceByProductArgs = {
  filters: MgmtRushPerfFiltersInput;
};

export type MgmtAnalytics_trendsArgs = {
  filters: MgmtTrendsFiltersInput;
};

export type MgmtAnalyticsDateFilterDateRange = {
  __typename: "MgmtAnalyticsDateFilterDateRange";
  fromDate: Scalars["String"]["output"];
  toDate: Scalars["String"]["output"];
};

export type MgmtAnalyticsDateFilterDateRange_fromDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type MgmtAnalyticsDateFilterDateRange_toDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

/** These keys should be a subset of the enum members of MgmtAnalyticsDateFilterType */
export type MgmtAnalyticsDateFilterDateRanges = {
  __typename: "MgmtAnalyticsDateFilterDateRanges";
  LAST_3_MONTHS: MgmtAnalyticsDateFilterDateRange;
  LAST_7_DAYS: MgmtAnalyticsDateFilterDateRange;
  LAST_WEEK: MgmtAnalyticsDateFilterDateRange;
  LAST_YEAR: MgmtAnalyticsDateFilterDateRange;
  THIS_YEAR: MgmtAnalyticsDateFilterDateRange;
  TODAY: MgmtAnalyticsDateFilterDateRange;
};

export type MgmtAnalyticsDateFilterInput = {
  dateRangeEnd: CalendarDateInput;
  dateRangeStart: CalendarDateInput;
};

export type MgmtAnalyticsDateFilterSettings = {
  __typename: "MgmtAnalyticsDateFilterSettings";
  customDateRangeEnd: Maybe<Scalars["String"]["output"]>;
  customDateRangeStart: Maybe<Scalars["String"]["output"]>;
  dateFilterType: MgmtAnalyticsDateFilterType;
};

export type MgmtAnalyticsDateFilterSettings_customDateRangeEndArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type MgmtAnalyticsDateFilterSettings_customDateRangeStartArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export enum MgmtAnalyticsDateFilterType {
  CUSTOM = "CUSTOM",
  LAST_3_MONTHS = "LAST_3_MONTHS",
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_WEEK = "LAST_WEEK",
  LAST_YEAR = "LAST_YEAR",
  THIS_YEAR = "THIS_YEAR",
  TODAY = "TODAY",
}

export type MgmtAnalyticsEmployeeStatusFilterInput = {
  /** If true, use all statuses (i.e. don't filter by status) */
  all: Scalars["Boolean"]["input"];
  /** If 'all' is false, this field indicates which statuses to filter by. */
  specificEmployeeStatuses?: InputMaybe<Array<CompanyEmployeeStatus>>;
};

export type MgmtAnalyticsEmployeeStatusFilterSettings = {
  __typename: "MgmtAnalyticsEmployeeStatusFilterSettings";
  /** If true, use all statuses (i.e. don't filter by status) */
  all: Scalars["Boolean"]["output"];
  /** If 'all' is false, this field indicates which statuses to filter by. */
  specificEmployeeStatuses: Maybe<Array<CompanyEmployeeStatus>>;
};

export type MgmtAnalyticsHoaStatesFilterInput = {
  /** If true, use all states (i.e. don't filter by HOA state) */
  all: Scalars["Boolean"]["input"];
  /**
   * If 'all' is false, this field indicates which states to filter by.
   * States should use abbreviations, e.g. ["AZ", "CA", "TX"]
   * If 'all' is true, this field will be ignored.
   */
  specificHoaStates?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type MgmtAnalyticsHoaStatesFilterSettings = {
  __typename: "MgmtAnalyticsHoaStatesFilterSettings";
  /** If true, use all states (i.e. don't filter by HOA state) */
  all: Scalars["Boolean"]["output"];
  /**
   * If 'all' is false, this field indicates which states to filter by.
   * We use abbreviations for the states, e.g. ["AZ", "CA", "TX"]
   * If 'all' is true, this will be null.
   */
  specificHoaStates: Maybe<Array<Scalars["String"]["output"]>>;
};

export type MgmtAnalyticsHoaTypesFilterInput = {
  /** If true, use all HOA types (i.e. don't filter by HOA type) */
  all: Scalars["Boolean"]["input"];
  /**
   * If 'all' is false, this field indicates which HOA types to filter by.
   * If 'all' is true, this field will be ignored.
   */
  specificHoaTypes?: InputMaybe<Array<HoaType>>;
};

export type MgmtAnalyticsHoaTypesFilterSettings = {
  __typename: "MgmtAnalyticsHoaTypesFilterSettings";
  /** If true, use all HOA types (i.e. don't filter by HOA type) */
  all: Scalars["Boolean"]["output"];
  /**
   * If 'all' is false, this field indicates which HOA types to filter by.
   * If 'all' is true, this will be null.
   */
  specificHoaTypes: Maybe<Array<HoaType>>;
};

export type MgmtAnalyticsProductCategoriesFilterInput = {
  /**
   * If true, use all product categories (i.e. don't filter by product category)
   * Note: Docs & TRID will not be included.
   */
  all: Scalars["Boolean"]["input"];
  /** If 'all' is false, this field indicates which products to filter by. */
  specificProductCategories?: InputMaybe<Array<ProductCategoryType>>;
};

export type MgmtAnalyticsProductCategoriesFilterSettings = {
  __typename: "MgmtAnalyticsProductCategoriesFilterSettings";
  /**
   * If true, use all product categories (i.e. don't filter by product category)
   * Note: Docs & TRID will not be included.
   */
  all: Scalars["Boolean"]["output"];
  /** If 'all' is false, this field indicates which products to filter by. */
  specificProductCategories: Maybe<Array<ProductCategoryType>>;
};

export type MgmtAnalyticsSaveDateFilterInput = {
  customDateRangeEnd?: InputMaybe<CalendarDateInput>;
  customDateRangeStart?: InputMaybe<CalendarDateInput>;
  dateFilterType: MgmtAnalyticsDateFilterType;
};

export type MgmtCompanyDemands = {
  __typename: "MgmtCompanyDemands";
  payees: Array<DemandPayee>;
};

export type MgmtCompanyHoaRemittanceAmounts = RemittanceAmountsInterface & {
  __typename: "MgmtCompanyHoaRemittanceAmounts";
  adjustmentsAmount: Scalars["Float"]["output"];
  cancelsAmount: Scalars["Float"]["output"];
  /** The details of the order line items and respective transactions that contribute to the remittance amounts. */
  details: Array<RemittanceOrderLineItemDetail>;
  grossAmount: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  netAmount: Scalars["Float"]["output"];
  offsetAmount: Scalars["Float"]["output"];
  uuid: Scalars["UUID"]["output"];
  vendorId: Scalars["String"]["output"];
};

export type MgmtCompanyQuestionnaires = {
  __typename: "MgmtCompanyQuestionnaires";
  accountingImportSettings: MgmtQnrAcctgImportSettingsResult;
  defaultContactSettings: QnrContactSettings;
  /**
   * Number of units in the Management Company, according to the Questionnaire data.
   * This is a sum of all HOAs units that are part of the Management Company.
   * If none of their HOAs has # of units specified, this will return null.
   */
  numUnits: Maybe<Scalars["PositiveInt"]["output"]>;
  optInQuestionSettings: MgmtQnrOptInQuestionSettingsResult;
};

export type MgmtCompanyQuestionnaires_accountingImportSettingsArgs = {
  accountingSystemCode: AccountingSystemCode;
};

export type MgmtCompanyQuestionnaires_numUnitsArgs = {
  hoaStatuses: Array<HoaStatus>;
};

export type MgmtCompanyRemittance = {
  __typename: "MgmtCompanyRemittance";
  /** Batch Group information associated with the Management Company. */
  batchGroup: Maybe<MgmtCompanyRemittanceBatchGroup>;
  /** List of email contacts associated with the Management Company. */
  emailContacts: Array<MgmtCompanyRemittanceEmailContact>;
  /**
   * The date of the most recent Remittance run for the Management Company.
   * It refers to the end of the timeframe chosen for the previous run.
   */
  latestRemittanceDate: Maybe<Scalars["String"]["output"]>;
  remittanceAmountsForTimeframe: Maybe<MgmtCompanyRemittanceAmounts>;
  runs: Array<MgmtCompanyRemittanceRun>;
  transactions: RemittanceTransactions;
  /** Vendor settings information associated with the Management Company. */
  vendorSettings: Maybe<MgmtCompanyRemittanceVendorSettings>;
};

export type MgmtCompanyRemittance_latestRemittanceDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type MgmtCompanyRemittance_remittanceAmountsForTimeframeArgs = {
  fromDate: CalendarDateInput;
  toDate: CalendarDateInput;
};

export type MgmtCompanyRemittance_runsArgs = {
  remittanceRunsInput: RemittanceRunsInput;
};

export type MgmtCompanyRemittance_transactionsArgs = {
  remittanceTransactionsInput: RemittanceTransactionsInput;
};

export type MgmtCompanyRemittanceAdjustment = {
  /** Adjustment amount. */
  amount: Scalars["Float"]["input"];
  /** The UUID of the Management Company. */
  mgmtCompanyUuid: Scalars["UUID"]["input"];
  /** Order confirmation of the adjustment. */
  orderConfirmation: Scalars["String"]["input"];
  /** The reason for the adjustment. */
  reason: Scalars["String"]["input"];
};

export type MgmtCompanyRemittanceAmounts = RemittanceAmountsInterface & {
  __typename: "MgmtCompanyRemittanceAmounts";
  adjustmentsAmount: Scalars["Float"]["output"];
  cancelsAmount: Scalars["Float"]["output"];
  /** The details of the order line items and respective transactions that contribute to the remittance amounts. */
  details: Array<RemittanceOrderLineItemDetail>;
  grossAmount: Scalars["Float"]["output"];
  /**
   * This field will be null if the management company isn't configured to remit
   * to its associations (see the \`vendorSettings\` field on \`MgmtCompanyRemittance\`).
   */
  hoaAmounts: Maybe<Array<MgmtCompanyHoaRemittanceAmounts>>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  netAmount: Scalars["Float"]["output"];
  offsetAmount: Scalars["Float"]["output"];
  uuid: Scalars["UUID"]["output"];
  vendorId: Scalars["String"]["output"];
};

export type MgmtCompanyRemittanceBatchGroup = RemittanceBatchGroupInterface & {
  __typename: "MgmtCompanyRemittanceBatchGroup";
  description: Maybe<Scalars["String"]["output"]>;
  /** Name of the Batch Group that should be unique. */
  name: Scalars["String"]["output"];
  uuid: Scalars["UUID"]["output"];
};

export type MgmtCompanyRemittanceEmailContact = {
  __typename: "MgmtCompanyRemittanceEmailContact";
  createdAt: Scalars["String"]["output"];
  email: Scalars["TrimmedNonEmptyString"]["output"];
  id: Scalars["ID"]["output"];
  isPrimary: Scalars["Boolean"]["output"];
  name: Scalars["TrimmedNonEmptyString"]["output"];
  updatedAt: Maybe<Scalars["String"]["output"]>;
};

export type MgmtCompanyRemittanceEmailContact_createdAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type MgmtCompanyRemittanceEmailContact_updatedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type MgmtCompanyRemittanceRun = {
  __typename: "MgmtCompanyRemittanceRun";
  adjustments: Maybe<Scalars["Float"]["output"]>;
  cancels: Scalars["Float"]["output"];
  gross: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  net: Maybe<Scalars["Float"]["output"]>;
  periodEnd: Scalars["String"]["output"];
  periodStart: Scalars["String"]["output"];
  runDate: Scalars["String"]["output"];
};

export type MgmtCompanyRemittanceRun_periodEndArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type MgmtCompanyRemittanceRun_periodStartArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type MgmtCompanyRemittanceRun_runDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type MgmtCompanyRemittanceVendorSettings =
  RemittanceVendorSettingsInterface & {
    __typename: "MgmtCompanyRemittanceVendorSettings";
    createdAt: Scalars["String"]["output"];
    /**
     * Indicates that payment should be remitted to the individual associations
     * within the management company, rather than all payment being sent to the
     * management company. See HOA 'vendorId' field.
     */
    payToAssociations: Scalars["Boolean"]["output"];
    updatedAt: Maybe<Scalars["String"]["output"]>;
    /**
     * This value should generally match the management company's vendor ID in
     * Epicor, a third-party payment platform. Epicor is used to remit the funds
     * collected on each management company's behalf when requestors place orders
     * on HomeWiseDocs.
     *
     * Unfortunately, there can be times when there is no vendor ID available for a
     * management company - for example, if a management company changes names,
     * there will be a period where the old vendor ID is invalid but a new vendor
     * ID isn't yet available. For that reason, an empty value for the 'vendorId'
     * field is explicitly permitted.
     */
    vendorId: Maybe<Scalars["String"]["output"]>;
  };

export type MgmtCompanyRemittanceVendorSettings_createdAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type MgmtCompanyRemittanceVendorSettings_updatedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type MgmtDeferredReleaseSettings = {
  __typename: "MgmtDeferredReleaseSettings";
  /**
   * If true, employees of this mgmt company can use the Deferred Release feature.
   * Note: This does _not_ apply to Questionnaires. For qnrs, see 'isQnrAllowed'.
   */
  isAllowed: Scalars["Boolean"]["output"];
  /**
   * If true, employees of this mgmt company can use the Deferred Release feature
   * for Lender & Appraiser Questionnaires.
   */
  isQnrAllowed: Scalars["Boolean"]["output"];
};

export type MgmtDeferredReleaseSettings_isQnrAllowedArgs = {
  qnrType?: InputMaybe<QnrType>;
};

export type MgmtKeyMetrics = {
  __typename: "MgmtKeyMetrics";
  /**
   * Order counts for each HOA in the management company. Data is sorted
   * alphabetically by HOA name. As a result, the HOA's will be in the same
   * order as they are in the revenueByHOA field.
   */
  orderCountsByHOA: Array<HoaOrderCount>;
  /**
   * The management company's daily order counts over time. Data is sorted by
   * date in ascending order, i.e. earliest date first.
   */
  orderCountsOverTime: Array<OrderCountDataPoint>;
  /**
   * Revenue for each HOA in the management company. Data is sorted
   * alphabetically by HOA name. As a result, the HOA's will be in the same
   * order as they are in the orderCountsByHOA field.
   */
  revenueByHOA: Array<HoaRevenue>;
  /**
   * The management company's daily revenue over time. Data is sorted by date in
   * ascending order, i.e. earliest date first.
   */
  revenueOverTime: Array<RevenueDataPoint>;
};

export type MgmtKeyMetricsFilterSettings = {
  __typename: "MgmtKeyMetricsFilterSettings";
  dateFilter: MgmtAnalyticsDateFilterSettings;
  hoaStates: MgmtAnalyticsHoaStatesFilterSettings;
  hoaTypes: MgmtAnalyticsHoaTypesFilterSettings;
  uuid: Scalars["ID"]["output"];
};

export type MgmtKeyMetricsFiltersInput = {
  dateFilter: MgmtAnalyticsDateFilterInput;
  hoaStates: MgmtAnalyticsHoaStatesFilterInput;
  hoaTypes: MgmtAnalyticsHoaTypesFilterInput;
};

export type MgmtPerfByALaCarteProductType = MgmtProductPerfMap & {
  __typename: "MgmtPerfByALaCarteProductType";
  appraiserQuestionnaire: MgmtProductPerfBreakdown;
  complianceInspection: MgmtProductPerfBreakdown;
  demand: MgmtProductPerfBreakdown;
  docs: MgmtProductPerfBreakdown;
  lenderQuestionnaire: MgmtProductPerfBreakdown;
  report: MgmtProductPerfBreakdown;
  resaleDisclosure: MgmtProductPerfBreakdown;
};

/**
 * The management company's performance for each type of bundle.
 *
 * Other than docsOnly and multiProduct, these fields refer to bundles
 * containing doc(s) and a *single* type of form.
 * For example, lenderQuestionnaire refers to a bundle containing a lender
 * questionnaire and 0 or more documents. Ideally, that bundle should have at
 * least 1 document, but some companies have created "faux" bundles with just a
 * single form and nothing else.
 */
export type MgmtPerfByBundleType = MgmtProductPerfMap & {
  __typename: "MgmtPerfByBundleType";
  appraiserQuestionnaire: MgmtProductPerfBreakdown;
  complianceInspection: MgmtProductPerfBreakdown;
  demand: MgmtProductPerfBreakdown;
  docsOnly: MgmtProductPerfBreakdown;
  lenderQuestionnaire: MgmtProductPerfBreakdown;
  /**
   * A bundle containing multiple forms (and possibly documents). It would be
   * more consistent to use the name "multiForm", but the term "multi-product"
   * is the standard term used within HomeWise.
   */
  multiProduct: MgmtProductPerfBreakdown;
  report: MgmtProductPerfBreakdown;
  resaleDisclosure: MgmtProductPerfBreakdown;
};

export type MgmtProductPerfBreakdown = {
  __typename: "MgmtProductPerfBreakdown";
  atCloseFees: Scalars["Float"]["output"];
  numCanceled: Scalars["Int"]["output"];
  numOrdered: Scalars["Int"]["output"];
  upFrontRevenue: Scalars["Float"]["output"];
};

export type MgmtProductPerfFilterSettings = {
  __typename: "MgmtProductPerfFilterSettings";
  dateFilter: MgmtAnalyticsDateFilterSettings;
  hoaStates: MgmtAnalyticsHoaStatesFilterSettings;
  hoaTypes: MgmtAnalyticsHoaTypesFilterSettings;
  uuid: Scalars["ID"]["output"];
};

export type MgmtProductPerfFiltersInput = {
  dateFilter: MgmtAnalyticsDateFilterInput;
  hoaStates: MgmtAnalyticsHoaStatesFilterInput;
  hoaTypes: MgmtAnalyticsHoaTypesFilterInput;
};

export type MgmtProductPerfForHOA = {
  __typename: "MgmtProductPerfForHOA";
  /** Performance for each type of a la carte product. */
  aLaCartePerf: MgmtPerfByALaCarteProductType;
  /** Performance for each type of bundle. */
  bundlePerf: MgmtPerfByBundleType;
  hoa: HOA;
};

export type MgmtProductPerfMap = {
  appraiserQuestionnaire: MgmtProductPerfBreakdown;
  complianceInspection: MgmtProductPerfBreakdown;
  demand: MgmtProductPerfBreakdown;
  lenderQuestionnaire: MgmtProductPerfBreakdown;
  report: MgmtProductPerfBreakdown;
  resaleDisclosure: MgmtProductPerfBreakdown;
};

export type MgmtProductProcessing = {
  __typename: "MgmtProductProcessing";
  /**
   * Number of products released on time. Only includes products that actually
   * required processing.
   */
  numOnTime: Scalars["Int"]["output"];
  /**
   * Number of products released after the due date. Only includes products that
   * actually required processing.
   */
  numPastDue: Scalars["Int"]["output"];
  productCategory: ProductCategoryType;
};

export type MgmtProductProcessingFilterSettings = {
  __typename: "MgmtProductProcessingFilterSettings";
  dateFilter: MgmtAnalyticsDateFilterSettings;
  employeeStatuses: MgmtAnalyticsEmployeeStatusFilterSettings;
  hoaStates: MgmtAnalyticsHoaStatesFilterSettings;
  hoaTypes: MgmtAnalyticsHoaTypesFilterSettings;
  productCategories: MgmtAnalyticsProductCategoriesFilterSettings;
  uuid: Scalars["ID"]["output"];
};

export type MgmtProductProcessingFiltersInput = {
  employeeStatuses: MgmtAnalyticsEmployeeStatusFilterInput;
  hoaStates: MgmtAnalyticsHoaStatesFilterInput;
  hoaTypes: MgmtAnalyticsHoaTypesFilterInput;
  productCategories: MgmtAnalyticsProductCategoriesFilterInput;
  releaseDateFilter: MgmtAnalyticsDateFilterInput;
};

export type MgmtProductProcessingForEmployee = {
  __typename: "MgmtProductProcessingForEmployee";
  employee: CompanyEmployee;
  /**
   * The employee's processing data for different products. The array only
   * includes product categories that the employee released.
   * Note: The array isn't sorted in any particular order.
   */
  productProcessing: Array<MgmtProductProcessing>;
};

export type MgmtQnrAcctgImportSettings = {
  __typename: "MgmtQnrAcctgImportSettings";
  /**
   * A list of questionnaire questions with the mgmt company's accounting
   * integration import settings for each question. These settings determine
   * which questions should be imported from the mgmt company's accounting system
   * when they process questionnaires.
   *
   * This list only includes the questions that HWD can import from the
   * accounting system. So the set of questions in this list depends on which
   * accounting system the mgmt company uses.
   *
   * If the mgmt company has not enabled an accounting system integration in
   * their HWD account or if their accounting system doesn't support our
   * questionnaire integration, this will be an empty list. You should query the
   * 'ManagementCompany.accountingSystem' resolver if you want to check for those
   * cases explicitly.
   */
  questionsWithAcctgImportSettings: Array<QnrQuestionWithAcctgImportSettings>;
};

export type MgmtQnrAcctgImportSettingsError = {
  __typename: "MgmtQnrAcctgImportSettingsError";
  code: MgmtQnrAcctgImportSettingsErrorCode;
  message: Scalars["String"]["output"];
};

export enum MgmtQnrAcctgImportSettingsErrorCode {
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
}

export type MgmtQnrAcctgImportSettingsResult =
  | MgmtQnrAcctgImportSettings
  | MgmtQnrAcctgImportSettingsError;

export type MgmtQnrOptInQuestionSettings = {
  __typename: "MgmtQnrOptInQuestionSettings";
  /** List of questionnaire sections that contain any opt-in questions. */
  sectionsWithOptInQuestionSettings: Array<QnrSectionWithOptInQuestionSettings>;
};

export type MgmtQnrOptInQuestionSettingsError = {
  __typename: "MgmtQnrOptInQuestionSettingsError";
  code: MgmtQnrOptInQuestionSettingsErrorCode;
  message: Scalars["String"]["output"];
};

export enum MgmtQnrOptInQuestionSettingsErrorCode {
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
}

export type MgmtQnrOptInQuestionSettingsResult =
  | MgmtQnrOptInQuestionSettings
  | MgmtQnrOptInQuestionSettingsError;

export type MgmtRemittanceVendorSettingsInput = {
  hoaRemittanceVendorSettings?: InputMaybe<
    Array<HoaRemittanceVendorSettingsInput>
  >;
  /**
   * The vendor ID of the management company. May be blank if a vendor ID hasn't
   * been generated for this company in the payment processing system yet.
   */
  mgmtVendorId: Scalars["String"]["input"];
  /**
   * Indicates whether payment should be remitted to individual associations of
   * the company when remittance is run. If this is set to false, any values
   * provided in 'associationVendorSettings' will be ignored, since those
   * settings have no effect when payment isn't remitted to individual
   * associations.
   */
  payToAssociations: Scalars["Boolean"]["input"];
};

export type MgmtRushPerfFilterSettings = {
  __typename: "MgmtRushPerfFilterSettings";
  completionDateFilter: MgmtAnalyticsDateFilterSettings;
  hoaStates: MgmtAnalyticsHoaStatesFilterSettings;
  hoaTypes: MgmtAnalyticsHoaTypesFilterSettings;
  uuid: Scalars["ID"]["output"];
};

export type MgmtRushPerfFiltersInput = {
  completionDateFilter: MgmtAnalyticsDateFilterInput;
  hoaStates: MgmtAnalyticsHoaStatesFilterInput;
  hoaTypes: MgmtAnalyticsHoaTypesFilterInput;
};

export type MgmtRushPerfForProduct = {
  __typename: "MgmtRushPerfForProduct";
  /**
   * The number of standard (i.e. non-rush) orders that were completed for this
   * product type.
   */
  numCompletedStdOrders: Scalars["Int"]["output"];
  /**
   * Note: this data only includes completed rush orders. It does _not_ include
   * pending or canceled orders.
   */
  perfByRushTier: Array<RushPerfForTier>;
};

/**
 * Rush performance for each type of product that can be rushed.
 * We deliberately omit appraiser questionnaires, which currently can't be rushed.
 */
export type MgmtRushProductPerfMap = {
  __typename: "MgmtRushProductPerfMap";
  complianceInspection: MgmtRushPerfForProduct;
  demand: MgmtRushPerfForProduct;
  lenderQuestionnaire: MgmtRushPerfForProduct;
  multiProduct: MgmtRushPerfForProduct;
  report: MgmtRushPerfForProduct;
  resaleDisclosure: MgmtRushPerfForProduct;
};

export type MgmtSettings = {
  __typename: "MgmtSettings";
  deferredRelease: MgmtDeferredReleaseSettings;
};

export type MgmtTrends = {
  __typename: "MgmtTrends";
  orderCountsByYear: Array<MgmtTrendsOrderCountsForYear>;
  revenueByYear: Array<MgmtTrendsRevenueForYear>;
};

export type MgmtTrendsFilterSettings = {
  __typename: "MgmtTrendsFilterSettings";
  hoaStates: MgmtAnalyticsHoaStatesFilterSettings;
  hoaTypes: MgmtAnalyticsHoaTypesFilterSettings;
  uuid: Scalars["ID"]["output"];
  years: Array<Scalars["Int"]["output"]>;
};

export type MgmtTrendsFiltersInput = {
  hoaStates: MgmtAnalyticsHoaStatesFilterInput;
  hoaTypes: MgmtAnalyticsHoaTypesFilterInput;
  years: Array<Scalars["Int"]["input"]>;
};

export type MgmtTrendsOrderCountsForYear = {
  __typename: "MgmtTrendsOrderCountsForYear";
  /**
   * Array of length 12 representing the mgmt company's order counts in each
   * month of the year. If the company couldn't have possibly had any orders in a
   * given month, then the corresponding array element will be null. For example,
   * if a month is in the future or if it was before the date when the company's
   * account was created, then it'll be null.
   */
  breakdownByMonth: Array<Maybe<OrderCountBreakdown>>;
  year: Scalars["Int"]["output"];
};

export type MgmtTrendsRevenueForYear = {
  __typename: "MgmtTrendsRevenueForYear";
  /**
   * Array of length 12 representing the mgmt company's revenue in each month of
   * the year. If the company couldn't have possibly had any revenue in a given
   * month, then the corresponding array element will be null. For example, if
   * a month is in the future or if it was before the date when the company's
   * account was created, then it'll be null.
   */
  breakdownByMonth: Array<Maybe<RevenueBreakdown>>;
  year: Scalars["Int"]["output"];
};

export enum ModifyBuyerErrorCode {
  INVALID_FORM_DATA = "INVALID_FORM_DATA",
  /**
   * This enum member cannot be returned by the modifyBuyer mutation. Since the
   * information that can be changed using the modifyBuyer mutation is a subset
   * of the information that can be changed using the modifyOrder mutation, we
   * allow the modifyBuyer operation to go through even if modifyOrder is available.
   */
  MODIFY_ORDER_AVAIL_AND_SUPERSEDES_MODIFY_BUYER = "MODIFY_ORDER_AVAIL_AND_SUPERSEDES_MODIFY_BUYER",
  NOT_MOST_RECENT_ORDER = "NOT_MOST_RECENT_ORDER",
  ORDER_CANCELED = "ORDER_CANCELED",
  ORDER_COMPLETED_BUT_UPDATE_NOT_AVAIL = "ORDER_COMPLETED_BUT_UPDATE_NOT_AVAIL",
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  ORDER_NOT_FROM_REQUESTOR_ORG = "ORDER_NOT_FROM_REQUESTOR_ORG",
  ORDER_NOT_PAID = "ORDER_NOT_PAID",
  ORDER_NOT_RESALE_TRANSACTION = "ORDER_NOT_RESALE_TRANSACTION",
  QUESTIONNAIRE_ONLY_ORDER = "QUESTIONNAIRE_ONLY_ORDER",
}

export type ModifyBuyerMutationError = BaseMutationError & {
  __typename: "ModifyBuyerMutationError";
  code: ModifyBuyerErrorCode;
  message: Scalars["String"]["output"];
};

export type ModifyBuyerMutationResponse = MutationResponse & {
  __typename: "ModifyBuyerMutationResponse";
  error: Maybe<ModifyBuyerMutationError>;
  order: Maybe<Order>;
  success: Scalars["Boolean"]["output"];
};

export type ModifyBuyerTransactionDetailsInput = {
  buyer: BuyerInput;
  buyerOccupied?: InputMaybe<Scalars["Boolean"]["input"]>;
  loanNumber?: InputMaybe<Scalars["String"]["input"]>;
  resaleFormOptionValues?: InputMaybe<Array<ResaleFormOptionInput>>;
};

export enum ModifyOrderErrorCode {
  HWD_PROCESSING_LSQ = "HWD_PROCESSING_LSQ",
  INVALID_FORM_DATA = "INVALID_FORM_DATA",
  MGMT_CO_PROCESSING_ORDER = "MGMT_CO_PROCESSING_ORDER",
  ORDER_CANCELED = "ORDER_CANCELED",
  ORDER_COMPLETED = "ORDER_COMPLETED",
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  ORDER_NOT_FROM_REQUESTOR_ORG = "ORDER_NOT_FROM_REQUESTOR_ORG",
  ORDER_NOT_PAID = "ORDER_NOT_PAID",
}

export type ModifyOrderMutationError = BaseMutationError & {
  __typename: "ModifyOrderMutationError";
  code: ModifyOrderErrorCode;
  message: Scalars["String"]["output"];
};

export type ModifyOrderMutationResponse = MutationResponse & {
  __typename: "ModifyOrderMutationResponse";
  error: Maybe<ModifyOrderMutationError>;
  order: Maybe<Order>;
  success: Scalars["Boolean"]["output"];
};

export type MultiSelectFieldResponseInput = {
  dirty: Scalars["Boolean"]["input"];
  /**
   * If 'dirty' is true, then this field should be a list of UUIDs of the
   * selected option(s), including any option(s) that were previously selected.
   *
   * If the user wants to delete all previously-selected options, then you should
   * pass an empty list. So the list should be non-null if 'dirty' is true.
   */
  selectedOptionUUIDs?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type MultiSelectQnrQuestion = {
  __typename: "MultiSelectQnrQuestion";
  apiCode: Scalars["String"]["output"];
  core: CoreQuestionInfo;
  options: Array<MultiSelectQnrQuestionOption>;
  responseRequired: Scalars["Boolean"]["output"];
};

export type MultiSelectQnrQuestionOption = {
  __typename: "MultiSelectQnrQuestionOption";
  /**
   * If this field is true and the option is selected, no other options can be
   * selected. This allows multi-selects to have "None of the above" functionality.
   */
  exclusive: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  /** If true, the manager must specify a comment if this option is selected. */
  requiresComment: Scalars["Boolean"]["output"];
  uuid: Scalars["ID"]["output"];
};

export type MultiSelectQnrQuestionOptionWithResponse = {
  __typename: "MultiSelectQnrQuestionOptionWithResponse";
  /**
   * If this field is true and the option is selected, no other options can be
   * selected. This allows multi-selects to have "None of the above" functionality.
   */
  exclusive: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  /** If true, the manager must specify a comment if this option is selected. */
  requiresComment: Scalars["Boolean"]["output"];
  selected: Scalars["Boolean"]["output"];
  uuid: Scalars["ID"]["output"];
};

export type MultiSelectQnrQuestionWithResponse = {
  __typename: "MultiSelectQnrQuestionWithResponse";
  apiCode: Scalars["String"]["output"];
  comment: Maybe<Scalars["String"]["output"]>;
  core: CoreQuestionInfoForResponse;
  expirationInfo: Maybe<QnrResponseExpirationInfo>;
  optionsWithResponses: Array<MultiSelectQnrQuestionOptionWithResponse>;
  responseRequired: Scalars["Boolean"]["output"];
};

export type MultiSelectQuestionSaveDataInput = {
  apiCode: Scalars["String"]["input"];
  comment: StringFieldResponseInput;
  response: MultiSelectFieldResponseInput;
};

export type MultiUnitOwnersBucketQnrDataInput = {
  numOwners: Scalars["Int"]["input"];
  numUnitsPerOwner: Scalars["Int"]["input"];
};

/**
 * If 'dirty' is true, 'allBuckets' should be a non-empty array describing each
 * of the multi-unit owner "buckets".
 *
 * If 'dirty' is false, 'allBuckets' should be null (it will be ignored).
 */
export type MultiUnitOwnersResponseSaveQnrDataInput = {
  allBuckets?: InputMaybe<Array<MultiUnitOwnersBucketQnrDataInput>>;
  dirty: Scalars["Boolean"]["input"];
};

export type MultiUnitOwnersSaveQnrDataInput = {
  comment: StringFieldResponseInput;
  response: MultiUnitOwnersResponseSaveQnrDataInput;
};

export type Mutation = {
  __typename: "Mutation";
  /**
   * Persist the date when the requestor acknowledged their obligations WRT the
   * homeowners' privacy rights, e.g. their CCPA rights.
   */
  acknowledgeHomeownerPrivacyRights: AcknowledgeHomeownerPrivacyRightsMutationResponse;
  /**
   * Allow a master requestor to add a new requestor to the org's account.
   * In the future, we may instead allow the master user to simply send an email
   * invitation to the person they want to join the account.
   */
  addNewUserToRequestorOrg: AddNewUserToRequestorOrgResponse;
  addRush: AddRushMutationResponse;
  /** Attach a document to an existing order. */
  attachDocumentToOrder: AttachDocumentToOrderMutationResponse;
  bulkSaveStructuralSafety: BulkSaveStructuralSafetyMutationResponse;
  /**
   * Cancel an order.
   * Currently, this mutation only supports requestors. Eventually, we should
   * see if it can be generalized to support other user types. But until then,
   * we should only allow requestors to use it.
   */
  cancelOrder: CancelOrderMutationResponse;
  changePassword: ChangePasswordMutationResponse;
  /**
   * Copy an HOA's questionnaire template data to a same-type
   * HOA belonging to the same mgmt company.
   */
  copyHoaQnrData: CopyHoaQnrDataMutationResponse;
  /**
   * Copy an HOA's Expiration settings to all same-type HOAs in the specified
   * HOA group(s).
   *
   * For example, if 'sourceHoaUuid' represents a condo association, then its
   * expiration settings will be applied to all condo associations in each of
   * the specified group(s).
   */
  copyHoaQnrExpirationSettingsToHoaGroups: CopyHoaQnrSettingsMutationResponse;
  /**
   * Copy an HOA's expiration settings to the mgmt company's same-type HOAs.
   *
   * If 'all' is true, the settings will be applied to all same-type HOAs.
   * O/w, the 'targetHoaUuids' variable should specify a list of HOA UUIDs to
   * copy the settings to. 'targetHoaUuids' should reference same-type HOAs--any
   * other HOAs will be ignored.
   *
   * For example, if 'sourceHoaUuid' represents a condo association and 'all' is
   * true, then the condo association's expiration settings will be copied to
   * all of the mgmt company's condo associations.
   */
  copyHoaQnrExpirationSettingsToHoas: CopyHoaQnrSettingsMutationResponse;
  /**
   * Copy an HOA's Quick Update settings to all same-type HOAs in the specified
   * HOA group(s).
   *
   * For example, if 'sourceHoaUuid' represents a condo association, then its
   * Quick Update settings will be applied to all condo associations in each of
   * the specified group(s).
   */
  copyHoaQnrQuickUpdateSettingsToHoaGroups: CopyHoaQnrSettingsMutationResponse;
  /**
   * Copy an HOA's Quick Update settings to the mgmt company's same-type HOAs.
   *
   * If 'all' is true, the settings will be applied to all same-type HOAs.
   * O/w, the 'targetHoaUuids' variable should specify a list of HOA UUIDs to
   * copy the settings to. 'targetHoaUuids' should reference same-type HOAs--any
   * other HOAs will be ignored.
   *
   * For example, if 'sourceHoaUuid' represents a condo association and 'all' is
   * true, then the condo association's Quick Update settings will be copied to
   * all of the mgmt company's condo associations.
   */
  copyHoaQnrQuickUpdateSettingsToHoas: CopyHoaQnrSettingsMutationResponse;
  /** Create a Demand Payee */
  createDemandPayee: CreateDemandPayeeResponse;
  /** Create a new follow-up questionnaire question. */
  createFollowUpQnrQuestion: CreateFollowUpQnrQuestionMutationResponse;
  /** Create a new primary questionnaire question. */
  createPrimaryQnrQuestion: CreatePrimaryQnrQuestionMutationResponse;
  /** Create an announcement for requestors. */
  createRequestorAnnouncement: CreateRequestorAnnouncementResponse;
  /** Delete an announcement for requestors. */
  deleteDemandPayee: DeleteDemandPayeeResponse;
  deleteQnrQuestion: DeleteQnrQuestionMutationResponse;
  /** Delete a Remittance Batch Group. */
  deleteRemittanceBatchGroup: DeleteRemittanceBatchGroupMutationResponse;
  /**
   * Delete a requestor account by UUID. A non-master can only delete their
   * own account. A master can only delete child accounts (not the master account).
   * Returns UUID of the deleted account.
   */
  deleteRequestorAccount: Scalars["String"]["output"];
  /** Delete an announcement for requestors. */
  deleteRequestorAnnouncement: DeleteRequestorAnnouncementResponse;
  /**
   * Generates parameters that the client needs in order to open Lightbox for
   * accepting credit card payments.
   *
   * Note: This mutation does _not_ require auth. The 'addRush' and 'payForOrder'
   * payment flows, which do not require auth, need to use this mutation.
   */
  generateLightboxParams: GenerateLightboxParamsResult;
  /**
   * Impersonate a mgmt company. In particular, this mutation will sign an
   * impersonation JWT for the specified company and put the token into the
   * impersonation cookie.
   */
  impersonateMgmtCompany: ImpersonateMgmtCompanyResponse;
  login: LoginMutationResponse;
  /**
   * Modify the info that was entered in both the Buyer Information and
   * Transaction Information sections of the Transaction Details page.
   */
  modifyBuyer: ModifyBuyerMutationResponse;
  /**
   * Modify an order. In particular, modify the info that was entered on the
   * Transaction Details page.
   * Currently, this mutation only supports requestors. Eventually, we should
   * see if it can be generalized to support other user types. But until then,
   * we should only allow requestors to use it.
   */
  modifyOrder: ModifyOrderMutationResponse;
  payForOrder: PayForOrderMutationResponse;
  placeOrder: PlaceOrderMutationResponse;
  /** Processes remittance for all companies in a specified batch group. */
  processRemittanceBatchRun: ProcessRemittanceBatchRunMutationResponse;
  releaseQnrQuestion: ReleaseQnrQuestionMutationResponse;
  /** Reorder a set of follow-up qnr questions. */
  reorderFollowUpQnrQuestions: ReorderFollowUpQnrQuestionsMutationResponse;
  requestForgotUsernameEmail: RequestForgotUsernameEmailMutationResponse;
  requestOrderUpdate: RequestOrderUpdateMutationResponse;
  requestPasswordResetEmail: RequestPasswordResetEmailMutationResponse;
  resetPassword: ResetPasswordMutationResponse;
  /** Save an HOA's expiration settings */
  saveHoaQnrExpirationSettings: SaveHoaQnrExpirationSettingsMutationResponse;
  /** Save an HOA's Quick Update settings */
  saveHoaQnrQuickUpdateSettings: SaveHoaQnrQuickUpdateSettingsMutationResponse;
  saveKeyMetricsFilters: SaveKeyMetricsFiltersMutationResponse;
  /** Save a mgmt company's accounting import settings */
  saveMgmtQnrAcctgImportSettings: SaveMgmtQnrAcctgImportSettingsMutationResponse;
  saveMgmtQnrDefaultContactSettings: SaveMgmtQnrDefaultContactSettingsMutationResponse;
  /** Save a management company's Opt In settings */
  saveMgmtQnrOptInQuestionSettings: SaveMgmtQnrOptInSettingsMutationResponse;
  /** Save a mgmt company's remittance vendor settings. */
  saveMgmtRemittanceEmailContacts: SaveMgmtRemittanceEmailContactsResponse;
  /** Save a mgmt company's remittance vendor settings. */
  saveMgmtRemittanceVendorSettings: SaveMgmtRemittanceVendorSettingsMutationResponse;
  saveProductPerfFilters: SaveProductPerfFiltersMutationResponse;
  saveProductProcessingFilters: SaveProductProcessingFiltersMutationResponse;
  saveQnrData: SaveQnrDataMutationResponse;
  /** Save a Remittance Batch Group. */
  saveRemittanceBatchGroup: SaveRemittanceBatchGroupMutationResponse;
  saveRushPerfFilters: SaveRushPerfFiltersMutationResponse;
  saveTrendsFilters: SaveTrendsFiltersMutationResponse;
  signUp: SignUpMutationResponse;
  /**
   * Indicates that the user has started processing the product. By "started
   * processing", we mean the user gave us some sort of signal that they intend
   * to start processing the product. In practice, this may simply mean the user
   * opened the page where they can process the product.
   *
   * This is useful for preventing the requestor from canceling or modifying the
   * order once a product has started to be processed.
   */
  startProcessingProduct: StartProcessingProductMutationResponse;
  /** Update a Demand Payee */
  updateDemandPayee: UpdateDemandPayeeResponse;
  updateFollowUpQnrQuestion: UpdateFollowUpQnrQuestionMutationResponse;
  /** Update a company's profile */
  updateMgmtCompany: UpdateMgmtCompanyResponse;
  updatePrimaryQnrQuestion: UpdatePrimaryQnrQuestionMutationResponse;
  /**
   * Update the questionnaire layout.
   *
   * In particular, this mutation will re-order qnr questions and subsections
   * according to the input.
   *
   * Note: this mutation will _not_ reorder sections. In other words, the order
   * of the sections in the input will be ignored.
   */
  updateQnrLayout: UpdateQnrLayoutMutationResponse;
  /** Update an announcement for requestors. */
  updateRequestorAnnouncement: UpdateRequestorAnnouncementResponse;
  /** Update the requestor's profile. The requestor's ID will be obtained from the auth token. */
  updateRequestorProfile: UpdateRequestorProfileResponse;
  /**
   * Upload a requestor's own lender-specific questionnaire template. We allow
   * requestors to upload a form in case we don't currently have the form the
   * lender needs.
   * Note: the file must be a PDF.
   *
   * TODO: delete me. Should become part of placeOrder mutation
   */
  uploadOwnLsqTemplate: File;
};

export type Mutation_addNewUserToRequestorOrgArgs = {
  profile: AddNewUserToRequestorOrgMutationInput;
};

export type Mutation_addRushArgs = {
  input: AddRushInput;
};

export type Mutation_attachDocumentToOrderArgs = {
  documentType: OrderAttachmentDocumentType;
  file: Scalars["Upload"]["input"];
  orderUUID: Scalars["String"]["input"];
};

export type Mutation_bulkSaveStructuralSafetyArgs = {
  input: BulkSaveStructuralSafetyInput;
};

export type Mutation_cancelOrderArgs = {
  comments: Scalars["String"]["input"];
  orderUUID: Scalars["String"]["input"];
  reason: CancellationReason;
};

export type Mutation_changePasswordArgs = {
  clientAppType?: InputMaybe<ClientAppType>;
  currentPassword: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
};

export type Mutation_copyHoaQnrDataArgs = {
  sourceHoaUuid: Scalars["ID"]["input"];
  targetHoaUuid: Scalars["ID"]["input"];
};

export type Mutation_copyHoaQnrExpirationSettingsToHoaGroupsArgs = {
  sourceHoaUuid: Scalars["ID"]["input"];
  targetHoaGroupUuids: Array<Scalars["ID"]["input"]>;
};

export type Mutation_copyHoaQnrExpirationSettingsToHoasArgs = {
  all: Scalars["Boolean"]["input"];
  sourceHoaUuid: Scalars["ID"]["input"];
  targetHoaUuids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type Mutation_copyHoaQnrQuickUpdateSettingsToHoaGroupsArgs = {
  sourceHoaUuid: Scalars["ID"]["input"];
  targetHoaGroupUuids: Array<Scalars["ID"]["input"]>;
};

export type Mutation_copyHoaQnrQuickUpdateSettingsToHoasArgs = {
  all: Scalars["Boolean"]["input"];
  sourceHoaUuid: Scalars["ID"]["input"];
  targetHoaUuids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type Mutation_createDemandPayeeArgs = {
  input: DemandPayeeInput;
};

export type Mutation_createFollowUpQnrQuestionArgs = {
  input: CreateFollowUpQnrQuestionInput;
};

export type Mutation_createPrimaryQnrQuestionArgs = {
  input: CreatePrimaryQnrQuestionInput;
};

export type Mutation_createRequestorAnnouncementArgs = {
  input: CreateRequestorAnnouncementInput;
};

export type Mutation_deleteDemandPayeeArgs = {
  input: DeleteDemandPayeeInput;
};

export type Mutation_deleteQnrQuestionArgs = {
  isFollowUpQuestion: Scalars["Boolean"]["input"];
  questionApiCode: Scalars["String"]["input"];
};

export type Mutation_deleteRemittanceBatchGroupArgs = {
  input: DeleteRemittanceBatchGroupInput;
};

export type Mutation_deleteRequestorAccountArgs = {
  accountUUID: Scalars["String"]["input"];
};

export type Mutation_deleteRequestorAnnouncementArgs = {
  input: DeleteRequestorAnnouncementInput;
};

export type Mutation_generateLightboxParamsArgs = {
  input: GenerateLightboxParamsInput;
};

export type Mutation_impersonateMgmtCompanyArgs = {
  input: ImpersonateMgmtCompanyInput;
};

export type Mutation_loginArgs = {
  clientAppType: ClientAppType;
  password: Scalars["String"]["input"];
  recaptchaToken?: InputMaybe<Scalars["String"]["input"]>;
  username: Scalars["String"]["input"];
};

export type Mutation_modifyBuyerArgs = {
  orderUUID: Scalars["String"]["input"];
  transactionDetails: ModifyBuyerTransactionDetailsInput;
};

export type Mutation_modifyOrderArgs = {
  orderUUID: Scalars["String"]["input"];
  transactionDetails: TransactionDetailsInput;
};

export type Mutation_payForOrderArgs = {
  input: PayForOrderInput;
};

export type Mutation_placeOrderArgs = {
  order: PlaceOrderInput;
};

export type Mutation_processRemittanceBatchRunArgs = {
  input: ProcessRemittanceBatchRunInput;
};

export type Mutation_releaseQnrQuestionArgs = {
  isFollowUpQuestion: Scalars["Boolean"]["input"];
  questionApiCode: Scalars["String"]["input"];
};

export type Mutation_reorderFollowUpQnrQuestionsArgs = {
  input: ReorderFollowUpQnrQuestionsInput;
};

export type Mutation_requestForgotUsernameEmailArgs = {
  clientAppType?: InputMaybe<ClientAppType>;
  email: Scalars["String"]["input"];
};

export type Mutation_requestOrderUpdateArgs = {
  input: RequestOrderUpdateInput;
};

export type Mutation_requestPasswordResetEmailArgs = {
  clientAppType?: InputMaybe<ClientAppType>;
  username: Scalars["String"]["input"];
};

export type Mutation_resetPasswordArgs = {
  clientAppType?: InputMaybe<ClientAppType>;
  newPassword: Scalars["String"]["input"];
  passwordResetToken: Scalars["String"]["input"];
};

export type Mutation_saveHoaQnrExpirationSettingsArgs = {
  hoaUUID: Scalars["ID"]["input"];
  questionsToModify: Array<SaveHoaQnrExpirationSettingsQuestionInput>;
};

export type Mutation_saveHoaQnrQuickUpdateSettingsArgs = {
  hoaUUID: Scalars["ID"]["input"];
  questionsToModify: Array<SaveHoaQnrQuickUpdateSettingsQuestionInput>;
};

export type Mutation_saveKeyMetricsFiltersArgs = {
  input: SaveKeyMetricsFiltersInput;
};

export type Mutation_saveMgmtQnrAcctgImportSettingsArgs = {
  input: SaveMgmtQnrAcctgImportSettingsInput;
};

export type Mutation_saveMgmtQnrDefaultContactSettingsArgs = {
  input: SaveMgmtQnrDefaultContactSettingsInput;
};

export type Mutation_saveMgmtQnrOptInQuestionSettingsArgs = {
  questionsToModify: Array<SaveMgmtQnrOptInSettingsQuestionInput>;
};

export type Mutation_saveMgmtRemittanceEmailContactsArgs = {
  input: SaveMgmtRemittanceEmailContactsInput;
};

export type Mutation_saveMgmtRemittanceVendorSettingsArgs = {
  input: SaveMgmtRemittanceVendorSettingsInput;
};

export type Mutation_saveProductPerfFiltersArgs = {
  input: SaveProductPerfFiltersInput;
};

export type Mutation_saveProductProcessingFiltersArgs = {
  input: SaveProductProcessingFiltersInput;
};

export type Mutation_saveQnrDataArgs = {
  input: SaveQnrDataInput;
};

export type Mutation_saveRemittanceBatchGroupArgs = {
  input: SaveRemittanceBatchGroupInput;
};

export type Mutation_saveRushPerfFiltersArgs = {
  input: SaveRushPerfFiltersInput;
};

export type Mutation_saveTrendsFiltersArgs = {
  input: SaveTrendsFiltersInput;
};

export type Mutation_signUpArgs = {
  input: SignUpMutationInput;
};

export type Mutation_startProcessingProductArgs = {
  orderProductUuid: Scalars["ID"]["input"];
};

export type Mutation_updateDemandPayeeArgs = {
  input: UpdateDemandPayeeInput;
};

export type Mutation_updateFollowUpQnrQuestionArgs = {
  input: UpdateFollowUpQnrQuestionInput;
};

export type Mutation_updateMgmtCompanyArgs = {
  input: UpdateMgmtCompanyInput;
};

export type Mutation_updatePrimaryQnrQuestionArgs = {
  input: UpdatePrimaryQnrQuestionInput;
};

export type Mutation_updateQnrLayoutArgs = {
  sections: Array<UpdateQnrLayoutSectionInput>;
};

export type Mutation_updateRequestorAnnouncementArgs = {
  input: UpdateRequestorAnnouncementInput;
};

export type Mutation_updateRequestorProfileArgs = {
  profile: UpdateRequestorProfileMutationInput;
};

export type Mutation_uploadOwnLsqTemplateArgs = {
  file: Scalars["Upload"]["input"];
  hoaUUID: Scalars["ID"]["input"];
};

export type MutationResponse = {
  error: Maybe<BaseMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type Order = {
  /**
   * The time when the order was acknowledged (if there was an acknowledgement).
   * Restricted to authenticated users to limit PII disclosure.
   */
  acknowledgedAt: Maybe<Scalars["String"]["output"]>;
  /**
   * The notice that the requestor acknowledged when they placed the order.
   * Restricted to authenticated users to limit PII disclosure.
   */
  acknowledgedNotice: Maybe<Scalars["String"]["output"]>;
  /**
   * Suppose the user didn't use rush processing when they placed the order, but
   * now they'd like to add rush processing to the order. Then, we should use
   * this resolver to figure out what rush options, if any, are available. If there
   * aren't any rush options, this resolver can be used to tell the user the reason.
   */
  addedRushOptionsInfo: AddedRushOptionsInfo;
  attachments: Array<OrderAttachment>;
  bundles: Array<OrderBundle>;
  buyer: Maybe<Buyer>;
  /**
   * If true, the buyer intends to move into the property. Only applies to resale
   * transactions. Restricted to authenticated users to limit PII disclosure.
   */
  buyerOccupied: Maybe<Scalars["Boolean"]["output"]>;
  confirmationEmailSentTo: Scalars["String"]["output"];
  confirmationNumber: Scalars["String"]["output"];
  currentOwner: CurrentOwner;
  /**
   * The estimated closing date of the real estate transaction. We don't always collect this field.
   * For example, we don't ask the requestor to enter it if the order doesn't contain any forms.
   *
   * Note: We do NOT need to convert this to HWD's timezone using a schema directive. It's an HWD
   * calendar date, so it's inherently in HWD's timezone already.
   */
  estimatedClosingDate: Maybe<Scalars["String"]["output"]>;
  hasRush: Scalars["Boolean"]["output"];
  hoa: HOA;
  individualItems: Array<OrderItem>;
  /**
   * The loan #. This only applies if the user ordered a questionnaire. Restricted to
   * authenticated users to limit PII disclosure.
   */
  loanNumber: Maybe<Scalars["String"]["output"]>;
  orderDate: Scalars["String"]["output"];
  paidAt: Maybe<Scalars["String"]["output"]>;
  paymentMethod: PaymentMethod;
  presaleDocumentsInfo: Maybe<Array<PresaleDocumentsGroup>>;
  /**
   * URL that can be used to view the order's receipt (which we often refer to as "order statement" since
   * it's technically not a receipt).
   * Unless the order is still awaiting payment, then this field should be non-null.
   */
  receiptUrl: Maybe<Scalars["String"]["output"]>;
  requestor: Requestor;
  resaleFormOptionValues: Maybe<Array<ResaleFormOptionValue>>;
  rushAddedAt: Maybe<Scalars["String"]["output"]>;
  shareWithEmails: Maybe<Array<Scalars["String"]["output"]>>;
  shippingInfo: Maybe<ShippingInfo>;
  subjectPropertyAddress: Scalars["String"]["output"];
  totalAtCloseFee: Scalars["Float"]["output"];
  totalPaid: Scalars["Float"]["output"];
  totalPrice: Scalars["Float"]["output"];
  /**
   * Total amount of money that was due at up front (USD).
   * Note: this doesn't necessarily mean the up front fees have been paid yet. For example, this
   * fee still has to be paid for order that are still awaiting payment (e.g. 3rd party pay)
   */
  totalUpFrontFee: Scalars["Float"]["output"];
  transactionType: TransactionType;
  upFrontPayStatus: UpFrontPayStatus;
  uuid: Scalars["String"]["output"];
};

/** Info about whether the requestor can perform a certain action on their order. */
export type OrderActionInfo = {
  __typename: "OrderActionInfo";
  isActionAvailable: Scalars["Boolean"]["output"];
  /**
   * If isActionAvailable is false, then this should provide a brief, user-friendly explanation of
   * why the action is not available.
   */
  reasonNotAvailable: Maybe<Scalars["String"]["output"]>;
};

export type OrderAttachment = {
  __typename: "OrderAttachment";
  id: Scalars["ID"]["output"];
  /**
   * The label to display for this file. Do NOT use the OrderAttachmentDocumentType enum
   * for this. This needs to just be a string, because the management company is allowed to
   * upload document attachments with an arbitrary string label.
   */
  label: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export enum OrderAttachmentDocumentType {
  GRANT_WARRANTY_DEED = "GRANT_WARRANTY_DEED",
  NEW_OWNER_FORMS = "NEW_OWNER_FORMS",
  OTHER = "OTHER",
  SALES_CONTRACT = "SALES_CONTRACT",
  SALES_PACKAGE = "SALES_PACKAGE",
  SELLER_AUTHORIZATION = "SELLER_AUTHORIZATION",
}

export type OrderBundle = {
  __typename: "OrderBundle";
  id: Scalars["ID"]["output"];
  items: Array<OrderItem>;
  label: Scalars["String"]["output"];
  /** If true, this item was ordered for a master HOA. */
  orderedForMasterHoa: Scalars["Boolean"]["output"];
};

export enum OrderCompletionStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  NOT_PAID = "NOT_PAID",
  PENDING = "PENDING",
}

export type OrderCountBreakdown = {
  __typename: "OrderCountBreakdown";
  numCanceledOrders: Scalars["Int"]["output"];
  numNewOrders: Scalars["Int"]["output"];
};

export type OrderCountDataPoint = {
  __typename: "OrderCountDataPoint";
  breakdown: OrderCountBreakdown;
  date: Scalars["String"]["output"];
};

export type OrderCountDataPoint_dateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export enum OrderDateRange {
  ANY_DATE = "ANY_DATE",
  CURRENT_YEAR = "CURRENT_YEAR",
  FOUR_OR_MORE_YEARS_AGO = "FOUR_OR_MORE_YEARS_AGO",
  LAST_3_MONTHS = "LAST_3_MONTHS",
  LAST_6_MONTHS = "LAST_6_MONTHS",
  LAST_YEAR = "LAST_YEAR",
  THREE_YEARS_AGO = "THREE_YEARS_AGO",
  TWO_YEARS_AGO = "TWO_YEARS_AGO",
}

export type OrderItem = {
  __typename: "OrderItem";
  /**
   * If true, the item can be downloaded. This should always be true for completed orders.
   * For pending orders, this will only be true for the items that have been completed
   * so far...and even then it will only be true if the HOA has the partial release setting configured.
   */
  canDownload: Scalars["Boolean"]["output"];
  category: ProductCategoryType;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  /** If true, this item was ordered for a master HOA. */
  orderedForMasterHoa: Scalars["Boolean"]["output"];
  /**
   * Most recent date when an "mgmt update" occurred, or null.
   *
   * Here, an "mgmt update" refers to when the mgmt company had already released
   * the product, but then they amended it after that.
   *
   * Ideally, we'd rename this attr to 'mgmtUpdatedAt'. After all, it's possible
   * for the product to not get re-released immediately when an "mgmt update"
   * occurs. However, it wouldn't be worth the effort to deprecate this field and
   * add a new one.
   */
  reReleasedAt: Maybe<Scalars["String"]["output"]>;
  /**
   * URL for viewing the product. This field is nullable because the user can't download
   * the product during certain states (e.g. when the order hasn't been paid yet)
   */
  url: Maybe<Scalars["String"]["output"]>;
};

export type OrderItem_reReleasedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrderLookupError = {
  __typename: "OrderLookupError";
  errorCode: OrderLookupErrorCode;
};

export enum OrderLookupErrorCode {
  ORDER_ACCESS_EXPIRED = "ORDER_ACCESS_EXPIRED",
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
}

export type OrderLookupResult = OrderLookupError | OrderLookupSuccess;

export type OrderLookupSuccess = {
  __typename: "OrderLookupSuccess";
  order: Order;
};

export type OrderPricingSummary = {
  __typename: "OrderPricingSummary";
  atClose: PricingSummaryBreakdown;
  refundPolicyInfo: RefundPolicyInfo;
  /**
   * If we're charging tax on this order, this is the human-friendly name of
   * the tax, as it should appear during the order process, on receipts, and in
   * reports.
   * If no tax, this should be null.
   */
  taxDisplayName: Maybe<Scalars["String"]["output"]>;
  total: PricingSummaryBreakdown;
  upFront: PricingSummaryBreakdown;
};

export type OrderPricingSummaryError = {
  __typename: "OrderPricingSummaryError";
  code: OrderPricingSummaryErrorCode;
  message: Scalars["String"]["output"];
};

export enum OrderPricingSummaryErrorCode {
  HOA_NOT_ACTIVE = "HOA_NOT_ACTIVE",
  HOA_NOT_FOUND = "HOA_NOT_FOUND",
  MGMT_SETTINGS_CHANGED = "MGMT_SETTINGS_CHANGED",
  UNNECESSARY_RUSH = "UNNECESSARY_RUSH",
  UNRECOGNIZED_RUSH_OPTION = "UNRECOGNIZED_RUSH_OPTION",
}

/**
 * Note: In some ways, this type is a simplified version of 'PlaceOrderInput'.
 * But some fields on this type are nullable in case the resolver is fired at
 * a point in the order process when the requestor hasn't entered the info yet.
 */
export type OrderPricingSummaryInput = {
  bundleIds: Array<Scalars["ID"]["input"]>;
  developerId?: InputMaybe<Scalars["ID"]["input"]>;
  hoaUUID: Scalars["String"]["input"];
  individualItemIds: Array<Scalars["ID"]["input"]>;
  lsqInfo?: InputMaybe<LsqOrderInfoInput>;
  masterHoasCartInput?: InputMaybe<Array<MasterHoaCartInput>>;
  /**
   * TODO: rename to payUpFrontOverride for clarity
   * https://github.com/homewisedocs/requestor-app/issues/2148
   */
  payUpFront?: InputMaybe<Scalars["Boolean"]["input"]>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  rushId?: InputMaybe<Scalars["ID"]["input"]>;
  shippingMethod?: InputMaybe<ShippingMethod>;
  transactionType: TransactionType;
};

export type OrderPricingSummaryResult =
  | OrderPricingSummary
  | OrderPricingSummaryError;

export type OrderProduct = {
  mgmtCompletedAt: Maybe<Scalars["String"]["output"]>;
  order: Order;
  releasedAt: Maybe<Scalars["String"]["output"]>;
  startedProcessingAt: Maybe<Scalars["String"]["output"]>;
  uuid: Scalars["ID"]["output"];
};

export type OrderProducts = {
  __typename: "OrderProducts";
  qnr: QnrOrderProductResult;
};

export type OrderProducts_qnrArgs = {
  qnrType: QnrType;
  uuid: Scalars["ID"]["input"];
};

export type OrderUpdateInfoUnion = CantUpdateReason | InfoForUpdateRequest;

export type OrdersInput = {
  dateRange: OrderDateRange;
  /**
   * 0-based index of the page to retrieve. This is less flexible than using limit and offset, but we'd
   * rather not allow the client to query for an arbitrary number of orders all at once.
   */
  page: Scalars["Int"]["input"];
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
};

export type PayForOrderInput = {
  orderUUID: Scalars["String"]["input"];
  paymentInfo: PaymentInfo;
};

export type PayForOrderMutationData = {
  __typename: "PayForOrderMutationData";
  orderUUID: Scalars["String"]["output"];
};

export type PayForOrderMutationResponse = MutationResponse & {
  __typename: "PayForOrderMutationResponse";
  data: Maybe<PayForOrderMutationData>;
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type PayForOrderPricingSummary = {
  __typename: "PayForOrderPricingSummary";
  /**
   * The amount of the credit card fee if the payment method is a credit card.
   * For other payment methods, this will be null.
   */
  creditCardFee: Maybe<Price>;
  order: PricingSummaryBreakdown;
  refundPolicyInfo: RefundPolicyInfo;
  /**
   * If we're charging tax on this order, this is a human-friendly name of the tax.
   * If no tax, this should be null.
   */
  taxDisplayName: Maybe<Scalars["String"]["output"]>;
  total: PricingSummaryBreakdown;
};

export type PayForOrderPricingSummaryError = {
  __typename: "PayForOrderPricingSummaryError";
  code: PayForOrderPricingSummaryErrorCode;
  message: Scalars["String"]["output"];
};

export enum PayForOrderPricingSummaryErrorCode {
  NO_UP_FRONT_PAYMENT_REQUIRED = "NO_UP_FRONT_PAYMENT_REQUIRED",
  ORDER_ALREADY_PAID = "ORDER_ALREADY_PAID",
  ORDER_CANCELED = "ORDER_CANCELED",
  ORDER_COMPLETED = "ORDER_COMPLETED",
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type PayForOrderPricingSummaryResult =
  | PayForOrderPricingSummary
  | PayForOrderPricingSummaryError;

export enum PaymentDueType {
  ATC = "ATC",
  UPF = "UPF",
}

export type PaymentInfo = {
  creditCardInfo?: InputMaybe<CreditCardInfo>;
  eCheckInfo?: InputMaybe<ECheckInfo>;
  paymentMethod: PaymentMethod;
};

export enum PaymentMethod {
  ACH = "ACH",
  CREDIT_CARD = "CREDIT_CARD",
  /**
   * This is only used for a special arrangment with CoreLogic where we periodically invoice them.
   * You should not need to use this value for typical requestors.
   */
  INVOICED = "INVOICED",
  NO_UPFRONT_PAYMENT_REQUIRED = "NO_UPFRONT_PAYMENT_REQUIRED",
  PAY_BY_CHECK = "PAY_BY_CHECK",
  THIRD_PARTY_PAY = "THIRD_PARTY_PAY",
}

export type PendingOrder = Order & {
  __typename: "PendingOrder";
  /**
   * The time when the order was acknowledged (if there was an acknowledgement).
   * Restricted to authenticated users to limit PII disclosure.
   */
  acknowledgedAt: Maybe<Scalars["String"]["output"]>;
  /**
   * The notice that the requestor acknowledged when they placed the order.
   * Restricted to authenticated users to limit PII disclosure.
   */
  acknowledgedNotice: Maybe<Scalars["String"]["output"]>;
  /**
   * Info about whether the requestor can possibly add a rush to the order (assuming an added rush option is available).
   * Note: Even if this field indicates that the addRush action can be performed, that does NOT necessarily mean that there
   * are any available rush options.
   */
  addRushActionInfo: OrderActionInfo;
  /**
   * Suppose the user didn't use rush processing when they placed the order, but
   * now they'd like to add rush processing to the order. Then, we should use
   * this resolver to figure out what rush options, if any, are available. If there
   * aren't any rush options, this resolver can be used to tell the user the reason.
   */
  addedRushOptionsInfo: AddedRushOptionsInfo;
  attachments: Array<OrderAttachment>;
  bundles: Array<OrderBundle>;
  buyer: Maybe<Buyer>;
  /**
   * If true, the buyer intends to move into the property. Only applies to resale
   * transactions. Restricted to authenticated users to limit PII disclosure.
   */
  buyerOccupied: Maybe<Scalars["Boolean"]["output"]>;
  /** Info about whether the requestor can cancel the order. */
  cancelOrderActionInfo: OrderActionInfo;
  confirmationEmailSentTo: Scalars["String"]["output"];
  confirmationNumber: Scalars["String"]["output"];
  currentOwner: CurrentOwner;
  /**
   * This url allows users to download all ordered products that have been released so far.
   * Note: This will be null unless the pending order is partially released.
   */
  downloadAllReleasedUrl: Maybe<Scalars["String"]["output"]>;
  /**
   * Note: We do NOT need to convert this to HWD's timezone using a schema directive. It's an HWD
   * calendar date, so it's inherently in HWD's timezone already.
   */
  dueDate: Scalars["String"]["output"];
  /**
   * The estimated closing date of the real estate transaction. We don't always collect this field.
   * For example, we don't ask the requestor to enter it if the order doesn't contain any forms.
   *
   * Note: We do NOT need to convert this to HWD's timezone using a schema directive. It's an HWD
   * calendar date, so it's inherently in HWD's timezone already.
   */
  estimatedClosingDate: Maybe<Scalars["String"]["output"]>;
  hasRush: Scalars["Boolean"]["output"];
  hoa: HOA;
  individualItems: Array<OrderItem>;
  /**
   * If true, the 'dueDate' field is reliable.
   *
   * Otherwise, the client should be cautious about using 'dueDate' and fields
   * that are based on it, such as 'processingStatus'. For example, some
   * companies process certain products based on the closing date instead of the
   * nominal due date. In other words, they often intentionally complete orders
   * after the due date. As a result, displaying a message to requestors based on
   * 'processingStatus' could be confusing and misleading.
   */
  isDueDateReliable: Scalars["Boolean"]["output"];
  /**
   * The loan #. This only applies if the user ordered a questionnaire. Restricted to
   * authenticated users to limit PII disclosure.
   */
  loanNumber: Maybe<Scalars["String"]["output"]>;
  /**
   * Info about whether the requestor can modify information originally given in the Buyer Information and
   * Transaction Information sections on the Transaction Details page of the order process.
   *
   * Note: modifyBuyer and modifyOrder will never be available at the same time.
   */
  modifyBuyerActionInfo: OrderActionInfo;
  /** Info about whether the requestor can modify the order. */
  modifyOrderActionInfo: OrderActionInfo;
  orderDate: Scalars["String"]["output"];
  paidAt: Maybe<Scalars["String"]["output"]>;
  /**
   * If true, the order has been "partially released". Even though the entire order hasn't been completed,
   * we still want to allow the requestor to view/download the parts that have been completed so far.
   */
  partiallyReleased: Scalars["Boolean"]["output"];
  paymentMethod: PaymentMethod;
  presaleDocumentsInfo: Maybe<Array<PresaleDocumentsGroup>>;
  processingStatus: PendingOrderProcessingStatus;
  /**
   * URL that can be used to view the order's receipt (which we often refer to as "order statement" since
   * it's technically not a receipt).
   * Unless the order is still awaiting payment, then this field should be non-null.
   */
  receiptUrl: Maybe<Scalars["String"]["output"]>;
  refundPolicyInfo: PendingOrderRefundPolicyInfo;
  requestor: Requestor;
  resaleFormOptionValues: Maybe<Array<ResaleFormOptionValue>>;
  rushAddedAt: Maybe<Scalars["String"]["output"]>;
  shareWithEmails: Maybe<Array<Scalars["String"]["output"]>>;
  shippingInfo: Maybe<ShippingInfo>;
  subjectPropertyAddress: Scalars["String"]["output"];
  totalAtCloseFee: Scalars["Float"]["output"];
  totalPaid: Scalars["Float"]["output"];
  totalPrice: Scalars["Float"]["output"];
  /**
   * Total amount of money that was due at up front (USD).
   * Note: this doesn't necessarily mean the up front fees have been paid yet. For example, this
   * fee still has to be paid for order that are still awaiting payment (e.g. 3rd party pay)
   */
  totalUpFrontFee: Scalars["Float"]["output"];
  transactionType: TransactionType;
  upFrontPayStatus: UpFrontPayStatus;
  uuid: Scalars["String"]["output"];
};

export type PendingOrder_acknowledgedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type PendingOrder_dueDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type PendingOrder_estimatedClosingDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type PendingOrder_modifyBuyerActionInfoArgs = {
  perfSensitiveEnforceUpdateRequestLogic?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

export type PendingOrder_orderDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type PendingOrder_paidAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type PendingOrder_rushAddedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export enum PendingOrderProcessingStatus {
  /** The order is due on some future date. */
  DUE_IN_FUTURE = "DUE_IN_FUTURE",
  /**
   * The order is due today and HomeWiseDocs needs to finish processing it. In
   * practice, this means the mgmt company has finished processing an LSQ order
   * and that the HWD Forms department still needs to finish processing the LSQ.
   */
  DUE_TODAY_AWAITING_HWD = "DUE_TODAY_AWAITING_HWD",
  /** The order is due today and the mgmt company needs to finish processing it. */
  DUE_TODAY_AWAITING_MGMT = "DUE_TODAY_AWAITING_MGMT",
  /** Similar to 'DUE_TODAY_AWAITING_HWD', except the order is past due. */
  PAST_DUE_AWAITING_HWD = "PAST_DUE_AWAITING_HWD",
  /** The order is past due and the mgmt company needs to finish processing it. */
  PAST_DUE_AWAITING_MGMT = "PAST_DUE_AWAITING_MGMT",
}

export type PendingOrderRefundPolicyInfo = {
  __typename: "PendingOrderRefundPolicyInfo";
  cancellationRefundPolicyMessage: Maybe<Scalars["NonEmptyString"]["output"]>;
};

export type PendingOrders = {
  __typename: "PendingOrders";
  orders: Array<PendingOrder>;
  totalNumOrders: Scalars["Int"]["output"];
};

export type PhoneInfo = {
  __typename: "PhoneInfo";
  extension: Maybe<Scalars["String"]["output"]>;
  phoneNumber: Scalars["String"]["output"];
};

export enum PlaceOrderErrorCode {
  DOC_ATTACHMENT_ERROR = "DOC_ATTACHMENT_ERROR",
  HOA_NOT_ACTIVE = "HOA_NOT_ACTIVE",
  HOA_NOT_FOUND = "HOA_NOT_FOUND",
  INVALID_ESTIMATED_CLOSING_DATE = "INVALID_ESTIMATED_CLOSING_DATE",
  INVALID_FORM_DATA = "INVALID_FORM_DATA",
  MGMT_SETTINGS_CHANGED = "MGMT_SETTINGS_CHANGED",
  PAYMENT_ERROR = "PAYMENT_ERROR",
  UNNECESSARY_RUSH = "UNNECESSARY_RUSH",
  UNRECOGNIZED_RUSH_OPTION = "UNRECOGNIZED_RUSH_OPTION",
}

export type PlaceOrderInput = {
  bundleIds: Array<Scalars["ID"]["input"]>;
  currentOwnerAddressVerificationStatus: CurrentOwnerAddressVerificationStatus;
  developerId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * If the requestor uploaded an attachment to their order during the order process, this field
   * should describe the attachment.
   */
  documentAttachmentInfo?: InputMaybe<DocumentAttachmentInfo>;
  /**
   * Estimated closing date. This field only applies if the order requires processing.
   * If an order doesn't require processing, we don't need to ask for the estimated closing date.
   */
  estimatedClosingDate?: InputMaybe<CalendarDateInput>;
  hoaUUID: Scalars["String"]["input"];
  individualItemIds: Array<Scalars["ID"]["input"]>;
  lsqInfo?: InputMaybe<LsqOrderInfoInput>;
  /**
   * If the HOA for this order is a sub association within 1 or more master associations,
   * then this input field represents an array of the cart info for each master association.
   */
  masterHoasCartInput?: InputMaybe<Array<MasterHoaCartInput>>;
  mobilePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * If true, the requestor has elected to pay for the order up front. In particular, anything in the order
   * that is normally due at close will instead be paid for now.
   * TODO: rename to payUpFrontOverride for clarity
   * https://github.com/homewisedocs/requestor-app/issues/2148
   */
  payNow?: InputMaybe<Scalars["Boolean"]["input"]>;
  paymentInfo: PaymentInfo;
  rushId?: InputMaybe<Scalars["ID"]["input"]>;
  shareWithEmails?: InputMaybe<Array<Scalars["String"]["input"]>>;
  shippingInfo?: InputMaybe<ShippingInfoInput>;
  transactionDetails: TransactionDetailsInput;
  transactionType: TransactionType;
};

export type PlaceOrderMutationError = BaseMutationError & {
  __typename: "PlaceOrderMutationError";
  code: PlaceOrderErrorCode;
  message: Scalars["String"]["output"];
};

export type PlaceOrderMutationResponse = MutationResponse & {
  __typename: "PlaceOrderMutationResponse";
  error: Maybe<PlaceOrderMutationError>;
  order: Maybe<PlacedOrderInfo>;
  success: Scalars["Boolean"]["output"];
};

export type PlacedOrderInfo = {
  __typename: "PlacedOrderInfo";
  /**
   * The order's completion status. Even though the order has just been placed,
   * the completion status may vary. It might be pending, completed, or awaiting payment
   * depending on the order.
   */
  completionStatus: OrderCompletionStatus;
  confirmationEmailSentTo: Scalars["String"]["output"];
  confirmationNumber: Scalars["String"]["output"];
  dueDate: Maybe<Scalars["String"]["output"]>;
  hoaUUID: Scalars["String"]["output"];
  presaleDocumentsInfo: Maybe<Array<PresaleDocumentsGroup>>;
  totalAmount: Scalars["Float"]["output"];
  totalDueAtClose: Scalars["Float"]["output"];
  totalPaid: Scalars["Float"]["output"];
  uuid: Scalars["String"]["output"];
};

export type PlacedOrderInfo_dueDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type PlainTextQnrQuestionResponse = {
  __typename: "PlainTextQnrQuestionResponse";
  value: Maybe<Scalars["String"]["output"]>;
};

/**
 * An error that occurred while running the "post-save action". This flag helps
 * the client determine whether it needs to refresh the data.
 *
 * As an example, consider the following scenario:
 * 1) Mgmt User A goes to edit some HOA's qnr data
 * 2) Mgmt User B goes to process a qnr order for the same HOA
 * 3) User A clears out a required response and saves
 * 4) User B makes a couple changes and then tries to release the qnr
 *
 * User B will get an error with a 'code' of 'QNR_IS_MISSING_REQUIRED_DATA'
 * since User A had just cleared out a required response. However, we'll still
 * save the changes made by User B--we just won't release the qnr.
 *
 * In this scenario, we'll return an error of type 'PostSaveQnrDataError' so the
 * client will know it needs to refresh, e.g. to get the updated expiration info
 * due to the save that just occurred. Plus, once the client refreshes, it'll
 * "know" about the required response that User A cleared out. So the client
 * will be able to mark that error clearly for User B.
 *
 * Note: For other types of errors (i.e. "pre-save errors"), the client probably
 * shouldn't refresh its data. For example, suppose the user tried to save but
 * received an error of type 'BasicSaveQnrDataError' with a 'code' of
 * 'CONTACT_NOT_FOUND'. If the client refreshed, it might throw away other
 * unsaved changes made by the user. Instead, the client should give the user an
 * opportunity to selectna different contact and try saving their changes again.
 */
export type PostSaveQnrDataError = BaseMutationError & {
  __typename: "PostSaveQnrDataError";
  code: PostSaveQnrDataErrorCode;
  message: Scalars["String"]["output"];
};

export enum PostSaveQnrDataErrorCode {
  DEFERRED_RELEASE_NOT_ALLOWED_ALREADY_RELEASED = "DEFERRED_RELEASE_NOT_ALLOWED_ALREADY_RELEASED",
  DEFERRED_RELEASE_NOT_ALLOWED_LSQ = "DEFERRED_RELEASE_NOT_ALLOWED_LSQ",
  DEFERRED_RELEASE_NOT_ALLOWED_MGMT_SETTINGS = "DEFERRED_RELEASE_NOT_ALLOWED_MGMT_SETTINGS",
  DEFERRED_RELEASE_NOT_ALLOWED_TOO_LATE = "DEFERRED_RELEASE_NOT_ALLOWED_TOO_LATE",
  ORDER_CANCELED = "ORDER_CANCELED",
  POST_SAVE_ACTION_NONE_NOT_ALLOWED = "POST_SAVE_ACTION_NONE_NOT_ALLOWED",
  QNR_HAS_EXPIRED_DATA = "QNR_HAS_EXPIRED_DATA",
  QNR_IS_MISSING_REQUIRED_DATA = "QNR_IS_MISSING_REQUIRED_DATA",
}

export enum PostalServiceMatchType {
  CONFIRMED = "CONFIRMED",
  /**
   * In CONFIRMED_BY_DROPPING_UNIT matches, the address refers to a real
   * location, but the unit information is incorrect (e.g. the address refers
   * to an apartment building, but the apartment number given doesn't exist)
   */
  CONFIRMED_BY_DROPPING_UNIT = "CONFIRMED_BY_DROPPING_UNIT",
  /**
   * In MISSING_UNIT matches, the address refers to a real
   * location, but is missing necessary unit information (e.g. the address
   * refers to an apartment building, but no apartment number was given)
   */
  MISSING_UNIT = "MISSING_UNIT",
}

/** A presale document that the mgmt company would like to display to requestors when they place an order */
export type PresaleDocument = {
  __typename: "PresaleDocument";
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

/**
 * A group of associated presale documents. They should be displayed together when the requestor
 * places an order.
 */
export type PresaleDocumentsGroup = {
  __typename: "PresaleDocumentsGroup";
  documents: Array<PresaleDocument>;
  mgmtCompanyComment: Maybe<Scalars["String"]["output"]>;
};

export type Price = {
  __typename: "Price";
  amount: Scalars["Float"]["output"];
  /**
   * The formatted dollar amount, e.g. "$75.00", "$20.15", etc.
   * TODO: If necessary, add query variables similar to 'formatUSD' util
   * function's options: 'includeDollarSymbol', 'roundToNearestDollar',
   * and 'displayFractionDigitsForInteger'
   */
  formatted: Scalars["String"]["output"];
};

export type PricingSummaryBreakdown = {
  __typename: "PricingSummaryBreakdown";
  afterTax: Price;
  beforeTax: Price;
  tax: Price;
};

export type ProcessRemittanceBatchRunInput = {
  /** The UUID of the batch group to process remittances for. */
  batchGroupUuid: Scalars["UUID"]["input"];
  /** The name of the Remittance Batch. */
  batchName: Scalars["String"]["input"];
  /** The start date when searching for transactions to include in the remittance run. */
  fromDate: CalendarDateInput;
  /**
   * List of adjustments to be applied to the remittance (after adding gross
   * amounts and removing cancellations). Every management company UUID must
   * belong to a member of the batch group that this remittance run is being
   * processed for.
   */
  mgmtCompanyRemittanceAdjustments: Array<MgmtCompanyRemittanceAdjustment>;
  /**
   * Whether to notify management company remittance contacts that this remittance
   * batch run has been processed.
   */
  sendEmailNotificationsToMgmtCompanies: Scalars["Boolean"]["input"];
  /** The end date when searching for transactions to include in the remittance run. */
  toDate: CalendarDateInput;
};

export type ProcessRemittanceBatchRunMutationResponse = MutationResponse & {
  __typename: "ProcessRemittanceBatchRunMutationResponse";
  error: Maybe<GenericMutationError>;
  /**
   * Information related to the Remittance Batch that was processed that will be used
   * to build the remittance run summary report/spreadsheet and Epicor TXT file.
   * Returns null when the process does not succeed.
   */
  remittanceBatch: Maybe<RemittanceBatch>;
  success: Scalars["Boolean"]["output"];
};

export enum ProcessingDaysType {
  BUSINESS_DAYS_ONLY = "BUSINESS_DAYS_ONLY",
  CALENDAR_DAYS = "CALENDAR_DAYS",
}

export type ProcessingOptions = {
  __typename: "ProcessingOptions";
  /**
   * Adding a rush after the order has been placed may result in an extra fee.
   * This field represents the extra fee that we'd expect to be applied in that
   * scenario. Technically, the fee may change if the mgmt company changes their
   * settings after the requestor places the order yet before the requestor
   * tries to add a rush (hence the term "expected").
   * This field will be null if the requiresProcessing flag is false.
   */
  expectedExtraFeeForAddedRush: Maybe<Scalars["Float"]["output"]>;
  /**
   * If true, the order requires processing. In this case, the client should display the processing options to the requestor.
   * If false, the order doesn't require processing, so the client doesn't need to display the processing options at all.
   * Note that the rushOptions will be null if this flag is false.
   */
  requiresProcessing: Scalars["Boolean"]["output"];
  rushOptions: Maybe<Array<RushOption>>;
  standard: StandardProcessingInfo;
};

export enum ProductCategoryType {
  /** An appraiser questionnaire */
  APPRAISAL = "APPRAISAL",
  COMPLIANCE = "COMPLIANCE",
  DEMAND = "DEMAND",
  DOCUMENT = "DOCUMENT",
  /** An insurance questionnaire */
  INSURANCE_QUESTIONNAIRE = "INSURANCE_QUESTIONNAIRE",
  /**
   * A lender questionnaire. The current name of this enum member is a misnomer, since it does not
   * include appraiser questionnaires. However, it is quite difficult to update the name of the enum
   * member without breaking old clients.
   *
   * TODO: figure out a way to change QUESTIONNAIRE and APPRAISAL enum member names.
   * See related GH issue for more: https://github.com/homewisedocs/requestor-app/issues/1315
   */
  QUESTIONNAIRE = "QUESTIONNAIRE",
  REPORT = "REPORT",
  RESALE_DISCLOSURE = "RESALE_DISCLOSURE",
  TRID = "TRID",
}

export type ProductInterface = {
  hwdFee: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  mgmtCompanyFee: Scalars["Float"]["output"];
  paymentDue: PaymentDueType;
  popupMessage: Maybe<Scalars["String"]["output"]>;
  price: Scalars["Float"]["output"];
  /**
   * If non-null, display the HomeWiseDocs fee separately from the manager's fee.
   * Furthermore, use this value as the label for the HomeWiseDocs fee.
   *
   * If null, don't display the HomeWiseDocs fee separately.
   *
   * Some states have legislation that caps how much an mgmt company can charge
   * for certain products. If we merely displayed the sum of the HomeWise and
   * mgmt fees, then it may look as though the mgmt company is charging more
   * than the legislation permits.
   *
   * That's why we sometimes need to display the HomeWise fee separately. Doing
   * so allows requestors to see exactly how much the mgmt company charges. That
   * way, requestors will see that the mgmt company's pricing _does_ comply with
   * legislation. The total price may still technically exceed the cap, but it's
   * acceptable because HomeWise is treated as a separate fee.
   */
  separateHwdFeeLabel: Maybe<Scalars["String"]["output"]>;
};

/**
 * Bundles and individual products.
 * For convenience, we also access the acknowledgement message here. That message requires us to know
 * the available bundles and items. In the future, we could use DataLoaders for the notices, bundles, and items
 * so that the acknowledgement wouldn't have to be nested in here.
 */
export type Products = {
  __typename: "Products";
  bundles: Array<Bundle>;
  individualItems: Array<IndividualItem>;
  /**
   * Acknowledgement message to display to the requestor if a resale demand is selected. In other words, this should only be
   * displayed when a requestor selects a resale transaction type and then puts a demand (or a bundle that contains a
   * demand) into their cart. This setting is only configured by some management companies.
   * Furthermore, a non-null message will only be returned if the HOA is located in a state where the company specified to display it.
   */
  resaleDemandAcknowledgement: Maybe<Scalars["String"]["output"]>;
};

/**
 * Info about the responses that should be saved in the 'saveQnrData' mutation.
 *
 * Note that follow-up questions should be included here too. For example, if a
 * a plain text follow-up question needs saving, it should be included in
 * 'textLikeQuestions.plainText'.
 *
 * For 'textLikeQuestions', 'dropdownQuestions', and 'multiSelectQuestions', you
 * should only include questions that need saving.
 *
 * If the user marks a question's response as unchanged, you should include the
 * question in the input with 'dirty' set to false. That way, the response's
 * expiration date can be updated. That said, you could also set 'dirty' to true
 * and pass the unchanged value. After all, you could pass that type of input if
 * the user edited a value and/or comment and then reverted their edit(s) before
 * saving (in which case the expiration date should be pushed forward).
 *
 * Note: If a primary dropdown question changes and causes follow-up questions to
 * be removed, you don't need to pass those inapplicable follow-up questions. The
 * server will automatically clear out any previously-saved responses for those
 * follow-up questions. That way, if the manager reverts the primary question's
 * response at some point in the future, the old/stale follow-up responses won't
 * reappear.
 */
export type ProposedResponsesSaveQnrDataInput = {
  commercialUse?: InputMaybe<CommercialUseSaveQnrDataInput>;
  dropdownQuestions: Array<DropdownQuestionSaveDataInput>;
  generalComments: StringFieldResponseInput;
  multiSelectQuestions: Array<MultiSelectQuestionSaveDataInput>;
  multiUnitOwners?: InputMaybe<MultiUnitOwnersSaveQnrDataInput>;
  textLikeQuestions: TextLikeQuestionsSaveDataInput;
};

export type QnrCommercialUse = {
  __typename: "QnrCommercialUse";
  squareFeet: Scalars["Float"]["output"];
  units: Scalars["Int"]["output"];
  useType: CommercialUseType;
  /**
   * If the user selects 'OTHER' as the type of commercial use, we should
   * require them to specify the type of use as a write-in.
   *
   * This field represents that write-in value. OTOH, if the user did _not_
   * select 'OTHER', then this should be null.
   */
  writeInUseType: Maybe<Scalars["String"]["output"]>;
};

export type QnrCommercialUsesWithResponse = {
  __typename: "QnrCommercialUsesWithResponse";
  apiCode: Scalars["String"]["output"];
  comment: Maybe<Scalars["String"]["output"]>;
  core: CoreFollowUpQuestionInfoForResponse;
  expirationInfo: Maybe<QnrResponseExpirationInfo>;
  uses: Array<QnrCommercialUse>;
};

export type QnrContactSetting =
  | CompanyEmployee
  | QnrGenericCompanyContactSetting;

export type QnrContactSettings = {
  __typename: "QnrContactSettings";
  primary: QnrContactSetting;
  signature: QnrContactSetting;
};

export type QnrGenericCompanyContactSetting = {
  __typename: "QnrGenericCompanyContactSetting";
  genericCompanyText: Scalars["String"]["output"];
};

/**
 * Note: the "% of Total Units" column should be computed based on the
 * 'numUnitsPerOwner' column and the "Total Units" question, which is not part
 * of the table.
 */
export type QnrMultiUnitOwnersBucket = {
  __typename: "QnrMultiUnitOwnersBucket";
  numOwners: Scalars["Int"]["output"];
  numUnitsPerOwner: Scalars["Int"]["output"];
};

export type QnrMultiUnitOwnersWithResponse = {
  __typename: "QnrMultiUnitOwnersWithResponse";
  apiCode: Scalars["String"]["output"];
  buckets: Array<QnrMultiUnitOwnersBucket>;
  comment: Maybe<Scalars["String"]["output"]>;
  core: CoreFollowUpQuestionInfoForResponse;
  expirationInfo: Maybe<QnrResponseExpirationInfo>;
};

export type QnrOptInQuestionWithOptInSettings = {
  __typename: "QnrOptInQuestionWithOptInSettings";
  apiCode: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  optedIn: Scalars["Boolean"]["output"];
  templates: Array<QnrTemplate>;
};

export type QnrOrderProduct = OrderProduct & {
  __typename: "QnrOrderProduct";
  /**
   * The HOA's qnr data. In principle, you could also access this data from the
   * 'order.hoa.questionnaires.data' field. However, this field is more convenient
   * b/c you don't need to specify a 'qnrType' variable. Plus, this field isn't
   * so deeply nested.
   */
  hoaQnrData: HoaQnrDataResult;
  isLsq: Scalars["Boolean"]["output"];
  mgmtCompletedAt: Maybe<Scalars["String"]["output"]>;
  order: Order;
  releasedAt: Maybe<Scalars["String"]["output"]>;
  startedProcessingAt: Maybe<Scalars["String"]["output"]>;
  status: QnrOrderProductStatus;
  uuid: Scalars["ID"]["output"];
};

export type QnrOrderProduct_mgmtCompletedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type QnrOrderProduct_releasedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type QnrOrderProduct_startedProcessingAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type QnrOrderProductError = {
  __typename: "QnrOrderProductError";
  code: QnrOrderProductErrorCode;
  message: Scalars["String"]["output"];
};

export enum QnrOrderProductErrorCode {
  NOT_ASSIGNED_TO_QNR_FOR_HOA = "NOT_ASSIGNED_TO_QNR_FOR_HOA",
  NOT_AUTHORIZED_TO_PROCESS_QNR = "NOT_AUTHORIZED_TO_PROCESS_QNR",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  USER_NOT_FOUND = "USER_NOT_FOUND",
}

export enum QnrOrderProductPostSaveAction {
  NONE = "NONE",
  PLACE_QNR_ON_HOLD = "PLACE_QNR_ON_HOLD",
  /**
   * After saving, release the questionnaire product (if applicable).
   *
   * In the case of an LSQ, this means the LSQ will be sent to HWD Forms.
   *
   * Otherwise, this means the product will be marked as completed and possibly
   * released to the requestor (depending on the contents of the order and the
   * mgmt company's settings).
   */
  RELEASE_QNR = "RELEASE_QNR",
  /**
   * After saving, schedule the Questionnaire product to be released on the due date.
   *
   * Note: If the order is due today (or in the past), then we do _not_ allow
   * Deferred Release since there would be no point. Plus, our daily Deferred
   * Release cron job might've already ran.
   *
   * Also note that we do _not_ support Deferred Release for LSQs. LSQs should be
   * sent to the HWD Forms dept right after the mgmt company completes the std
   * qnr. Besides, our Forms dept prioritizes LSQs based on their due date, so
   * Forms generally ends up releasing LSQs close to the due date anyways.
   */
  SCHEDULE_DEFERRED_RELEASE = "SCHEDULE_DEFERRED_RELEASE",
}

export type QnrOrderProductResult = QnrOrderProduct | QnrOrderProductError;

export enum QnrOrderProductStatus {
  AWAITING_DEFERRED_RELEASE = "AWAITING_DEFERRED_RELEASE",
  /**
   * If the qnr is an LSQ, then this status means the mgmt company has processed
   * the qnr, but the HWD Forms department still needs to process the LSQ.
   */
  AWAITING_FORMS = "AWAITING_FORMS",
  AWAITING_MGMT = "AWAITING_MGMT",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  NOT_PAID = "NOT_PAID",
  ON_HOLD = "ON_HOLD",
}

export type QnrQuestion =
  | DropdownQnrQuestion
  | MultiSelectQnrQuestion
  | TextLikeQnrQuestion;

export type QnrQuestionAccountingSystemData =
  | DropdownQnrQuestionAccountingSystemData
  | TextLikeQnrQuestionAccountingSystemData;

export type QnrQuestionCalendarDayExpirationSettingsInput = {
  /**
   * 1-based day of the month when the question should expire.
   * e.g. 1 means the 1st day of the month, 2 means the 2nd day of the month, etc.
   */
  dayOfMonth: Scalars["PositiveInt"]["input"];
  /**
   * Zero-based index of the month when the question should expire.
   * e.g. 0 means January and 11 means December
   */
  monthIdx: Scalars["NonNegativeInt"]["input"];
};

/**
 * Expiration settings for a question where a response should expire on a given
 * day each year.
 */
export type QnrQuestionCalendarExpiration = {
  __typename: "QnrQuestionCalendarExpiration";
  /**
   * 1-based day of the month when a response should expire.
   * For example, if the response should expire on the 1st day of the month, then
   * this field should be 1.
   */
  expirationDayOfMonth: Scalars["Int"]["output"];
  /**
   * 0-based month when a response should expire.
   * January is 0, December is 11, etc.
   */
  expirationMonthIdx: Scalars["Int"]["output"];
};

/**
 * Expiration settings for a question where a response should expire after a
 * certain number of days.
 */
export type QnrQuestionDayCountExpiration = {
  __typename: "QnrQuestionDayCountExpiration";
  /**
   * Number of days for a response to expire.
   * Example: if the value is 1, then a response to this question should expire
   * the day after it was entered.
   */
  expirationDayCount: Scalars["Int"]["output"];
};

export type QnrQuestionExpirationSettings =
  | QnrQuestionCalendarExpiration
  | QnrQuestionDayCountExpiration;

/**
 * If responses to the question should expire, this can represent those
 * expiration settings in a mutation input.
 *
 * If the expiration is based on a calendar day, only 'expirationCalendarDay'
 * should be specified.
 *
 * Conversely, if the expiration is based on the age of the response (in # of
 * days), only 'expirationDayCount' should be specified.
 */
export type QnrQuestionExpirationSettingsInput = {
  expirationCalendarDay?: InputMaybe<QnrQuestionCalendarDayExpirationSettingsInput>;
  expirationDayCount?: InputMaybe<Scalars["PositiveInt"]["input"]>;
};

export enum QnrQuestionQuickUpdateMembership {
  EXCLUDED = "EXCLUDED",
  INCLUDED = "INCLUDED",
}

export enum QnrQuestionScope {
  /**
   * The question will be applied to every mgmt company. Mgmt companies do not
   * have a choice.
   */
  GLOBAL = "GLOBAL",
  /**
   * The question appears on lender-specific questionnaire(s), but it isn't part
   * of our generic set of questions. A lender-specific question should only be
   * displayed to the mgmt company if they're processing an order containing a
   * lender-specific questionnaire that includes the question.
   */
  LENDER_SPECIFIC = "LENDER_SPECIFIC",
  /**
   * By default, the question will not be applied to any mgmt companies. However,
   * any mgmt company can choose to opt into the question.
   */
  OPT_IN = "OPT_IN",
}

export type QnrQuestionWithAccountingSystemData = {
  __typename: "QnrQuestionWithAccountingSystemData";
  /**
   * Unique code of the question.
   * Note: the question may be a follow-up question.
   */
  apiCode: Scalars["String"]["output"];
  data: QnrQuestionAccountingSystemData;
  /**
   * The question's label (for convenience). The client should probably already
   * have the question cached (unless the question is brand new), so the client
   * _could_ get the label from the cache. However, this approach is more
   * convenient for the client.
   */
  questionLabel: Scalars["String"]["output"];
};

export type QnrQuestionWithAcctgImportSettings = {
  __typename: "QnrQuestionWithAcctgImportSettings";
  apiCode: Scalars["String"]["output"];
  importEnabled: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
};

export type QnrQuestionWithExpirationSettings = {
  __typename: "QnrQuestionWithExpirationSettings";
  apiCode: Scalars["String"]["output"];
  expSettings: Maybe<QnrQuestionExpirationSettings>;
  /**
   * Expiration settings of this question's follow-up questions.
   *
   * If this question does _not_ have any follow-up questions, then this field
   * will be an empty array.
   */
  followUpQuestionsWithExpSettings: Array<FollowUpQnrQuestionWithExpirationSettings>;
  hwdDefaultExpSettings: Maybe<QnrQuestionExpirationSettings>;
  label: Scalars["String"]["output"];
};

export type QnrQuestionWithQuickUpdateSettings = {
  __typename: "QnrQuestionWithQuickUpdateSettings";
  apiCode: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  quickUpdateMembership: QnrQuestionQuickUpdateMembership;
};

export type QnrResponseExpirationInfo = {
  __typename: "QnrResponseExpirationInfo";
  /**
   * Date when the response will expire, or null if the question has never
   * received a response.
   *
   * Note: We do NOT need to convert this to HWD's timezone using a schema
   * directive. The server should represent this as an HWD calendar date, so it
   * should inherently be in HWD's timezone already.
   */
  expirationDate: Maybe<Scalars["String"]["output"]>;
  expirationSettings: QnrQuestionExpirationSettings;
  expirationStatus: QnrResponseExpirationStatus;
  /**
   * Date when this question was last updated (or marked as unchanged). If the
   * question has never received a response, this field will be null.
   */
  updatedAt: Maybe<Scalars["String"]["output"]>;
};

export type QnrResponseExpirationInfo_expirationDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type QnrResponseExpirationInfo_updatedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export enum QnrResponseExpirationStatus {
  EXPIRED = "EXPIRED",
  NOT_EXPIRED = "NOT_EXPIRED",
  /**
   * The question doesn't have a response yet, so the expiration status isn't
   * well-defined.
   */
  NO_RESPONSE = "NO_RESPONSE",
  WILL_EXPIRE_SOON = "WILL_EXPIRE_SOON",
}

/** A section of a questionnaire with the data for an association. */
export type QnrSection = {
  __typename: "QnrSection";
  apiCode: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  /**
   * Additional information about the section to be displayed to management
   * company employees.
   */
  inputSubheading: Maybe<Scalars["String"]["output"]>;
  items: Array<QnrSectionItemWithResponse>;
};

export enum QnrSectionItemType {
  QUESTION = "QUESTION",
  SUBSECTION = "SUBSECTION",
}

export type QnrSectionItemWithExpirationSettings =
  | QnrQuestionWithExpirationSettings
  | QnrSubsectionWithExpirationSettings;

export type QnrSectionItemWithOptInQuestionSettings =
  | QnrOptInQuestionWithOptInSettings
  | QnrSubsectionWithOptInQuestionSettings;

export type QnrSectionItemWithQuickUpdateSettings =
  | QnrQuestionWithQuickUpdateSettings
  | QnrSubsectionWithQuickUpdateSettings;

export type QnrSectionItemWithResponse =
  | DropdownQnrQuestionWithResponse
  | MultiSelectQnrQuestionWithResponse
  | QnrSubsectionWithResponses
  | TextLikeQnrQuestionWithResponse;

export type QnrSectionWithExpirationSettings = {
  __typename: "QnrSectionWithExpirationSettings";
  apiCode: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  items: Array<QnrSectionItemWithExpirationSettings>;
};

export type QnrSectionWithOptInQuestionSettings = {
  __typename: "QnrSectionWithOptInQuestionSettings";
  apiCode: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  /**
   * List of subsections and opt-in questions within this section.
   *
   * Note: any subsections with no opt-in questions will not be included.
   */
  items: Array<QnrSectionItemWithOptInQuestionSettings>;
};

export type QnrSectionWithQuickUpdateSettings = {
  __typename: "QnrSectionWithQuickUpdateSettings";
  apiCode: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  items: Array<QnrSectionItemWithQuickUpdateSettings>;
};

export type QnrSubsectionQuestionWithResponse =
  | DropdownQnrQuestionWithResponse
  | MultiSelectQnrQuestionWithResponse
  | TextLikeQnrQuestionWithResponse;

export type QnrSubsectionWithExpirationSettings = {
  __typename: "QnrSubsectionWithExpirationSettings";
  apiCode: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  questions: Array<QnrQuestionWithExpirationSettings>;
};

export type QnrSubsectionWithOptInQuestionSettings = {
  __typename: "QnrSubsectionWithOptInQuestionSettings";
  apiCode: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  /** List of opt-in questions within this section. */
  questions: Array<QnrOptInQuestionWithOptInSettings>;
};

export type QnrSubsectionWithQuickUpdateSettings = {
  __typename: "QnrSubsectionWithQuickUpdateSettings";
  apiCode: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  questions: Array<QnrQuestionWithQuickUpdateSettings>;
};

export type QnrSubsectionWithResponses = {
  __typename: "QnrSubsectionWithResponses";
  apiCode: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  /**
   * Additional information about the subsection to be displayed to management
   * company employees.
   */
  inputSubheading: Maybe<Scalars["String"]["output"]>;
  questions: Array<QnrSubsectionQuestionWithResponse>;
};

export enum QnrTemplate {
  APPRAISER = "APPRAISER",
  COMMERCIAL_INSURANCE = "COMMERCIAL_INSURANCE",
  CONDO = "CONDO",
  CO_OP = "CO_OP",
  PUD = "PUD",
}

export enum QnrType {
  APPRAISER = "APPRAISER",
  INSURANCE = "INSURANCE",
  LENDER = "LENDER",
}

export type Query = {
  __typename: "Query";
  /** Summarize the pricing, including taxes, for adding a rush to an order. */
  addRushPricingSummary: AddRushPricingSummaryResult;
  /**
   * A HomeWiseDocs announcement. Useful for displaying something like a holiday message to inform users that
   * the HomeWise team will not be in the office.
   * If includeInactive is true, we'll bring the announcement if it is not active (if the current date is not
   * between the announcement's start and end date).
   */
  announcement: Maybe<Announcement>;
  /**
   * An employee of a management company. We identify the employee based on
   * their auth token.
   */
  companyEmployee: CompanyEmployee;
  hoa: HOA;
  hoaUnit: HOAUnit;
  hwdAdmin: HwdAdmin;
  /** All mortgage lenders in the HomeWise system */
  lenders: Array<Lender>;
  managementCompany: ManagementCompany;
  /**
   * Find an order either by its UUID or its confirmation number. You should only
   * specify one of the variables.
   * TODO: remove once transition to 'orderLookup' field is complete. See
   * https://buildium.atlassian.net/browse/HWD-1480
   */
  order: Maybe<Order>;
  /** Look up an order either by its UUID or its confirmation number. You should only specify one of the variables. */
  orderLookup: OrderLookupResult;
  /**
   * Summarize an order's pricing, including taxes, before it has been placed.
   * Note: this resolver can be fired in the middle of an order or at the very
   * end of the order process.
   */
  orderPricingSummary: OrderPricingSummaryResult;
  orderProducts: OrderProducts;
  /** Summarize an existing unpaid order's pricing, including taxes. */
  payForOrderPricingSummary: PayForOrderPricingSummaryResult;
  /** Information related to the Remittance system, such as Batch Groups. */
  remittance: Remittance;
  /** Summarize an order update's pricing, including taxes, before it has been requested. */
  requestOrderUpdatePricingSummary: RequestOrderUpdatePricingSummaryResult;
  /**
   * Note that the uuid param is optional. If it is not explicitly passed, then it must
   * be contained in the request's auth token.
   */
  requestor: Requestor;
  /** The id must be contained in the request's auth token */
  requestorOrg: RequestorOrg;
  verifyAddress: AddressVerificationResult;
};

export type Query_addRushPricingSummaryArgs = {
  orderUUID: Scalars["ID"]["input"];
  rushId: Scalars["ID"]["input"];
};

export type Query_announcementArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Query_hoaArgs = {
  includeNonActiveHoas?: InputMaybe<Scalars["Boolean"]["input"]>;
  uuid: Scalars["ID"]["input"];
};

export type Query_hoaUnitArgs = {
  includeNonActiveHoaUnits?: InputMaybe<Scalars["Boolean"]["input"]>;
  uuid: Scalars["ID"]["input"];
};

export type Query_managementCompanyArgs = {
  uuid?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Query_orderArgs = {
  confirmationNumber?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_orderLookupArgs = {
  confirmationNumber?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_orderPricingSummaryArgs = {
  order: OrderPricingSummaryInput;
};

export type Query_payForOrderPricingSummaryArgs = {
  orderUUID: Scalars["ID"]["input"];
  paymentMethod: PaymentMethod;
};

export type Query_requestOrderUpdatePricingSummaryArgs = {
  input: RequestOrderUpdatePricingSummaryInput;
};

export type Query_requestorArgs = {
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_verifyAddressArgs = {
  input: VerifyAddressInput;
};

export type RefundPolicyInfo = {
  __typename: "RefundPolicyInfo";
  nonRefundableFeesMessage: Maybe<Scalars["NonEmptyString"]["output"]>;
  refundPolicyMessage: Maybe<Scalars["NonEmptyString"]["output"]>;
};

export enum ReleaseQnrQuestionErrorCode {
  QUESTION_ALREADY_RELEASED = "QUESTION_ALREADY_RELEASED",
  QUESTION_DELETED = "QUESTION_DELETED",
  QUESTION_NOT_FOUND = "QUESTION_NOT_FOUND",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type ReleaseQnrQuestionMutationError = BaseMutationError & {
  __typename: "ReleaseQnrQuestionMutationError";
  code: ReleaseQnrQuestionErrorCode;
  message: Scalars["String"]["output"];
};

export type ReleaseQnrQuestionMutationResponse = MutationResponse & {
  __typename: "ReleaseQnrQuestionMutationResponse";
  error: Maybe<ReleaseQnrQuestionMutationError>;
  /**
   * Release date (if the mutation succeeded).
   *
   * This helps the client update its cache without needing to worry about date
   * formatting.
   */
  releaseDate: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type ReleaseQnrQuestionMutationResponse_releaseDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type Remittance = {
  __typename: "Remittance";
  /** Remittance alerts information. */
  alerts: RemittanceAlerts;
  /** Return a single Remittance Batch Group. */
  batchGroup: Maybe<RemittanceBatchGroup>;
  /** Returns the calculated Mgmt Company remittance amounts for a given batch group UUID and timeframe. */
  batchGroupAmountsByTimeframe: Array<MgmtCompanyRemittanceAmounts>;
  /** Returns all the Remittance Batch Groups. */
  batchGroups: Array<RemittanceBatchGroup>;
  /**
   * It returns all the HOAs managed by Companies that have the 'payToAssociations'
   * flag enabled in the Remittance Vendor Settings.
   */
  hoasWithVendorSettings: Array<HOA>;
};

export type Remittance_batchGroupArgs = {
  uuid: Scalars["UUID"]["input"];
};

export type Remittance_batchGroupAmountsByTimeframeArgs = {
  input: BatchGroupAmountsByTimeframeInput;
};

export type RemittanceAlerts = {
  __typename: "RemittanceAlerts";
  /** List of Associations that are missing the Vendor ID information. */
  hoasMissingVendorId: Array<HOA>;
  /** List of Management Companies that are inactive but included to a Batch Group. */
  inactiveManagementCompaniesWithBatchGroup: Array<ManagementCompany>;
  /** List of Management Companies that are not included to any Batch Group. */
  managementCompaniesMissingBatchGroup: Array<ManagementCompany>;
  /** List of Management Companies that do not have any email contacts included. */
  managementCompaniesMissingEmailContacts: Array<ManagementCompany>;
  /** List of Management Companies that are missing the Vendor ID information. */
  managementCompaniesMissingVendorId: Array<ManagementCompany>;
};

export type RemittanceAmountsInterface = {
  adjustmentsAmount: Scalars["Float"]["output"];
  cancelsAmount: Scalars["Float"]["output"];
  details: Array<RemittanceOrderLineItemDetail>;
  grossAmount: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  netAmount: Scalars["Float"]["output"];
  offsetAmount: Scalars["Float"]["output"];
  uuid: Scalars["UUID"]["output"];
  vendorId: Scalars["String"]["output"];
};

export type RemittanceBatch = {
  __typename: "RemittanceBatch";
  /** Unique name of the Remittance Batch. */
  batchName: Scalars["String"]["output"];
  /** The date the remittance batch was run. */
  createdAt: Scalars["String"]["output"];
  /** The end date of the Remittance Batch timeframe. */
  endDate: Scalars["String"]["output"];
  /** Management Companies included in the Remittance Batch, with its amount fields aggregated. */
  mgmtCompaniesRemittanceAmounts: Array<MgmtCompanyRemittanceAmounts>;
  /** The start date of the Remittance Batch timeframe. */
  startDate: Scalars["String"]["output"];
};

export type RemittanceBatch_createdAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type RemittanceBatch_endDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type RemittanceBatch_startDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type RemittanceBatchGroup = RemittanceBatchGroupInterface & {
  __typename: "RemittanceBatchGroup";
  description: Maybe<Scalars["String"]["output"]>;
  /** List of the management companies that compose the batch group. */
  managementCompanies: Array<ManagementCompany>;
  /** Name of the Batch Group that should be unique. */
  name: Scalars["String"]["output"];
  uuid: Scalars["UUID"]["output"];
};

export type RemittanceBatchGroupInterface = {
  description: Maybe<Scalars["String"]["output"]>;
  /** Name of the Batch Group that should be unique. */
  name: Scalars["String"]["output"];
  uuid: Scalars["UUID"]["output"];
};

export type RemittanceEmailContactInput = {
  email: Scalars["TrimmedNonEmptyString"]["input"];
  isPrimary: Scalars["Boolean"]["input"];
  name: Scalars["TrimmedNonEmptyString"]["input"];
};

export type RemittanceOrderLineItemDetail = {
  __typename: "RemittanceOrderLineItemDetail";
  /** Amount that the order line item contributes to. */
  amount: Scalars["Float"]["output"];
  /** The description of the bundle, product or fee (label) of the order line item. */
  description: Scalars["String"]["output"];
  /** ID of the HOA */
  hoaId: Scalars["ID"]["output"];
  /** The payment date of the order. */
  paymentDate: Scalars["String"]["output"];
  /** Transaction code (confirmation number). */
  transactionCode: Scalars["String"]["output"];
  /** Indicates to which type of amount the order line item contributes to (gross, cancellation or offset amount). */
  transactionRemittanceType: TransactionRemittanceType;
};

export type RemittanceOrderLineItemDetail_paymentDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type RemittanceRunsInput = {
  /**
   * If true, will filter only remittances that has a net amount of less than 0 USD.
   * This is useful for retrieving only remittances that requires an invoice.
   */
  filterByNetNegative: Scalars["Boolean"]["input"];
};

export type RemittanceTransaction = {
  __typename: "RemittanceTransaction";
  address: Scalars["String"]["output"];
  associationId: Maybe<Scalars["ID"]["output"]>;
  associationName: Scalars["String"]["output"];
  cancellationDate: Maybe<Scalars["String"]["output"]>;
  charge: Scalars["Float"]["output"];
  city: Scalars["String"]["output"];
  currentOwnerFirstName: Scalars["String"]["output"];
  currentOwnerLastName: Scalars["String"]["output"];
  orderDate: Scalars["String"]["output"];
  paymentDate: Maybe<Scalars["String"]["output"]>;
  product: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
  transactionCode: Scalars["String"]["output"];
};

export type RemittanceTransaction_cancellationDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type RemittanceTransaction_orderDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type RemittanceTransaction_paymentDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type RemittanceTransactions = {
  __typename: "RemittanceTransactions";
  canceled: Array<RemittanceTransaction>;
  upfront: Array<RemittanceTransaction>;
};

export type RemittanceTransactionsInput = {
  remittanceId: Scalars["ID"]["input"];
};

export type RemittanceVendorSettingsInterface = {
  vendorId: Maybe<Scalars["String"]["output"]>;
};

export enum ReorderFollowUpQnrQuestionsErrorCode {
  FOLLOW_UP_QUESTION_API_CODES_INVALID = "FOLLOW_UP_QUESTION_API_CODES_INVALID",
  PRIMARY_QUESTION_NOT_FOUND = "PRIMARY_QUESTION_NOT_FOUND",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type ReorderFollowUpQnrQuestionsInput = {
  primaryQuestionApiCode: Scalars["String"]["input"];
  /** API codes of the follow-up questions, in the new desired order. */
  reorderedFollowUpQuestionApiCodes: Array<Scalars["String"]["input"]>;
};

export type ReorderFollowUpQnrQuestionsMutationError = BaseMutationError & {
  __typename: "ReorderFollowUpQnrQuestionsMutationError";
  code: ReorderFollowUpQnrQuestionsErrorCode;
  message: Scalars["String"]["output"];
};

export type ReorderFollowUpQnrQuestionsMutationResponse = MutationResponse & {
  __typename: "ReorderFollowUpQnrQuestionsMutationResponse";
  error: Maybe<ReorderFollowUpQnrQuestionsMutationError>;
  /**
   * The primary dropdown question with the updated follow-up question ordering
   * (if the mutation succeeded).
   */
  primaryQuestion: Maybe<DropdownQnrQuestion>;
  success: Scalars["Boolean"]["output"];
};

export type RequestForgotUsernameEmailMutationResponse = MutationResponse & {
  __typename: "RequestForgotUsernameEmailMutationResponse";
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

export enum RequestOrderUpdateErrorCode {
  CANNOT_UPDATE_SELECTED_FORM = "CANNOT_UPDATE_SELECTED_FORM",
  HOA_NOT_ACTIVE = "HOA_NOT_ACTIVE",
  INVALID_ESTIMATED_CLOSING_DATE = "INVALID_ESTIMATED_CLOSING_DATE",
  NO_FORMS_AVAILABLE_FOR_UPDATE = "NO_FORMS_AVAILABLE_FOR_UPDATE",
  ORDER_ALREADY_UPDATED = "ORDER_ALREADY_UPDATED",
  ORDER_NOT_COMPLETED = "ORDER_NOT_COMPLETED",
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  ORDER_NOT_PAID = "ORDER_NOT_PAID",
  ORDER_SETTINGS_NOT_FOUND = "ORDER_SETTINGS_NOT_FOUND",
  PAYMENT_ERROR = "PAYMENT_ERROR",
}

export type RequestOrderUpdateInput = {
  /**
   * ID's of the forms that the requestor wants in the updated order.
   * These can include ID's from both the sub HOA and any master HOA's (if applicable)
   */
  formIds: Array<Scalars["ID"]["input"]>;
  orderUUID: Scalars["String"]["input"];
  paymentInfo: PaymentInfo;
  /**
   * The requestor's updated estimated closing date. It's not necessarily different
   * from the estimated closing date that the requestor originally specified.
   */
  updatedEstimatedClosingDate: CalendarDateInput;
};

export type RequestOrderUpdateMutationError = BaseMutationError & {
  __typename: "RequestOrderUpdateMutationError";
  code: RequestOrderUpdateErrorCode;
  message: Scalars["String"]["output"];
};

export type RequestOrderUpdateMutationResponse = MutationResponse & {
  __typename: "RequestOrderUpdateMutationResponse";
  error: Maybe<RequestOrderUpdateMutationError>;
  order: Maybe<PlacedOrderInfo>;
  success: Scalars["Boolean"]["output"];
};

export type RequestOrderUpdatePricingSummaryError = {
  __typename: "RequestOrderUpdatePricingSummaryError";
  code: RequestOrderUpdateErrorCode;
  message: Scalars["String"]["output"];
};

export type RequestOrderUpdatePricingSummaryInput = {
  formIds: Array<Scalars["ID"]["input"]>;
  orderUUID: Scalars["String"]["input"];
  /**
   * Payment method. Note: In practice, the client will pass CREDIT_CARD by
   * default (for caching/pre-fetching purposes), so it doesn't hurt to make this
   * non-nullable.
   */
  paymentMethod: PaymentMethod;
};

export type RequestOrderUpdatePricingSummaryResult =
  | OrderPricingSummary
  | RequestOrderUpdatePricingSummaryError;

export type RequestPasswordResetEmailMutationResponse = MutationResponse & {
  __typename: "RequestPasswordResetEmailMutationResponse";
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

/**
 * Requestor. NOTE: we do not expose the requestor's orders on the requestor type. Our UI currently does not have a use case for just getting
 * an individual requestor's orders. You should access the orders through the requestor's "org" resolver.
 *
 * To limit disclosure of PII, a requestor's username, email, phone, and address-related
 * fields are restricted to authenticated users.
 */
export type Requestor = {
  __typename: "Requestor";
  /**
   * Date when the requestor acknowledged their obligations WRT the homeowners' privacy rights,
   * e.g. their CCPA rights. If the requestor hasn't acknowledged this yet, the val will be null.
   */
  acknowledgedHomeownerPrivacyRightsAt: Maybe<Scalars["String"]["output"]>;
  city: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  fullName: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  /**
   * The requestor's "org". All requestors (even homeowners) are treated as though they belong to some organization. As an example, this allows us to
   * represent a title company with different escrow agents all under the same parent account.
   */
  org: RequestorOrg;
  phone: Maybe<Scalars["String"]["output"]>;
  postalCode: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
  streetAddress: Scalars["String"]["output"];
  username: Scalars["String"]["output"];
  uuid: Scalars["String"]["output"];
};

/**
 * Requestor. NOTE: we do not expose the requestor's orders on the requestor type. Our UI currently does not have a use case for just getting
 * an individual requestor's orders. You should access the orders through the requestor's "org" resolver.
 *
 * To limit disclosure of PII, a requestor's username, email, phone, and address-related
 * fields are restricted to authenticated users.
 */
export type Requestor_acknowledgedHomeownerPrivacyRightsAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type RequestorOrg = {
  __typename: "RequestorOrg";
  allOrders: AllOrders;
  cancelledOrders: CancelledOrders;
  completedOrders: CompletedOrders;
  id: Scalars["ID"]["output"];
  /**
   * Suppose a user has already placed an order, but would like to order updated versions of some of the forms
   * in their order. This resolver provides info about such an update request. In reality, this just makes
   * it easier for them to order the forms again. So you could think of "request update" as an "order again" that
   * you see on some e-commerce sites.
   */
  infoForUpdateRequest: OrderUpdateInfoUnion;
  name: Scalars["String"]["output"];
  /** If true, this requestor org should pay for orders using an invoice. */
  payByInvoice: Scalars["Boolean"]["output"];
  pendingOrders: PendingOrders;
  requestors: Array<Requestor>;
  unpaidOrders: UnpaidOrders;
};

export type RequestorOrg_allOrdersArgs = {
  input: OrdersInput;
};

export type RequestorOrg_cancelledOrdersArgs = {
  input: OrdersInput;
};

export type RequestorOrg_completedOrdersArgs = {
  input: OrdersInput;
};

export type RequestorOrg_infoForUpdateRequestArgs = {
  uuid: Scalars["String"]["input"];
};

export type RequestorOrg_pendingOrdersArgs = {
  input: OrdersInput;
};

export type RequestorOrg_requestorsArgs = {
  includeAdmin: Scalars["Boolean"]["input"];
};

export type RequestorOrg_unpaidOrdersArgs = {
  input: OrdersInput;
};

export type ResaleFormOption = {
  __typename: "ResaleFormOption";
  id: Scalars["ID"]["output"];
  /**
   * If true, this is a "master" field. In other words, the
   * field was not defined by the mgmt company. It is a default
   * field in the system. The master fields are typically sales price
   * and escrow number.
   */
  isMasterField: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  required: Scalars["Boolean"]["output"];
};

export type ResaleFormOptionInput = {
  /**
   * ID of the form option. Even when modifying an existing an order, this is still the ID
   * of the form option. It is _not_ the ID of the form option value. In other words, this
   * is _not_ the ID from the TransactionFormOption db model.
   */
  id: Scalars["ID"]["input"];
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type ResaleFormOptionValue = {
  __typename: "ResaleFormOptionValue";
  formOption: ResaleFormOption;
  /**
   * ID of the resale form option value. This is NOT the ID of the form option itself.
   * It's the ID that corresponds to the _value_. In paticular, this is the ID from the
   * TransactionFormOption db model.
   */
  id: Scalars["ID"]["output"];
  value: Maybe<Scalars["String"]["output"]>;
};

export type ResetPasswordMutationResponse = MutationResponse & {
  __typename: "ResetPasswordMutationResponse";
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type RevenueBreakdown = {
  __typename: "RevenueBreakdown";
  atCloseFees: Scalars["Float"]["output"];
  upFrontRevenue: Scalars["Float"]["output"];
};

export type RevenueDataPoint = {
  __typename: "RevenueDataPoint";
  breakdown: RevenueBreakdown;
  date: Scalars["String"]["output"];
};

export type RevenueDataPoint_dateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * A user's role. This can be passed to the @auth schema directive to determine
 * which resources the user is authorized to interact with.
 * Be careful about changing this. This type is duplicated in the common package,
 * and it's used inside of our auth tokens.
 */
export enum Role {
  HOMEWISE_EMPLOYEE = "HOMEWISE_EMPLOYEE",
  MASTER_MGMT_COMPANY_EMPLOYEE = "MASTER_MGMT_COMPANY_EMPLOYEE",
  MASTER_REQUESTOR = "MASTER_REQUESTOR",
  MGMT_COMPANY = "MGMT_COMPANY",
  REQUESTOR = "REQUESTOR",
}

export type RushOption = {
  __typename: "RushOption";
  dueDate: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  paymentDue: PaymentDueType;
  price: Scalars["Float"]["output"];
};

export type RushOption_dueDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type RushPerfForTier = {
  __typename: "RushPerfForTier";
  /**
   * This rush tier's performance for each added rush pricing the mgmt company offered.
   *
   * The array is sorted by mgmtFee in ascending order.
   *
   * If the mgmt company didn't change the added rush pricing during the specified
   * date range, then the array will only have 1 element.
   */
  addedRushPerfByPricing: Array<RushTierPerfForPricing>;
  mgmtProcessingDays: Scalars["Int"]["output"];
  processingDaysType: ProcessingDaysType;
  /**
   * This rush tier's performance for each rush pricing the mgmt company offered.
   *
   * The array is sorted by mgmtFee in ascending order.
   *
   * If the mgmt company didn't change the rush pricing during the specified date
   * range, then the array will only have 1 element.
   */
  rushPerfByPricing: Array<RushTierPerfForPricing>;
};

/**
 * A rush tier's performance for a particular rush pricing that the mgmt company
 * offered to requestors.
 */
export type RushTierPerfForPricing = {
  __typename: "RushTierPerfForPricing";
  /**
   * Rush fee charged by the mgmt company. If this object represents added rush
   * performance, then this field represents the _total_ added rush fee, i.e. it
   * includes both the "base" rush fee and the extra fee for added rush.
   */
  mgmtFee: Scalars["Float"]["output"];
  mgmtRevenue: Scalars["Float"]["output"];
  numCompletedOrders: Scalars["Int"]["output"];
  paymentDue: PaymentDueType;
};

export enum SaveHoaQnrExpirationSettingsErrorCode {
  EMPLOYEE_NOT_FOUND = "EMPLOYEE_NOT_FOUND",
  EXPIRATION_SETTINGS_INVALID = "EXPIRATION_SETTINGS_INVALID",
  HOA_NOT_FOUND = "HOA_NOT_FOUND",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  QUESTION_NOT_FOUND = "QUESTION_NOT_FOUND",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type SaveHoaQnrExpirationSettingsMutationError = BaseMutationError & {
  __typename: "SaveHoaQnrExpirationSettingsMutationError";
  code: SaveHoaQnrExpirationSettingsErrorCode;
  message: Scalars["String"]["output"];
};

export type SaveHoaQnrExpirationSettingsMutationResponse = MutationResponse & {
  __typename: "SaveHoaQnrExpirationSettingsMutationResponse";
  error: Maybe<SaveHoaQnrExpirationSettingsMutationError>;
  /** If 'success' is true, this represents the HOA's new expiration settings. */
  newHoaQnrExpirationSettings: Maybe<HoaQnrExpirationSettings>;
  success: Scalars["Boolean"]["output"];
};

export type SaveHoaQnrExpirationSettingsQuestionInput = {
  expirationSettings: QnrQuestionExpirationSettingsInput;
  /** Unique api code of a question whose expiration settings should be modified. */
  questionApiCode: Scalars["String"]["input"];
};

export enum SaveHoaQnrQuickUpdateSettingsErrorCode {
  EMPLOYEE_NOT_FOUND = "EMPLOYEE_NOT_FOUND",
  HOA_NOT_FOUND = "HOA_NOT_FOUND",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  QUESTION_NOT_FOUND = "QUESTION_NOT_FOUND",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type SaveHoaQnrQuickUpdateSettingsMutationError = BaseMutationError & {
  __typename: "SaveHoaQnrQuickUpdateSettingsMutationError";
  code: SaveHoaQnrQuickUpdateSettingsErrorCode;
  message: Scalars["String"]["output"];
};

export type SaveHoaQnrQuickUpdateSettingsMutationResponse = MutationResponse & {
  __typename: "SaveHoaQnrQuickUpdateSettingsMutationResponse";
  error: Maybe<SaveHoaQnrQuickUpdateSettingsMutationError>;
  /** If 'success' is true, this represents the HOA's new Quick Update settings. */
  newHoaQnrQuickUpdateSettings: Maybe<HoaQnrQuickUpdateSettings>;
  success: Scalars["Boolean"]["output"];
};

export type SaveHoaQnrQuickUpdateSettingsQuestionInput = {
  newQuickUpdateMembership: QnrQuestionQuickUpdateMembership;
  /**
   * Unique api code of a question whose Quick Update settings should be modified.
   *
   * Note: this should not represent a follow-up question. Follow-up questions
   * don't have their own Quick Update settings. Their Quick Update settings are
   * based on the primary question.
   */
  questionApiCode: Scalars["String"]["input"];
};

export type SaveKeyMetricsFiltersInput = {
  dateFilter: MgmtAnalyticsSaveDateFilterInput;
  hoaStates: MgmtAnalyticsHoaStatesFilterInput;
  hoaTypes: MgmtAnalyticsHoaTypesFilterInput;
};

export type SaveKeyMetricsFiltersMutationResponse = MutationResponse & {
  __typename: "SaveKeyMetricsFiltersMutationResponse";
  error: Maybe<GenericMutationError>;
  /**
   * The saved filter settings (if the mutation succeeds). This is useful for
   * updating the client-side cache.
   * If the mutation fails, this will be null.
   */
  savedFilters: Maybe<MgmtKeyMetricsFilterSettings>;
  success: Scalars["Boolean"]["output"];
};

export enum SaveMgmtQnrAcctgImportSettingsErrorCode {
  EMPLOYEE_NOT_FOUND = "EMPLOYEE_NOT_FOUND",
  INPUT_INVALID = "INPUT_INVALID",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type SaveMgmtQnrAcctgImportSettingsInput = {
  /**
   * The accounting system's code.
   *
   * Some mgmt companies use multiple accounting systems. Furthermore, the
   * import settings for each system are independent. That's why you need to
   * specify which accounting system's settings you're updating.
   */
  accountingSystemCode: AccountingSystemCode;
  questionsToModify: Array<SaveMgmtQnrAcctgImportSettingsQuestionInput>;
};

export type SaveMgmtQnrAcctgImportSettingsMutationError = BaseMutationError & {
  __typename: "SaveMgmtQnrAcctgImportSettingsMutationError";
  code: SaveMgmtQnrAcctgImportSettingsErrorCode;
  message: Scalars["String"]["output"];
};

export type SaveMgmtQnrAcctgImportSettingsMutationResponse =
  MutationResponse & {
    __typename: "SaveMgmtQnrAcctgImportSettingsMutationResponse";
    error: Maybe<SaveMgmtQnrAcctgImportSettingsMutationError>;
    /**
     * If 'success' is true, this represents the management company's new
     * accounting import settings.
     */
    newAcctgImportSettings: Maybe<MgmtQnrAcctgImportSettings>;
    success: Scalars["Boolean"]["output"];
  };

export type SaveMgmtQnrAcctgImportSettingsQuestionInput = {
  /**
   * This value represents whether responses for the question should be
   * imported from the accounting system.
   */
  importEnabled: Scalars["Boolean"]["input"];
  /**
   * Unique api code of a question whose accounting import settings should be
   * modified.
   */
  questionApiCode: Scalars["String"]["input"];
};

export enum SaveMgmtQnrDefaultContactSettingsErrorCode {
  CONTACT_NOT_FOUND = "CONTACT_NOT_FOUND",
  EMPLOYEE_NOT_FOUND = "EMPLOYEE_NOT_FOUND",
  INPUT_INVALID = "INPUT_INVALID",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type SaveMgmtQnrDefaultContactSettingsInput = {
  newDefaultPrimaryContactEmployeeUUID?: InputMaybe<Scalars["ID"]["input"]>;
  newDefaultSignatureContactEmployeeUUID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type SaveMgmtQnrDefaultContactSettingsMutationError =
  BaseMutationError & {
    __typename: "SaveMgmtQnrDefaultContactSettingsMutationError";
    code: SaveMgmtQnrDefaultContactSettingsErrorCode;
    message: Scalars["String"]["output"];
  };

export type SaveMgmtQnrDefaultContactSettingsMutationResponse =
  MutationResponse & {
    __typename: "SaveMgmtQnrDefaultContactSettingsMutationResponse";
    error: Maybe<SaveMgmtQnrDefaultContactSettingsMutationError>;
    success: Scalars["Boolean"]["output"];
  };

export enum SaveMgmtQnrOptInSettingsErrorCode {
  EMPLOYEE_NOT_FOUND = "EMPLOYEE_NOT_FOUND",
  INPUT_INVALID = "INPUT_INVALID",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type SaveMgmtQnrOptInSettingsMutationError = BaseMutationError & {
  __typename: "SaveMgmtQnrOptInSettingsMutationError";
  code: SaveMgmtQnrOptInSettingsErrorCode;
  message: Scalars["String"]["output"];
};

export type SaveMgmtQnrOptInSettingsMutationResponse = MutationResponse & {
  __typename: "SaveMgmtQnrOptInSettingsMutationResponse";
  error: Maybe<SaveMgmtQnrOptInSettingsMutationError>;
  /**
   * If 'success' is true, this represents the management company's new Opt In
   * settings.
   */
  newOptInQuestionSettings: Maybe<MgmtQnrOptInQuestionSettings>;
  success: Scalars["Boolean"]["output"];
};

export type SaveMgmtQnrOptInSettingsQuestionInput = {
  newOptIn: Scalars["Boolean"]["input"];
  /**
   * Unique api code of a question whose Opt In settings should be modified.
   *
   * Note: this should not represent a follow-up question. Follow-up questions
   * don't have their own Opt In settings. Their Opt In settings are
   * based on the primary question.
   */
  questionApiCode: Scalars["String"]["input"];
};

export type SaveMgmtRemittanceEmailContactsInput = {
  mgmtCompanyUuid: Scalars["UUID"]["input"];
  remittanceEmailContacts: Array<RemittanceEmailContactInput>;
};

export type SaveMgmtRemittanceEmailContactsResponse = {
  __typename: "SaveMgmtRemittanceEmailContactsResponse";
  emailContacts: Maybe<Array<MgmtCompanyRemittanceEmailContact>>;
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type SaveMgmtRemittanceVendorSettingsInput = {
  mgmtCompanyUuid: Scalars["UUID"]["input"];
  settings: MgmtRemittanceVendorSettingsInput;
};

export type SaveMgmtRemittanceVendorSettingsMutationResponse =
  MutationResponse & {
    __typename: "SaveMgmtRemittanceVendorSettingsMutationResponse";
    error: Maybe<GenericMutationError>;
    success: Scalars["Boolean"]["output"];
  };

export type SaveProductPerfFiltersInput = {
  dateFilter: MgmtAnalyticsSaveDateFilterInput;
  hoaStates: MgmtAnalyticsHoaStatesFilterInput;
  hoaTypes: MgmtAnalyticsHoaTypesFilterInput;
};

export type SaveProductPerfFiltersMutationResponse = MutationResponse & {
  __typename: "SaveProductPerfFiltersMutationResponse";
  error: Maybe<GenericMutationError>;
  /**
   * The saved filter settings (if the mutation succeeds). This is useful for
   * updating the client-side cache.
   * If the mutation fails, this will be null.
   */
  savedFilters: Maybe<MgmtProductPerfFilterSettings>;
  success: Scalars["Boolean"]["output"];
};

export type SaveProductProcessingFiltersInput = {
  employeeStatuses: MgmtAnalyticsEmployeeStatusFilterInput;
  hoaStates: MgmtAnalyticsHoaStatesFilterInput;
  hoaTypes: MgmtAnalyticsHoaTypesFilterInput;
  productCategories: MgmtAnalyticsProductCategoriesFilterInput;
  releaseDateFilter: MgmtAnalyticsSaveDateFilterInput;
};

export type SaveProductProcessingFiltersMutationResponse = MutationResponse & {
  __typename: "SaveProductProcessingFiltersMutationResponse";
  error: Maybe<GenericMutationError>;
  /**
   * The saved filter settings (if the mutation succeeds). This is useful for
   * updating the client-side cache.
   * If the mutation fails, this will be null.
   */
  savedFilters: Maybe<MgmtProductProcessingFilterSettings>;
  success: Scalars["Boolean"]["output"];
};

export type SaveQnrDataError =
  | BasicSaveQnrDataError
  | PostSaveQnrDataError
  | SaveQnrDataSmartValidationError;

/**
 * Note: This mutation is generalized to handle multiple scenarios. If the user
 * wants to save the questionnaire data for a questionnaire template, then
 * 'saveTemplateInput' should be non-null and 'saveOrderProductInput' should be
 * null. On the other hand, if the user is trying to process a questionnaire
 * from a specific order, then 'saveOrderProductInput' should be non-null and
 * 'saveTemplateInput' should be null.
 */
export type SaveQnrDataInput = {
  contactSettings: ContactSettingsSaveQnrDataInput;
  proposedResponses: ProposedResponsesSaveQnrDataInput;
  saveOrderProductInput?: InputMaybe<SaveQnrOrderProductDataInput>;
  saveTemplateInput?: InputMaybe<SaveQnrTemplateDataInput>;
};

export type SaveQnrDataMutationResponse = {
  __typename: "SaveQnrDataMutationResponse";
  error: Maybe<SaveQnrDataError>;
  success: Scalars["Boolean"]["output"];
};

export type SaveQnrDataSmartValidationError = BaseMutationError & {
  __typename: "SaveQnrDataSmartValidationError";
  /**
   * List of "fields" involved in the smart validation error.
   *
   * Typically, each field will simply represent a question. However, if we apply
   * smart validations to "bespoke" questions (e.g. Commercial Use & Multi-Unit
   * Owners), then a field may only represent _part_ of the question, such as an
   * individual column from a specific row.
   */
  fields: Array<SaveQnrDataSmartValidationErrorField>;
  message: Scalars["String"]["output"];
};

export type SaveQnrDataSmartValidationErrorField = {
  __typename: "SaveQnrDataSmartValidationErrorField";
  /**
   * The formatted value of the field's response.
   *
   * If the user edited this field, then this represents the new response being
   * proposed. OTOH, if the user did _not_ edit this field, then this represents
   * the previously-saved value.
   */
  formattedValue: Scalars["String"]["output"];
  /**
   * Human-friendly label. In most cases, this will be a more compact version of
   * the question's label. The Smart Validation UI doesn't have as much space as
   * the template itself.
   */
  label: Scalars["String"]["output"];
  /**
   * API code of the question that this field corresponds to.
   *
   * As an example, this may help the client implement "Scroll to Question" functionality.
   */
  questionApiCode: Scalars["String"]["output"];
};

export type SaveQnrOrderProductDataInput = {
  orderProductUUID: Scalars["String"]["input"];
  postSaveAction: QnrOrderProductPostSaveAction;
};

export type SaveQnrTemplateDataInput = {
  hoaUUID: Scalars["String"]["input"];
  qnrType: QnrType;
};

export type SaveRemittanceBatchGroupInput = {
  /** The description of the Remittance Batch Group; */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Array of management companies IDs that belongs to the Remittance Batch Group. */
  managementCompaniesIds: Array<Scalars["ID"]["input"]>;
  /** The name of the Remittance Batch Group, this should be unique. */
  name: Scalars["TrimmedNonEmptyString"]["input"];
  /** The UUID of the Remittance Batch Group. Empty if it is a new record. */
  uuid?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type SaveRemittanceBatchGroupMutationResponse = MutationResponse & {
  __typename: "SaveRemittanceBatchGroupMutationResponse";
  error: Maybe<GenericMutationError>;
  /** The successfully saved Batch Group (if the mutation succeeded). */
  savedBatchGroup: Maybe<RemittanceBatchGroup>;
  success: Scalars["Boolean"]["output"];
};

export type SaveRushPerfFiltersInput = {
  completionDateFilter: MgmtAnalyticsSaveDateFilterInput;
  hoaStates: MgmtAnalyticsHoaStatesFilterInput;
  hoaTypes: MgmtAnalyticsHoaTypesFilterInput;
};

export type SaveRushPerfFiltersMutationResponse = MutationResponse & {
  __typename: "SaveRushPerfFiltersMutationResponse";
  error: Maybe<GenericMutationError>;
  /**
   * The saved filter settings (if the mutation succeeds). This is useful for
   * updating the client-side cache.
   * If the mutation fails, this will be null.
   */
  savedFilters: Maybe<MgmtRushPerfFilterSettings>;
  success: Scalars["Boolean"]["output"];
};

export type SaveStructuralSafetyQuestionCustomResponseInput = {
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropdownVariant: DropdownQnrQuestionPredefinedOptionVariant;
};

export type SaveStructuralSafetyQuestionInput = {
  apiCode: Scalars["String"]["input"];
  applyStandardHwdResponse: Scalars["Boolean"]["input"];
  customResponse?: InputMaybe<SaveStructuralSafetyQuestionCustomResponseInput>;
};

export type SaveTrendsFiltersInput = {
  hoaStates: MgmtAnalyticsHoaStatesFilterInput;
  hoaTypes: MgmtAnalyticsHoaTypesFilterInput;
  years: Array<Scalars["Int"]["input"]>;
};

export type SaveTrendsFiltersMutationResponse = MutationResponse & {
  __typename: "SaveTrendsFiltersMutationResponse";
  error: Maybe<GenericMutationError>;
  /**
   * The saved filter settings (if the mutation succeeds). This is useful for
   * updating the client-side cache.
   * If the mutation fails, this will be null.
   */
  savedFilters: Maybe<MgmtTrendsFilterSettings>;
  success: Scalars["Boolean"]["output"];
};

/**
 * Shipping info. To limit disclosure of PII, the recipient's name and address
 * are restricted to authenticated users.
 */
export type ShippingInfo = {
  __typename: "ShippingInfo";
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  dateShipped: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  postalCode: Scalars["String"]["output"];
  /**
   * The company that will deliver the physical shipment (e.g. FedEx).
   * This field must be nullable. It gets entered by the admin side of the
   * system once the physical shipment gets sent.
   */
  shippingCompany: Maybe<Scalars["String"]["output"]>;
  /** This resolver will return null if the db has a shipping method that is no longer supported */
  shippingMethodWithLegacySupport: Maybe<ShippingMethodWithLegacySupport>;
  state: Scalars["String"]["output"];
  streetAddress: Scalars["String"]["output"];
  /**
   * Code to track the shipment.
   * This field must be nullable. It gets entered by the admin side of the
   * system once the physical shipment gets sent.
   */
  trackingNumber: Maybe<Scalars["String"]["output"]>;
  unit: Maybe<Scalars["String"]["output"]>;
};

/**
 * Shipping info. To limit disclosure of PII, the recipient's name and address
 * are restricted to authenticated users.
 */
export type ShippingInfo_dateShippedArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type ShippingInfoInput = {
  city: Scalars["TrimmedNonEmptyString"]["input"];
  country: Scalars["TrimmedNonEmptyString"]["input"];
  name: Scalars["TrimmedNonEmptyString"]["input"];
  postalCode: Scalars["TrimmedNonEmptyString"]["input"];
  shippingMethod: ShippingMethod;
  state: Scalars["TrimmedNonEmptyString"]["input"];
  streetAddress: Scalars["TrimmedNonEmptyString"]["input"];
  unit?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ShippingMethod {
  STANDARD = "STANDARD",
  USB_FLASH_DRIVE = "USB_FLASH_DRIVE",
}

export enum ShippingMethodWithLegacySupport {
  CD = "CD",
  STANDARD = "STANDARD",
  THREE_DAY = "THREE_DAY",
  USB_FLASH_DRIVE = "USB_FLASH_DRIVE",
}

export type SignUpMutationInput = {
  city: Scalars["TrimmedNonEmptyString"]["input"];
  email: Scalars["Email"]["input"];
  firstName: Scalars["TrimmedNonEmptyString"]["input"];
  lastName: Scalars["TrimmedNonEmptyString"]["input"];
  orgName?: InputMaybe<Scalars["String"]["input"]>;
  orgType: ApiRequestorOrgType;
  password: Scalars["NonEmptyString"]["input"];
  phone: Scalars["PhoneNumber"]["input"];
  state: Scalars["TrimmedNonEmptyString"]["input"];
  streetAddress: Scalars["TrimmedNonEmptyString"]["input"];
  username: Scalars["Username"]["input"];
  zip: Scalars["ZipCode"]["input"];
};

export type SignUpMutationResponse = MutationResponse & {
  __typename: "SignUpMutationResponse";
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type SimpleQnrLayout = {
  __typename: "SimpleQnrLayout";
  sections: Array<SimpleQnrSectionForLayout>;
};

export type SimpleQnrQuestionForLayout = {
  __typename: "SimpleQnrQuestionForLayout";
  apiCode: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  templates: Array<QnrTemplate>;
};

/** A simplified, template-agnostic version of a questionnaire section. */
export type SimpleQnrSectionForLayout = {
  __typename: "SimpleQnrSectionForLayout";
  apiCode: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  items: Array<SimpleQnrSectionItemForLayout>;
  templates: Array<QnrTemplate>;
};

export type SimpleQnrSectionItemForLayout =
  | SimpleQnrQuestionForLayout
  | SimpleQnrSubsectionForLayout;

export type SimpleQnrSubsectionForLayout = {
  __typename: "SimpleQnrSubsectionForLayout";
  apiCode: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  questions: Array<SimpleQnrQuestionForLayout>;
  templates: Array<QnrTemplate>;
};

export type StandardProcessingInfo = {
  __typename: "StandardProcessingInfo";
  dueDate: Scalars["String"]["output"];
};

export type StandardProcessingInfo_dueDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type StartProcessingProductMutationResponse = MutationResponse & {
  __typename: "StartProcessingProductMutationResponse";
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

/**
 * Can be useful for representing the input for a string-based field.
 *
 * Unlike using a nullable 'String' type alone, this object type allows us to
 * unambiguously differentiate between a value that should not be updated and a
 * value that should be made empty.
 *
 * If 'dirty' is true, 'proposedValue' should represent the value that should be
 * saved. If 'dirty' is true and 'proposedValue' is null, that will be
 * interpreted as the user deleting their previously-saved value (which may not
 * be allowed in some cases).
 *
 * If 'dirty' is false, then 'proposedValue' will be ignored since the response
 * must not need saving.
 */
export type StringFieldResponseInput = {
  dirty: Scalars["Boolean"]["input"];
  proposedValue?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringQuestionSaveDataInput = {
  apiCode: Scalars["String"]["input"];
  comment: StringFieldResponseInput;
  response: StringFieldResponseInput;
};

export type TextLikeQnrQuestion = {
  __typename: "TextLikeQnrQuestion";
  apiCode: Scalars["String"]["output"];
  core: CoreQuestionInfo;
  responseRequired: Scalars["Boolean"]["output"];
  responseType: TextLikeQnrQuestionResponseType;
};

export type TextLikeQnrQuestionAccountingSystemData = {
  __typename: "TextLikeQnrQuestionAccountingSystemData";
  /**
   * Data we received from the accounting system for this question.
   *
   * Note: The 'TextLikeQnrQuestionResponse' type technically allows the value to
   * be represented as null. However, in practice, you can assume that the value
   * contained in this field will always be non-null (and non-empty in the case
   * of a string-based response type). See 'HoaQnrAccountingSystemData.questions'
   */
  acctgSystemValue: TextLikeQnrQuestionResponse;
  /**
   * The latest response saved in HWD.
   *
   * Note: If the HOA doesn't have a response to this question saved in HWD yet,
   * then the value within this field will be null.
   */
  latestHwdResponse: TextLikeQnrQuestionResponse;
  /** The date of the latest response saved in HWD. */
  latestHwdResponseDate: Maybe<Scalars["String"]["output"]>;
};

export type TextLikeQnrQuestionAccountingSystemData_latestHwdResponseDateArgs =
  {
    format?: InputMaybe<Scalars["String"]["input"]>;
  };

export type TextLikeQnrQuestionResponse =
  | DateQnrQuestionResponse
  | FloatQnrQuestionResponse
  | IntQnrQuestionResponse
  | PlainTextQnrQuestionResponse
  | UsdQnrQuestionResponse;

export enum TextLikeQnrQuestionResponseType {
  /**
   * A date question response type.
   *
   * Note: we don't specially validate date responses. Sometimes, a user needs
   * to specify a value like "January 2019" or "End of 2020" when they don't
   * have a precise date. As a result, our date questions can't be too strict.
   *
   * Our date questions should effectively behave like plain text questions,
   * except they should give the user the option to select from a datepicker for
   * convenience.
   */
  DATE = "DATE",
  FLOAT = "FLOAT",
  INTEGER = "INTEGER",
  PLAIN_TEXT = "PLAIN_TEXT",
  USD = "USD",
}

export type TextLikeQnrQuestionWithResponse = {
  __typename: "TextLikeQnrQuestionWithResponse";
  apiCode: Scalars["String"]["output"];
  comment: Maybe<Scalars["String"]["output"]>;
  core: CoreQuestionInfoForResponse;
  expirationInfo: Maybe<QnrResponseExpirationInfo>;
  /**
   * The response, whose type depends on what type of text-like question this is.
   *
   * Also note that the value nested within this field may be null since the
   * question might not have been answered yet.
   */
  response: TextLikeQnrQuestionResponse;
  responseRequired: Scalars["Boolean"]["output"];
};

export type TextLikeQuestionsSaveDataInput = {
  date: Array<StringQuestionSaveDataInput>;
  float: Array<FloatQuestionSaveDataInput>;
  integer: Array<IntQuestionSaveDataInput>;
  plainText: Array<StringQuestionSaveDataInput>;
  usd: Array<FloatQuestionSaveDataInput>;
};

export type TransactionDetailsInput = {
  buyer: BuyerInput;
  buyerOccupied?: InputMaybe<Scalars["Boolean"]["input"]>;
  currentOwner: CurrentOwnerInput;
  loanNumber?: InputMaybe<Scalars["String"]["input"]>;
  resaleFormOptionValues?: InputMaybe<Array<ResaleFormOptionInput>>;
};

export type TransactionInfoFields = {
  __typename: "TransactionInfoFields";
  /** If true, display the buyer occupied radio button on the client */
  displayBuyerOccupied: Scalars["Boolean"]["output"];
  /** If true, display the loan number field on the client */
  displayLoanNumber: Scalars["Boolean"]["output"];
  /**
   * Form fields that an HOA may choose to display to requestors.
   * For example, HOA's may want to collect certain data for resale transactions, such as the
   * sales price of the home or the escrow number.
   * NOTE: These form options should only be displayed for resale transactions!
   */
  resaleFormOptions: Array<ResaleFormOption>;
};

/** Indicates the destination of the remitted amount. */
export enum TransactionRemittanceType {
  CANCELLATION = "CANCELLATION",
  GROSS = "GROSS",
  OFFSET = "OFFSET",
}

export enum TransactionType {
  /**
   * Neither a resale nor a refinance transaction. We should only offer individual documents for this
   * transaction type (no forms or bundles). as a result, this is sometimes called a "docs only" transaction.
   */
  OTHER = "OTHER",
  REFINANCE = "REFINANCE",
  RESALE = "RESALE",
}

export type UnpaidOrder = Order & {
  __typename: "UnpaidOrder";
  /**
   * The time when the order was acknowledged (if there was an acknowledgement).
   * Restricted to authenticated users to limit PII disclosure.
   */
  acknowledgedAt: Maybe<Scalars["String"]["output"]>;
  /**
   * The notice that the requestor acknowledged when they placed the order.
   * Restricted to authenticated users to limit PII disclosure.
   */
  acknowledgedNotice: Maybe<Scalars["String"]["output"]>;
  /**
   * Suppose the user didn't use rush processing when they placed the order, but
   * now they'd like to add rush processing to the order. Then, we should use
   * this resolver to figure out what rush options, if any, are available. If there
   * aren't any rush options, this resolver can be used to tell the user the reason.
   */
  addedRushOptionsInfo: AddedRushOptionsInfo;
  attachments: Array<OrderAttachment>;
  bundles: Array<OrderBundle>;
  buyer: Maybe<Buyer>;
  /**
   * If true, the buyer intends to move into the property. Only applies to resale
   * transactions. Restricted to authenticated users to limit PII disclosure.
   */
  buyerOccupied: Maybe<Scalars["Boolean"]["output"]>;
  /** Info about whether the requestor can cancel the order. */
  cancelOrderActionInfo: OrderActionInfo;
  confirmationEmailSentTo: Scalars["String"]["output"];
  confirmationNumber: Scalars["String"]["output"];
  currentOwner: CurrentOwner;
  /**
   * The estimated closing date of the real estate transaction. We don't always collect this field.
   * For example, we don't ask the requestor to enter it if the order doesn't contain any forms.
   *
   * Note: We do NOT need to convert this to HWD's timezone using a schema directive. It's an HWD
   * calendar date, so it's inherently in HWD's timezone already.
   */
  estimatedClosingDate: Maybe<Scalars["String"]["output"]>;
  hasRush: Scalars["Boolean"]["output"];
  hoa: HOA;
  individualItems: Array<OrderItem>;
  /**
   * The loan #. This only applies if the user ordered a questionnaire. Restricted to
   * authenticated users to limit PII disclosure.
   */
  loanNumber: Maybe<Scalars["String"]["output"]>;
  orderDate: Scalars["String"]["output"];
  paidAt: Maybe<Scalars["String"]["output"]>;
  paymentMethod: PaymentMethod;
  presaleDocumentsInfo: Maybe<Array<PresaleDocumentsGroup>>;
  /**
   * URL that can be used to view the order's receipt (which we often refer to as "order statement" since
   * it's technically not a receipt).
   * Unless the order is still awaiting payment, then this field should be non-null.
   */
  receiptUrl: Maybe<Scalars["String"]["output"]>;
  requestor: Requestor;
  resaleFormOptionValues: Maybe<Array<ResaleFormOptionValue>>;
  rushAddedAt: Maybe<Scalars["String"]["output"]>;
  shareWithEmails: Maybe<Array<Scalars["String"]["output"]>>;
  shippingInfo: Maybe<ShippingInfo>;
  subjectPropertyAddress: Scalars["String"]["output"];
  totalAtCloseFee: Scalars["Float"]["output"];
  totalPaid: Scalars["Float"]["output"];
  totalPrice: Scalars["Float"]["output"];
  /**
   * Total amount of money that was due at up front (USD).
   * Note: this doesn't necessarily mean the up front fees have been paid yet. For example, this
   * fee still has to be paid for order that are still awaiting payment (e.g. 3rd party pay)
   */
  totalUpFrontFee: Scalars["Float"]["output"];
  transactionType: TransactionType;
  upFrontPayStatus: UpFrontPayStatus;
  uuid: Scalars["String"]["output"];
};

export type UnpaidOrder_acknowledgedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type UnpaidOrder_estimatedClosingDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type UnpaidOrder_orderDateArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type UnpaidOrder_paidAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type UnpaidOrder_rushAddedAtArgs = {
  format?: InputMaybe<Scalars["String"]["input"]>;
};

export type UnpaidOrders = {
  __typename: "UnpaidOrders";
  orders: Array<UnpaidOrder>;
  totalNumOrders: Scalars["Int"]["output"];
};

export enum UpFrontPayStatus {
  DUE_AT_CLOSE = "DUE_AT_CLOSE",
  NOT_PAID = "NOT_PAID",
  PAID_BY_CHECK = "PAID_BY_CHECK",
  PAID_BY_CREDIT_CARD = "PAID_BY_CREDIT_CARD",
}

export type UpdateDemandPayeeInput = {
  id: Scalars["ID"]["input"];
  updateInfo: UpdateDemandPayeeUpdateInfo;
};

export type UpdateDemandPayeeResponse = {
  __typename: "UpdateDemandPayeeResponse";
  demandPayee: Maybe<DemandPayee>;
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type UpdateDemandPayeeUpdateInfo = {
  address?: InputMaybe<DemandPayeeAddressInput>;
  contactName?: InputMaybe<Scalars["TrimmedNonEmptyString"]["input"]>;
  email?: InputMaybe<Scalars["Email"]["input"]>;
  fax?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
  mailingAddress?: InputMaybe<DemandPayeeAddressInput>;
  name?: InputMaybe<Scalars["TrimmedNonEmptyString"]["input"]>;
  phone?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
  website?: InputMaybe<Scalars["TrimmedNonEmptyString"]["input"]>;
};

export enum UpdateFollowUpQnrQuestionErrorCode {
  EXPIRATION_SETTINGS_INVALID = "EXPIRATION_SETTINGS_INVALID",
  QUESTION_NOT_FOUND = "QUESTION_NOT_FOUND",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type UpdateFollowUpQnrQuestionInput = {
  /**
   * The follow-up question's API code.
   *
   * Note: this input is included merely to identify the question. A question's
   * code cannot be changed once it has been created.
   */
  apiCode: Scalars["String"]["input"];
  customOutputLabel?: InputMaybe<Scalars["String"]["input"]>;
  expirationSettings?: InputMaybe<QnrQuestionExpirationSettingsInput>;
  hwdNotes?: InputMaybe<Scalars["String"]["input"]>;
  label: Scalars["String"]["input"];
  mgmtInputHelperText?: InputMaybe<Scalars["String"]["input"]>;
  responseRequired: Scalars["Boolean"]["input"];
};

export type UpdateFollowUpQnrQuestionMutationError = BaseMutationError & {
  __typename: "UpdateFollowUpQnrQuestionMutationError";
  code: UpdateFollowUpQnrQuestionErrorCode;
  message: Scalars["String"]["output"];
};

export type UpdateFollowUpQnrQuestionMutationResponse = MutationResponse & {
  __typename: "UpdateFollowUpQnrQuestionMutationResponse";
  error: Maybe<UpdateFollowUpQnrQuestionMutationError>;
  question: Maybe<BasicFollowUpQnrQuestion>;
  success: Scalars["Boolean"]["output"];
};

export type UpdateMgmtCompanyInput = {
  updateInfo: UpdateMgmtCompanyUpdateInfo;
  uuid: Scalars["UUID"]["input"];
};

export type UpdateMgmtCompanyResponse = {
  __typename: "UpdateMgmtCompanyResponse";
  company: Maybe<ManagementCompany>;
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type UpdateMgmtCompanyUpdateInfo = {
  name?: InputMaybe<Scalars["TrimmedNonEmptyString"]["input"]>;
};

export enum UpdatePrimaryQnrQuestionErrorCode {
  EXPIRATION_SETTINGS_INVALID = "EXPIRATION_SETTINGS_INVALID",
  QUESTION_NOT_FOUND = "QUESTION_NOT_FOUND",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type UpdatePrimaryQnrQuestionInput = {
  /**
   * The question's API code.
   *
   * Note: this input is included merely to identify the question. A question's
   * code cannot be changed once it has been created.
   */
  apiCode: Scalars["String"]["input"];
  customOutputLabel?: InputMaybe<Scalars["String"]["input"]>;
  expirationSettings?: InputMaybe<QnrQuestionExpirationSettingsInput>;
  hwdNotes?: InputMaybe<Scalars["String"]["input"]>;
  isDefaultQuickUpdateMember: Scalars["Boolean"]["input"];
  label: Scalars["String"]["input"];
  mgmtInputHelperText?: InputMaybe<Scalars["String"]["input"]>;
  responseRequired: Scalars["Boolean"]["input"];
};

export type UpdatePrimaryQnrQuestionMutationError = BaseMutationError & {
  __typename: "UpdatePrimaryQnrQuestionMutationError";
  code: UpdatePrimaryQnrQuestionErrorCode;
  message: Scalars["String"]["output"];
};

export type UpdatePrimaryQnrQuestionMutationResponse = MutationResponse & {
  __typename: "UpdatePrimaryQnrQuestionMutationResponse";
  error: Maybe<UpdatePrimaryQnrQuestionMutationError>;
  question: Maybe<QnrQuestion>;
  success: Scalars["Boolean"]["output"];
};

export enum UpdateQnrLayoutErrorCode {
  /** The specified layout was invalid, e.g. a question was missing or duplicated. */
  INVALID_LAYOUT = "INVALID_LAYOUT",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type UpdateQnrLayoutMutationError = BaseMutationError & {
  __typename: "UpdateQnrLayoutMutationError";
  code: UpdateQnrLayoutErrorCode;
  message: Scalars["String"]["output"];
};

export type UpdateQnrLayoutMutationResponse = MutationResponse & {
  __typename: "UpdateQnrLayoutMutationResponse";
  error: Maybe<UpdateQnrLayoutMutationError>;
  success: Scalars["Boolean"]["output"];
  updatedLayout: Maybe<SimpleQnrLayout>;
};

export type UpdateQnrLayoutSectionInput = {
  apiCode: Scalars["String"]["input"];
  items: Array<UpdateQnrLayoutSectionItemInput>;
};

export type UpdateQnrLayoutSectionItemInput = {
  apiCode: Scalars["String"]["input"];
  itemType: QnrSectionItemType;
  /**
   * If this item represents a subsection, this field should contain the API
   * codes of the subsection's questions (in the desired order).
   * Else, this field should be null.
   */
  subsectionQuestionApiCodes?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type UpdateRequestorAnnouncementInput = {
  id: Scalars["ID"]["input"];
  /** Input for a Date Time Interval. Dates and Times entered here are assumed to be in HWD_TIME_ZONE. */
  interval: DateTimeIntervalInput;
  message: Scalars["TrimmedNonEmptyString"]["input"];
};

export type UpdateRequestorAnnouncementResponse = {
  __typename: "UpdateRequestorAnnouncementResponse";
  announcement: Maybe<Announcement>;
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type UpdateRequestorProfileMutationInput = {
  city: Scalars["TrimmedNonEmptyString"]["input"];
  email: Scalars["Email"]["input"];
  firstName: Scalars["TrimmedNonEmptyString"]["input"];
  lastName: Scalars["TrimmedNonEmptyString"]["input"];
  password: Scalars["NonEmptyString"]["input"];
  phone: Scalars["PhoneNumber"]["input"];
  state: Scalars["TrimmedNonEmptyString"]["input"];
  streetAddress: Scalars["TrimmedNonEmptyString"]["input"];
  zip: Scalars["ZipCode"]["input"];
};

export type UpdateRequestorProfileResponse = MutationResponse & {
  __typename: "UpdateRequestorProfileResponse";
  error: Maybe<GenericMutationError>;
  success: Scalars["Boolean"]["output"];
  /** The successfully updated profile (if the mutation succeeded). */
  updatedProfile: Maybe<Requestor>;
};

export type UpdatedDoc = {
  __typename: "UpdatedDoc";
  label: Scalars["String"]["output"];
  /**
   * Whether the doc was originally ordered for the sub HOA or for the master HOA.
   * This is particularly useful for sub/master orders where a sub doc has the same
   * label as the master doc. In that case, this field allows us to distinguish between
   * those two docs.
   */
  orderedForMasterHoa: Scalars["Boolean"]["output"];
};

export type UsdQnrQuestionResponse = {
  __typename: "UsdQnrQuestionResponse";
  value: Maybe<UsdQnrQuestionResponseValue>;
};

export type UsdQnrQuestionResponseValue = {
  __typename: "UsdQnrQuestionResponseValue";
  formatted: Scalars["String"]["output"];
  raw: Scalars["Float"]["output"];
};

/** A verified address that is in our database. */
export type VerifiedHomeWiseAddress = {
  __typename: "VerifiedHomeWiseAddress";
  hoaUnit: HOAUnit;
};

/**
 * An address that we've verified, but we found that it actually corresponds to
 * a different HOA in our database than the one specified in the verifyAddress
 * resolver input. Returns an array of the matching HOA units in case there are
 * multiple (which can happen for a unit that is in both a sub and master HOA,
 * for example).
 */
export type VerifiedHomeWiseAddressWrongHOA = {
  __typename: "VerifiedHomeWiseAddressWrongHOA";
  matchedAddress: MatchedAddress;
  matchedHoaUnits: Array<HOAUnit>;
};

/**
 * An address that we've verified, but isn't in our database. We return the
 * the cleansed/normalized address fields for the client to use.
 */
export type VerifiedPostalAddress = {
  __typename: "VerifiedPostalAddress";
  matchType: PostalServiceMatchType;
  matchedAddress: MatchedAddress;
};

/**
 * Input data for verifying a US street address. The SmartyStreets docs include
 * the max the length of each input field:
 * https://smartystreets.com/docs/cloud/us-street-api#input-fields
 */
export type VerifyAddressInput = {
  city: Scalars["TrimmedNonEmptyString"]["input"];
  /** UUID of the HOA that the user searched for at the beginning of their order. */
  hoaUUID: Scalars["UUID"]["input"];
  /** The state name or abbreviation */
  state: Scalars["TrimmedNonEmptyString"]["input"];
  streetAddress: Scalars["TrimmedNonEmptyString"]["input"];
  unit?: InputMaybe<Scalars["String"]["input"]>;
  zip: Scalars["ZipCode"]["input"];
};

export type AddedRushOptionsQueryVariables = Exact<{
  orderUUID: Scalars["String"]["input"];
}>;

export type AddedRushOptionsQuery = {
  order:
    | {
        __typename: "CancelledOrder";
        uuid: string;
        estimatedClosingDate: string | null;
        addedRushOptionsInfo: {
          __typename: "AddedRushOptionsInfo";
          isAddedRushAvailable: boolean;
          reasonNotAvailable: string | null;
          addedRushOptions: Array<{
            __typename: "RushOption";
            id: string;
            price: number;
            dueDate: string;
            paymentDue: PaymentDueType;
          }> | null;
        };
      }
    | {
        __typename: "CompletedOrder";
        uuid: string;
        estimatedClosingDate: string | null;
        addedRushOptionsInfo: {
          __typename: "AddedRushOptionsInfo";
          isAddedRushAvailable: boolean;
          reasonNotAvailable: string | null;
          addedRushOptions: Array<{
            __typename: "RushOption";
            id: string;
            price: number;
            dueDate: string;
            paymentDue: PaymentDueType;
          }> | null;
        };
      }
    | {
        __typename: "PendingOrder";
        uuid: string;
        estimatedClosingDate: string | null;
        addedRushOptionsInfo: {
          __typename: "AddedRushOptionsInfo";
          isAddedRushAvailable: boolean;
          reasonNotAvailable: string | null;
          addedRushOptions: Array<{
            __typename: "RushOption";
            id: string;
            price: number;
            dueDate: string;
            paymentDue: PaymentDueType;
          }> | null;
        };
      }
    | {
        __typename: "UnpaidOrder";
        uuid: string;
        estimatedClosingDate: string | null;
        addedRushOptionsInfo: {
          __typename: "AddedRushOptionsInfo";
          isAddedRushAvailable: boolean;
          reasonNotAvailable: string | null;
          addedRushOptions: Array<{
            __typename: "RushOption";
            id: string;
            price: number;
            dueDate: string;
            paymentDue: PaymentDueType;
          }> | null;
        };
      }
    | null;
};

export type CreditCardFeeInfoQueryVariables = Exact<{
  hoaUUID: Scalars["ID"]["input"];
}>;

export type CreditCardFeeInfoQuery = {
  hoa: {
    __typename: "HOA";
    uuid: string;
    creditCardFeeInfo:
      | { __typename: "CreditCardFeeDisabled"; _: string | null }
      | {
          __typename: "FlatCreditCardFee";
          label: string;
          totalFee: number;
          paymentDue: PaymentDueType;
        };
  };
};

export type CreditCardFeeInfoFragment = {
  __typename: "HOA";
  uuid: string;
  creditCardFeeInfo:
    | { __typename: "CreditCardFeeDisabled"; _: string | null }
    | {
        __typename: "FlatCreditCardFee";
        label: string;
        totalFee: number;
        paymentDue: PaymentDueType;
      };
};

export type HoaAndCompanyNameQueryVariables = Exact<{
  hoaUUID: Scalars["ID"]["input"];
}>;

export type HoaAndCompanyNameQuery = {
  hoa: {
    __typename: "HOA";
    uuid: string;
    name: string;
    company: { __typename: "ManagementCompany"; uuid: string; name: string };
  };
};

export type FormUpdateInfoFragment = {
  __typename: "FormUpdateInfo";
  form: {
    __typename: "IndividualItem";
    id: string;
    label: string;
    category: ProductCategoryType;
    isForm: boolean;
    description: string | null;
    paymentDue: PaymentDueType;
    price: number;
    mgmtCompanyFee: number;
    hwdFee: number;
    popupMessage: string | null;
    separateHwdFeeLabel: string | null;
  };
  hoa: { __typename: "HOA"; uuid: string; name: string };
};

export type InfoForUpdateRequestQueryVariables = Exact<{
  orderUUID: Scalars["String"]["input"];
}>;

export type InfoForUpdateRequestQuery = {
  requestorOrg: {
    __typename: "RequestorOrg";
    id: string;
    infoForUpdateRequest:
      | { __typename: "CantUpdateReason"; reason: string }
      | {
          __typename: "InfoForUpdateRequest";
          defaultDueDate: string;
          inspectionDueDate: string;
          formsAvailableForUpdate: Array<{
            __typename: "FormUpdateInfo";
            form: {
              __typename: "IndividualItem";
              id: string;
              label: string;
              category: ProductCategoryType;
              isForm: boolean;
              description: string | null;
              paymentDue: PaymentDueType;
              price: number;
              mgmtCompanyFee: number;
              hwdFee: number;
              popupMessage: string | null;
              separateHwdFeeLabel: string | null;
            };
            hoa: { __typename: "HOA"; uuid: string; name: string };
          }>;
          masterFormsAvailableForUpdate: Array<{
            __typename: "FormUpdateInfo";
            form: {
              __typename: "IndividualItem";
              id: string;
              label: string;
              category: ProductCategoryType;
              isForm: boolean;
              description: string | null;
              paymentDue: PaymentDueType;
              price: number;
              mgmtCompanyFee: number;
              hwdFee: number;
              popupMessage: string | null;
              separateHwdFeeLabel: string | null;
            };
            hoa: { __typename: "HOA"; uuid: string; name: string };
          }>;
          updatedDocs: Array<{
            __typename: "UpdatedDoc";
            label: string;
            orderedForMasterHoa: boolean;
          }>;
        };
  };
};

export type LendersAndLsqFeeQueryVariables = Exact<{
  hoaUUID: Scalars["ID"]["input"];
  developerId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type LendersAndLsqFeeQuery = {
  hoa: {
    __typename: "HOA";
    uuid: string;
    lsqFee: {
      __typename: "LsqFee";
      totalFee: number;
      mgmtCompanyFee: number;
      hwdFee: number;
    };
    lsqCustomUploadFee: {
      __typename: "LsqCustomUploadFee";
      totalFee: number;
      paymentDue: PaymentDueType;
    } | null;
    questionnaires: {
      __typename: "HoaQuestionnaires";
      sampleStdLenderQnrUrl: string;
    };
  };
  lenders: Array<{
    __typename: "Lender";
    id: string;
    name: string;
    emphasize: boolean;
    questionnaires: Array<{
      __typename: "LenderQuestionnaire";
      id: string;
      label: string;
      previewUrl: string;
    }>;
  }>;
};

export type PayByInvoiceQueryVariables = Exact<{ [key: string]: never }>;

export type PayByInvoiceQuery = {
  requestorOrg: {
    __typename: "RequestorOrg";
    id: string;
    payByInvoice: boolean;
  };
};

export type OrderPricingSummaryQueryVariables = Exact<{
  orderPricingSummaryInput: OrderPricingSummaryInput;
}>;

export type OrderPricingSummaryQuery = {
  orderPricingSummary:
    | {
        __typename: "OrderPricingSummary";
        taxDisplayName: string | null;
        total: {
          __typename: "PricingSummaryBreakdown";
          beforeTax: { __typename: "Price"; amount: number; formatted: string };
          tax: { __typename: "Price"; amount: number; formatted: string };
          afterTax: { __typename: "Price"; amount: number; formatted: string };
        };
        upFront: {
          __typename: "PricingSummaryBreakdown";
          beforeTax: { __typename: "Price"; amount: number; formatted: string };
          tax: { __typename: "Price"; amount: number; formatted: string };
          afterTax: { __typename: "Price"; amount: number; formatted: string };
        };
        atClose: {
          __typename: "PricingSummaryBreakdown";
          beforeTax: { __typename: "Price"; amount: number; formatted: string };
          tax: { __typename: "Price"; amount: number; formatted: string };
          afterTax: { __typename: "Price"; amount: number; formatted: string };
        };
        refundPolicyInfo: {
          __typename: "RefundPolicyInfo";
          refundPolicyMessage: GQL_Client_NonEmptyString | null;
          nonRefundableFeesMessage: GQL_Client_NonEmptyString | null;
        };
      }
    | {
        __typename: "OrderPricingSummaryError";
        code: OrderPricingSummaryErrorCode;
        message: string;
      };
};

export type PricingSummaryBreakdownFragment = {
  __typename: "PricingSummaryBreakdown";
  beforeTax: { __typename: "Price"; amount: number; formatted: string };
  tax: { __typename: "Price"; amount: number; formatted: string };
  afterTax: { __typename: "Price"; amount: number; formatted: string };
};

export type PriceFragment = {
  __typename: "Price";
  amount: number;
  formatted: string;
};

export type RefundPolicyInfoFragment = {
  __typename: "RefundPolicyInfo";
  refundPolicyMessage: GQL_Client_NonEmptyString | null;
  nonRefundableFeesMessage: GQL_Client_NonEmptyString | null;
};

export type IndividualItemFragment = {
  __typename: "IndividualItem";
  id: string;
  label: string;
  category: ProductCategoryType;
  isForm: boolean;
  description: string | null;
  paymentDue: PaymentDueType;
  price: number;
  mgmtCompanyFee: number;
  hwdFee: number;
  popupMessage: string | null;
  separateHwdFeeLabel: string | null;
};

export type BundleFragment = {
  __typename: "Bundle";
  id: string;
  label: string;
  description: string | null;
  price: number;
  priceBeforeDiscount: number;
  displaySavings: boolean;
  mgmtCompanyFee: number;
  hwdFee: number;
  paymentDue: PaymentDueType;
  separateHwdFeeLabel: string | null;
  popupMessage: string | null;
  hasMissingDoc: boolean;
  items: Array<{
    __typename: "BundleItem";
    id: string;
    label: string;
    category: ProductCategoryType;
    isForm: boolean;
  }>;
};

export type ProductsAndBundlesQueryVariables = Exact<{
  hoaUUID: Scalars["ID"]["input"];
  transactionType: TransactionType;
  developerId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type ProductsAndBundlesQuery = {
  requestorOrg: {
    __typename: "RequestorOrg";
    id: string;
    payByInvoice: boolean;
  };
  hoa: {
    __typename: "HOA";
    uuid: string;
    name: string;
    availableProducts: {
      __typename: "Products";
      resaleDemandAcknowledgement: string | null;
      individualItems: Array<{
        __typename: "IndividualItem";
        id: string;
        label: string;
        category: ProductCategoryType;
        isForm: boolean;
        description: string | null;
        paymentDue: PaymentDueType;
        price: number;
        mgmtCompanyFee: number;
        hwdFee: number;
        popupMessage: string | null;
        separateHwdFeeLabel: string | null;
      }>;
      bundles: Array<{
        __typename: "Bundle";
        id: string;
        label: string;
        description: string | null;
        price: number;
        priceBeforeDiscount: number;
        displaySavings: boolean;
        mgmtCompanyFee: number;
        hwdFee: number;
        paymentDue: PaymentDueType;
        separateHwdFeeLabel: string | null;
        popupMessage: string | null;
        hasMissingDoc: boolean;
        items: Array<{
          __typename: "BundleItem";
          id: string;
          label: string;
          category: ProductCategoryType;
          isForm: boolean;
        }>;
      }>;
    };
    masterHoas: Array<{
      __typename: "HOA";
      uuid: string;
      name: string;
      availableProducts: {
        __typename: "Products";
        individualItems: Array<{
          __typename: "IndividualItem";
          id: string;
          label: string;
          category: ProductCategoryType;
          isForm: boolean;
          description: string | null;
          paymentDue: PaymentDueType;
          price: number;
          mgmtCompanyFee: number;
          hwdFee: number;
          popupMessage: string | null;
          separateHwdFeeLabel: string | null;
        }>;
        bundles: Array<{
          __typename: "Bundle";
          id: string;
          label: string;
          description: string | null;
          price: number;
          priceBeforeDiscount: number;
          displaySavings: boolean;
          mgmtCompanyFee: number;
          hwdFee: number;
          paymentDue: PaymentDueType;
          separateHwdFeeLabel: string | null;
          popupMessage: string | null;
          hasMissingDoc: boolean;
          items: Array<{
            __typename: "BundleItem";
            id: string;
            label: string;
            category: ProductCategoryType;
            isForm: boolean;
          }>;
        }>;
      };
    }>;
    creditCardFeeInfo:
      | { __typename: "CreditCardFeeDisabled"; _: string | null }
      | {
          __typename: "FlatCreditCardFee";
          label: string;
          totalFee: number;
          paymentDue: PaymentDueType;
        };
  };
};

export type ResaleFormOptionsFragment = {
  __typename: "ResaleFormOption";
  id: string;
  label: string;
  required: boolean;
  isMasterField: boolean;
};

export type ResaleFormOptionsQueryVariables = Exact<{
  hoaUUID: Scalars["ID"]["input"];
  selectedCategories: Array<ProductCategoryType> | ProductCategoryType;
  transactionType: TransactionType;
}>;

export type ResaleFormOptionsQuery = {
  hoa: {
    __typename: "HOA";
    uuid: string;
    transactionInfoFields: {
      __typename: "TransactionInfoFields";
      resaleFormOptions: Array<{
        __typename: "ResaleFormOption";
        id: string;
        label: string;
        required: boolean;
        isMasterField: boolean;
      }>;
    };
  };
};

export type SubAndMasterHoasQueryVariables = Exact<{
  subHOAUUID: Scalars["ID"]["input"];
}>;

export type SubAndMasterHoasQuery = {
  hoa: {
    __typename: "HOA";
    uuid: string;
    masterHoas: Array<{ __typename: "HOA"; uuid: string }>;
  };
};

export type UpFrontFormFeesQueryVariables = Exact<{
  hoaUUID: Scalars["ID"]["input"];
  subHoaInput: HoaFeeInput;
  masterHoasInput: Array<MasterHoaFeeInput> | MasterHoaFeeInput;
}>;

export type UpFrontFormFeesQuery = {
  hoa: {
    __typename: "HOA";
    uuid: string;
    name: string;
    upFrontFormFees: Array<{
      __typename: "Fee";
      id: string;
      label: string;
      price: number;
      paymentDue: PaymentDueType;
    }>;
    masterHoas: Array<{
      __typename: "HOA";
      uuid: string;
      name: string;
      upFrontFormFees: Array<{
        __typename: "Fee";
        id: string;
        label: string;
        price: number;
        paymentDue: PaymentDueType;
      }>;
    }>;
  };
};

export type UpFrontFormFeeFragment = {
  __typename: "Fee";
  id: string;
  label: string;
  price: number;
  paymentDue: PaymentDueType;
};

export type PlacedOrderInfoFragment = {
  __typename: "PlacedOrderInfo";
  hoaUUID: string;
  confirmationNumber: string;
  confirmationEmailSentTo: string;
  totalAmount: number;
  totalPaid: number;
  totalDueAtClose: number;
  completionStatus: OrderCompletionStatus;
  dueDate: string | null;
  presaleDocumentsInfo: Array<{
    __typename: "PresaleDocumentsGroup";
    mgmtCompanyComment: string | null;
    documents: Array<{
      __typename: "PresaleDocument";
      id: string;
      label: string;
      url: string;
    }>;
  }> | null;
};

export type PlaceOrderMutationVariables = Exact<{
  order: PlaceOrderInput;
}>;

export type PlaceOrderMutation = {
  placeOrder: {
    __typename: "PlaceOrderMutationResponse";
    success: boolean;
    error: {
      __typename: "PlaceOrderMutationError";
      message: string;
      code: PlaceOrderErrorCode;
    } | null;
    order: {
      __typename: "PlacedOrderInfo";
      hoaUUID: string;
      confirmationNumber: string;
      confirmationEmailSentTo: string;
      totalAmount: number;
      totalPaid: number;
      totalDueAtClose: number;
      completionStatus: OrderCompletionStatus;
      dueDate: string | null;
      presaleDocumentsInfo: Array<{
        __typename: "PresaleDocumentsGroup";
        mgmtCompanyComment: string | null;
        documents: Array<{
          __typename: "PresaleDocument";
          id: string;
          label: string;
          url: string;
        }>;
      }> | null;
    } | null;
  };
};

export type RushOptionFragment = {
  __typename: "RushOption";
  id: string;
  price: number;
  dueDate: string;
  paymentDue: PaymentDueType;
};

export type ProcessingOptionsAndShippingFeesQueryVariables = Exact<{
  hoaUUID: Scalars["ID"]["input"];
  selectedFormCategories: Array<ProductCategoryType> | ProductCategoryType;
  selectedCategories: Array<ProductCategoryType> | ProductCategoryType;
  developerId?: InputMaybe<Scalars["ID"]["input"]>;
  transactionType: TransactionType;
  missingDoc: Scalars["Boolean"]["input"];
  customQuestionnaire: Scalars["Boolean"]["input"];
}>;

export type ProcessingOptionsAndShippingFeesQuery = {
  hoa: {
    __typename: "HOA";
    uuid: string;
    isPayNowEnabled: boolean;
    processingOptions: {
      __typename: "ProcessingOptions";
      requiresProcessing: boolean;
      expectedExtraFeeForAddedRush: number | null;
      rushOptions: Array<{
        __typename: "RushOption";
        id: string;
        price: number;
        dueDate: string;
        paymentDue: PaymentDueType;
      }> | null;
      standard: { __typename: "StandardProcessingInfo"; dueDate: string };
    };
    company: { __typename: "ManagementCompany"; uuid: string };
    hardCopyShippingInfo: {
      __typename: "HardCopyShippingInfo";
      isAvailable: boolean;
      paymentDue: PaymentDueType;
      fees: {
        __typename: "HardCopyShippingFees";
        standardHardCopyShippingFee: number;
        usbFlashDrive: number;
      };
    };
    messages: {
      __typename: "HoaMessages";
      defaultOrderMessage: string | null;
      specialOrderMessage: string | null;
      questionnaireMessage: string | null;
    } | null;
  };
};

export type RequestPasswordResetEmailMutationVariables = Exact<{
  username: Scalars["String"]["input"];
}>;

export type RequestPasswordResetEmailMutation = {
  requestPasswordResetEmail: {
    __typename: "RequestPasswordResetEmailMutationResponse";
    success: boolean;
    error: { __typename: "GenericMutationError"; message: string } | null;
  };
};

export type RequestForgotUsernameEmailMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type RequestForgotUsernameEmailMutation = {
  requestForgotUsernameEmail: {
    __typename: "RequestForgotUsernameEmailMutationResponse";
    success: boolean;
    error: { __typename: "GenericMutationError"; message: string } | null;
  };
};

export type AnnouncementQueryVariables = Exact<{ [key: string]: never }>;

export type AnnouncementQuery = {
  announcement: {
    __typename: "Announcement";
    id: string;
    message: string;
  } | null;
};

export type UploadLsqMutationVariables = Exact<{
  file: Scalars["Upload"]["input"];
  hoaUUID: Scalars["ID"]["input"];
}>;

export type UploadLsqMutation = {
  uploadOwnLsqTemplate: { __typename: "File"; id: string; filename: string };
};

export type LoginMutationVariables = Exact<{
  username: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  recaptchaToken?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LoginMutation = {
  login: {
    __typename: "LoginMutationResponse";
    success: boolean;
    error: {
      __typename: "LoginMutationError";
      message: string;
      code: LoginMutationErrorCode;
    } | null;
  };
};

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
}>;

export type ChangePasswordMutation = {
  changePassword: {
    __typename: "ChangePasswordMutationResponse";
    success: boolean;
    error: { __typename: "GenericMutationError"; message: string } | null;
  };
};

export type CreateUserMutationVariables = Exact<{
  profile: AddNewUserToRequestorOrgMutationInput;
}>;

export type CreateUserMutation = {
  addNewUserToRequestorOrg: {
    __typename: "AddNewUserToRequestorOrgResponse";
    success: boolean;
    error: { __typename: "GenericMutationError"; message: string } | null;
    newUser: {
      __typename: "Requestor";
      uuid: string;
      firstName: string;
      lastName: string;
      phone: string | null;
      username: string;
      email: string;
      streetAddress: string;
      city: string;
      state: string;
      postalCode: string;
      fullName: string;
    } | null;
  };
};

export type EditProfileQueryVariables = Exact<{ [key: string]: never }>;

export type EditProfileQuery = {
  requestor: {
    __typename: "Requestor";
    uuid: string;
    firstName: string;
    lastName: string;
    phone: string | null;
    username: string;
    email: string;
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
    fullName: string;
  };
};

export type UpdateProfileMutationVariables = Exact<{
  profile: UpdateRequestorProfileMutationInput;
}>;

export type UpdateProfileMutation = {
  updateRequestorProfile: {
    __typename: "UpdateRequestorProfileResponse";
    success: boolean;
    error: { __typename: "GenericMutationError"; message: string } | null;
    updatedProfile: {
      __typename: "Requestor";
      uuid: string;
      firstName: string;
      lastName: string;
      phone: string | null;
      username: string;
      email: string;
      streetAddress: string;
      city: string;
      state: string;
      postalCode: string;
      fullName: string;
    } | null;
  };
};

export type ViewProfileQueryVariables = Exact<{
  uuid: Scalars["String"]["input"];
}>;

export type ViewProfileQuery = {
  requestor: {
    __typename: "Requestor";
    uuid: string;
    firstName: string;
    lastName: string;
    phone: string | null;
    username: string;
    email: string;
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
    fullName: string;
  };
};

export type RequestorFragment = {
  __typename: "Requestor";
  uuid: string;
  firstName: string;
  lastName: string;
  phone: string | null;
  username: string;
  email: string;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  fullName: string;
};

export type UsersListQueryVariables = Exact<{ [key: string]: never }>;

export type UsersListQuery = {
  requestor: {
    __typename: "Requestor";
    uuid: string;
    firstName: string;
    lastName: string;
    phone: string | null;
    username: string;
    email: string;
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
    fullName: string;
    org: {
      __typename: "RequestorOrg";
      id: string;
      requestors: Array<{
        __typename: "Requestor";
        uuid: string;
        firstName: string;
        lastName: string;
        phone: string | null;
        username: string;
        email: string;
        streetAddress: string;
        city: string;
        state: string;
        postalCode: string;
        fullName: string;
      }>;
    };
  };
};

export type DeleteUserMutationVariables = Exact<{
  uuid: Scalars["String"]["input"];
}>;

export type DeleteUserMutation = { _unusedResult: string };

export type MyOrdersQueryVariables = Exact<{
  all: Scalars["Boolean"]["input"];
  pending: Scalars["Boolean"]["input"];
  completed: Scalars["Boolean"]["input"];
  canceled: Scalars["Boolean"]["input"];
  unpaid: Scalars["Boolean"]["input"];
  input: OrdersInput;
  shouldSendQuery: Scalars["Boolean"]["input"];
}>;

export type MyOrdersQuery = {
  requestorOrg?: {
    __typename: "RequestorOrg";
    id: string;
    allOrders?: {
      __typename: "AllOrders";
      totalNumOrders: number;
      orders: Array<
        | {
            __typename: "CancelledOrder";
            uuid: string;
            transactionType: TransactionType;
            confirmationNumber: string;
            orderDate: string;
            estimatedClosingDate: string | null;
            hasRush: boolean;
            totalUpFrontFee: number;
            receiptUrl: string | null;
            cancellationDate: string;
            cancellationReason: CancellationReason | null;
            cancellationComments: string | null;
            currentOwner: {
              __typename: "CurrentOwner";
              fullName: string | null;
              addressInfo: {
                __typename: "CurrentOwnerAddress";
                streetAddress: string;
                unit: string | null;
              };
            };
            hoa: { __typename: "HOA"; uuid: string };
            attachments: Array<{
              __typename: "OrderAttachment";
              id: string;
              label: string;
              url: string;
            }>;
            individualItems: Array<{
              __typename: "OrderItem";
              orderedForMasterHoa: boolean;
              id: string;
              label: string;
              url: string | null;
              canDownload: boolean;
              reReleasedAt: string | null;
              category: ProductCategoryType;
            }>;
            bundles: Array<{
              __typename: "OrderBundle";
              id: string;
              label: string;
              orderedForMasterHoa: boolean;
              items: Array<{
                __typename: "OrderItem";
                id: string;
                label: string;
                url: string | null;
                canDownload: boolean;
                reReleasedAt: string | null;
                category: ProductCategoryType;
              }>;
            }>;
          }
        | {
            __typename: "CompletedOrder";
            uuid: string;
            transactionType: TransactionType;
            confirmationNumber: string;
            orderDate: string;
            estimatedClosingDate: string | null;
            hasRush: boolean;
            totalUpFrontFee: number;
            receiptUrl: string | null;
            completedAt: string;
            downloadAllUrl: string;
            currentOwner: {
              __typename: "CurrentOwner";
              fullName: string | null;
              addressInfo: {
                __typename: "CurrentOwnerAddress";
                streetAddress: string;
                unit: string | null;
              };
            };
            hoa: {
              __typename: "HOA";
              uuid: string;
              creditCardFeeInfo:
                | { __typename: "CreditCardFeeDisabled"; _: string | null }
                | {
                    __typename: "FlatCreditCardFee";
                    label: string;
                    totalFee: number;
                    paymentDue: PaymentDueType;
                  };
            };
            attachments: Array<{
              __typename: "OrderAttachment";
              id: string;
              label: string;
              url: string;
            }>;
            individualItems: Array<{
              __typename: "OrderItem";
              orderedForMasterHoa: boolean;
              id: string;
              label: string;
              url: string | null;
              canDownload: boolean;
              reReleasedAt: string | null;
              category: ProductCategoryType;
            }>;
            bundles: Array<{
              __typename: "OrderBundle";
              id: string;
              label: string;
              orderedForMasterHoa: boolean;
              items: Array<{
                __typename: "OrderItem";
                id: string;
                label: string;
                url: string | null;
                canDownload: boolean;
                reReleasedAt: string | null;
                category: ProductCategoryType;
              }>;
            }>;
            modifyBuyerActionInfo: {
              __typename: "OrderActionInfo";
              isActionAvailable: boolean;
              reasonNotAvailable: string | null;
            };
          }
        | {
            __typename: "PendingOrder";
            uuid: string;
            transactionType: TransactionType;
            confirmationNumber: string;
            orderDate: string;
            estimatedClosingDate: string | null;
            hasRush: boolean;
            totalUpFrontFee: number;
            receiptUrl: string | null;
            dueDate: string;
            isDueDateReliable: boolean;
            processingStatus: PendingOrderProcessingStatus;
            partiallyReleased: boolean;
            downloadAllReleasedUrl: string | null;
            currentOwner: {
              __typename: "CurrentOwner";
              fullName: string | null;
              addressInfo: {
                __typename: "CurrentOwnerAddress";
                streetAddress: string;
                unit: string | null;
              };
            };
            hoa: {
              __typename: "HOA";
              uuid: string;
              creditCardFeeInfo:
                | { __typename: "CreditCardFeeDisabled"; _: string | null }
                | {
                    __typename: "FlatCreditCardFee";
                    label: string;
                    totalFee: number;
                    paymentDue: PaymentDueType;
                  };
            };
            attachments: Array<{
              __typename: "OrderAttachment";
              id: string;
              label: string;
              url: string;
            }>;
            individualItems: Array<{
              __typename: "OrderItem";
              orderedForMasterHoa: boolean;
              id: string;
              label: string;
              url: string | null;
              canDownload: boolean;
              reReleasedAt: string | null;
              category: ProductCategoryType;
            }>;
            bundles: Array<{
              __typename: "OrderBundle";
              id: string;
              label: string;
              orderedForMasterHoa: boolean;
              items: Array<{
                __typename: "OrderItem";
                id: string;
                label: string;
                url: string | null;
                canDownload: boolean;
                reReleasedAt: string | null;
                category: ProductCategoryType;
              }>;
            }>;
            modifyBuyerActionInfo: {
              __typename: "OrderActionInfo";
              isActionAvailable: boolean;
              reasonNotAvailable: string | null;
            };
            modifyOrderActionInfo: {
              __typename: "OrderActionInfo";
              isActionAvailable: boolean;
              reasonNotAvailable: string | null;
            };
            cancelOrderActionInfo: {
              __typename: "OrderActionInfo";
              isActionAvailable: boolean;
              reasonNotAvailable: string | null;
            };
            addRushActionInfo: {
              __typename: "OrderActionInfo";
              isActionAvailable: boolean;
              reasonNotAvailable: string | null;
            };
          }
        | {
            __typename: "UnpaidOrder";
            uuid: string;
            transactionType: TransactionType;
            confirmationNumber: string;
            orderDate: string;
            estimatedClosingDate: string | null;
            hasRush: boolean;
            totalUpFrontFee: number;
            receiptUrl: string | null;
            currentOwner: {
              __typename: "CurrentOwner";
              fullName: string | null;
              addressInfo: {
                __typename: "CurrentOwnerAddress";
                streetAddress: string;
                unit: string | null;
              };
            };
            hoa: {
              __typename: "HOA";
              uuid: string;
              creditCardFeeInfo:
                | { __typename: "CreditCardFeeDisabled"; _: string | null }
                | {
                    __typename: "FlatCreditCardFee";
                    label: string;
                    totalFee: number;
                    paymentDue: PaymentDueType;
                  };
            };
            attachments: Array<{
              __typename: "OrderAttachment";
              id: string;
              label: string;
              url: string;
            }>;
            individualItems: Array<{
              __typename: "OrderItem";
              orderedForMasterHoa: boolean;
              id: string;
              label: string;
              url: string | null;
              canDownload: boolean;
              reReleasedAt: string | null;
              category: ProductCategoryType;
            }>;
            bundles: Array<{
              __typename: "OrderBundle";
              id: string;
              label: string;
              orderedForMasterHoa: boolean;
              items: Array<{
                __typename: "OrderItem";
                id: string;
                label: string;
                url: string | null;
                canDownload: boolean;
                reReleasedAt: string | null;
                category: ProductCategoryType;
              }>;
            }>;
            cancelOrderActionInfo: {
              __typename: "OrderActionInfo";
              isActionAvailable: boolean;
              reasonNotAvailable: string | null;
            };
          }
      >;
    };
    pendingOrders?: {
      __typename: "PendingOrders";
      totalNumOrders: number;
      orders: Array<{
        __typename: "PendingOrder";
        uuid: string;
        transactionType: TransactionType;
        confirmationNumber: string;
        orderDate: string;
        estimatedClosingDate: string | null;
        hasRush: boolean;
        totalUpFrontFee: number;
        receiptUrl: string | null;
        dueDate: string;
        isDueDateReliable: boolean;
        processingStatus: PendingOrderProcessingStatus;
        partiallyReleased: boolean;
        downloadAllReleasedUrl: string | null;
        currentOwner: {
          __typename: "CurrentOwner";
          fullName: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            unit: string | null;
          };
        };
        hoa: {
          __typename: "HOA";
          uuid: string;
          creditCardFeeInfo:
            | { __typename: "CreditCardFeeDisabled"; _: string | null }
            | {
                __typename: "FlatCreditCardFee";
                label: string;
                totalFee: number;
                paymentDue: PaymentDueType;
              };
        };
        attachments: Array<{
          __typename: "OrderAttachment";
          id: string;
          label: string;
          url: string;
        }>;
        individualItems: Array<{
          __typename: "OrderItem";
          orderedForMasterHoa: boolean;
          id: string;
          label: string;
          url: string | null;
          canDownload: boolean;
          reReleasedAt: string | null;
          category: ProductCategoryType;
        }>;
        bundles: Array<{
          __typename: "OrderBundle";
          id: string;
          label: string;
          orderedForMasterHoa: boolean;
          items: Array<{
            __typename: "OrderItem";
            id: string;
            label: string;
            url: string | null;
            canDownload: boolean;
            reReleasedAt: string | null;
            category: ProductCategoryType;
          }>;
        }>;
        modifyBuyerActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
        modifyOrderActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
        cancelOrderActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
        addRushActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
      }>;
    };
    completedOrders?: {
      __typename: "CompletedOrders";
      totalNumOrders: number;
      orders: Array<{
        __typename: "CompletedOrder";
        uuid: string;
        transactionType: TransactionType;
        confirmationNumber: string;
        orderDate: string;
        estimatedClosingDate: string | null;
        hasRush: boolean;
        totalUpFrontFee: number;
        receiptUrl: string | null;
        completedAt: string;
        downloadAllUrl: string;
        currentOwner: {
          __typename: "CurrentOwner";
          fullName: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            unit: string | null;
          };
        };
        hoa: {
          __typename: "HOA";
          uuid: string;
          creditCardFeeInfo:
            | { __typename: "CreditCardFeeDisabled"; _: string | null }
            | {
                __typename: "FlatCreditCardFee";
                label: string;
                totalFee: number;
                paymentDue: PaymentDueType;
              };
        };
        attachments: Array<{
          __typename: "OrderAttachment";
          id: string;
          label: string;
          url: string;
        }>;
        individualItems: Array<{
          __typename: "OrderItem";
          orderedForMasterHoa: boolean;
          id: string;
          label: string;
          url: string | null;
          canDownload: boolean;
          reReleasedAt: string | null;
          category: ProductCategoryType;
        }>;
        bundles: Array<{
          __typename: "OrderBundle";
          id: string;
          label: string;
          orderedForMasterHoa: boolean;
          items: Array<{
            __typename: "OrderItem";
            id: string;
            label: string;
            url: string | null;
            canDownload: boolean;
            reReleasedAt: string | null;
            category: ProductCategoryType;
          }>;
        }>;
        modifyBuyerActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
      }>;
    };
    canceledOrders: {
      __typename: "CancelledOrders";
      totalNumOrders: number;
      orders: Array<{
        __typename: "CancelledOrder";
        uuid: string;
        transactionType: TransactionType;
        confirmationNumber: string;
        orderDate: string;
        estimatedClosingDate: string | null;
        hasRush: boolean;
        totalUpFrontFee: number;
        receiptUrl: string | null;
        cancellationDate: string;
        cancellationReason: CancellationReason | null;
        cancellationComments: string | null;
        currentOwner: {
          __typename: "CurrentOwner";
          fullName: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            unit: string | null;
          };
        };
        hoa: { __typename: "HOA"; uuid: string };
        attachments: Array<{
          __typename: "OrderAttachment";
          id: string;
          label: string;
          url: string;
        }>;
        individualItems: Array<{
          __typename: "OrderItem";
          orderedForMasterHoa: boolean;
          id: string;
          label: string;
          url: string | null;
          canDownload: boolean;
          reReleasedAt: string | null;
          category: ProductCategoryType;
        }>;
        bundles: Array<{
          __typename: "OrderBundle";
          id: string;
          label: string;
          orderedForMasterHoa: boolean;
          items: Array<{
            __typename: "OrderItem";
            id: string;
            label: string;
            url: string | null;
            canDownload: boolean;
            reReleasedAt: string | null;
            category: ProductCategoryType;
          }>;
        }>;
      }>;
    };
    unpaidOrders?: {
      __typename: "UnpaidOrders";
      totalNumOrders: number;
      orders: Array<{
        __typename: "UnpaidOrder";
        uuid: string;
        transactionType: TransactionType;
        confirmationNumber: string;
        orderDate: string;
        estimatedClosingDate: string | null;
        hasRush: boolean;
        totalUpFrontFee: number;
        receiptUrl: string | null;
        currentOwner: {
          __typename: "CurrentOwner";
          fullName: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            unit: string | null;
          };
        };
        hoa: {
          __typename: "HOA";
          uuid: string;
          creditCardFeeInfo:
            | { __typename: "CreditCardFeeDisabled"; _: string | null }
            | {
                __typename: "FlatCreditCardFee";
                label: string;
                totalFee: number;
                paymentDue: PaymentDueType;
              };
        };
        attachments: Array<{
          __typename: "OrderAttachment";
          id: string;
          label: string;
          url: string;
        }>;
        individualItems: Array<{
          __typename: "OrderItem";
          orderedForMasterHoa: boolean;
          id: string;
          label: string;
          url: string | null;
          canDownload: boolean;
          reReleasedAt: string | null;
          category: ProductCategoryType;
        }>;
        bundles: Array<{
          __typename: "OrderBundle";
          id: string;
          label: string;
          orderedForMasterHoa: boolean;
          items: Array<{
            __typename: "OrderItem";
            id: string;
            label: string;
            url: string | null;
            canDownload: boolean;
            reReleasedAt: string | null;
            category: ProductCategoryType;
          }>;
        }>;
        cancelOrderActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
      }>;
    };
  };
};

export type AttachDocumentToOrderMutationVariables = Exact<{
  orderUUID: Scalars["String"]["input"];
  documentType: OrderAttachmentDocumentType;
  file: Scalars["Upload"]["input"];
}>;

export type AttachDocumentToOrderMutation = {
  attachDocumentToOrder: {
    __typename: "AttachDocumentToOrderMutationResponse";
    success: boolean;
    error: { __typename: "GenericMutationError"; message: string } | null;
    attachment: {
      __typename: "OrderAttachment";
      id: string;
      label: string;
      url: string;
    } | null;
  };
};

export type OrderRefundPolicyQueryVariables = Exact<{
  orderUUID: Scalars["String"]["input"];
}>;

export type OrderRefundPolicyQuery = {
  order:
    | { __typename: "CancelledOrder"; uuid: string }
    | { __typename: "CompletedOrder"; uuid: string }
    | {
        __typename: "PendingOrder";
        uuid: string;
        refundPolicyInfo: {
          __typename: "PendingOrderRefundPolicyInfo";
          cancellationRefundPolicyMessage: GQL_Client_NonEmptyString | null;
        };
      }
    | { __typename: "UnpaidOrder"; uuid: string }
    | null;
};

export type CancelOrderMutationVariables = Exact<{
  orderUUID: Scalars["String"]["input"];
  reason: CancellationReason;
  comments: Scalars["String"]["input"];
}>;

export type CancelOrderMutation = {
  cancelOrder: {
    __typename: "CancelOrderMutationResponse";
    success: boolean;
    error: {
      __typename: "CancelOrderMutationError";
      code: CancelOrderErrorCode;
      message: string;
    } | null;
  };
};

export type ModifyBuyerOrderTransactionDetailsQueryVariables = Exact<{
  orderUUID: Scalars["String"]["input"];
}>;

export type ModifyBuyerOrderTransactionDetailsQuery = {
  order:
    | {
        __typename: "CancelledOrder";
        uuid: string;
        transactionType: TransactionType;
        buyerOccupied: boolean | null;
        loanNumber: string | null;
        resaleFormOptionValues: Array<{
          __typename: "ResaleFormOptionValue";
          id: string;
          value: string | null;
          formOption: {
            __typename: "ResaleFormOption";
            id: string;
            label: string;
            required: boolean;
            isMasterField: boolean;
          };
        }> | null;
        currentOwner: {
          __typename: "CurrentOwner";
          firstName: string | null;
          lastName: string | null;
          fullName: string | null;
          email: string | null;
          phone: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            city: string;
            state: string;
            zip: string;
            unit: string | null;
          };
        };
        buyer: {
          __typename: "Buyer";
          firstName: string | null;
          lastName: string | null;
          fullName: string | null;
          email: string | null;
          phone: string | null;
          addressInfo: {
            __typename: "BuyerAddress";
            streetAddress: string | null;
            unit: string | null;
            city: string | null;
            state: string | null;
            zip: string | null;
            country: string | null;
          } | null;
        } | null;
        individualItems: Array<{
          __typename: "OrderItem";
          category: ProductCategoryType;
        }>;
        bundles: Array<{
          __typename: "OrderBundle";
          items: Array<{
            __typename: "OrderItem";
            category: ProductCategoryType;
          }>;
        }>;
      }
    | {
        __typename: "CompletedOrder";
        uuid: string;
        transactionType: TransactionType;
        buyerOccupied: boolean | null;
        loanNumber: string | null;
        modifyBuyerActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
        resaleFormOptionValues: Array<{
          __typename: "ResaleFormOptionValue";
          id: string;
          value: string | null;
          formOption: {
            __typename: "ResaleFormOption";
            id: string;
            label: string;
            required: boolean;
            isMasterField: boolean;
          };
        }> | null;
        currentOwner: {
          __typename: "CurrentOwner";
          firstName: string | null;
          lastName: string | null;
          fullName: string | null;
          email: string | null;
          phone: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            city: string;
            state: string;
            zip: string;
            unit: string | null;
          };
        };
        buyer: {
          __typename: "Buyer";
          firstName: string | null;
          lastName: string | null;
          fullName: string | null;
          email: string | null;
          phone: string | null;
          addressInfo: {
            __typename: "BuyerAddress";
            streetAddress: string | null;
            unit: string | null;
            city: string | null;
            state: string | null;
            zip: string | null;
            country: string | null;
          } | null;
        } | null;
        individualItems: Array<{
          __typename: "OrderItem";
          category: ProductCategoryType;
        }>;
        bundles: Array<{
          __typename: "OrderBundle";
          items: Array<{
            __typename: "OrderItem";
            category: ProductCategoryType;
          }>;
        }>;
      }
    | {
        __typename: "PendingOrder";
        uuid: string;
        transactionType: TransactionType;
        buyerOccupied: boolean | null;
        loanNumber: string | null;
        modifyBuyerActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
        resaleFormOptionValues: Array<{
          __typename: "ResaleFormOptionValue";
          id: string;
          value: string | null;
          formOption: {
            __typename: "ResaleFormOption";
            id: string;
            label: string;
            required: boolean;
            isMasterField: boolean;
          };
        }> | null;
        currentOwner: {
          __typename: "CurrentOwner";
          firstName: string | null;
          lastName: string | null;
          fullName: string | null;
          email: string | null;
          phone: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            city: string;
            state: string;
            zip: string;
            unit: string | null;
          };
        };
        buyer: {
          __typename: "Buyer";
          firstName: string | null;
          lastName: string | null;
          fullName: string | null;
          email: string | null;
          phone: string | null;
          addressInfo: {
            __typename: "BuyerAddress";
            streetAddress: string | null;
            unit: string | null;
            city: string | null;
            state: string | null;
            zip: string | null;
            country: string | null;
          } | null;
        } | null;
        individualItems: Array<{
          __typename: "OrderItem";
          category: ProductCategoryType;
        }>;
        bundles: Array<{
          __typename: "OrderBundle";
          items: Array<{
            __typename: "OrderItem";
            category: ProductCategoryType;
          }>;
        }>;
      }
    | {
        __typename: "UnpaidOrder";
        uuid: string;
        transactionType: TransactionType;
        buyerOccupied: boolean | null;
        loanNumber: string | null;
        resaleFormOptionValues: Array<{
          __typename: "ResaleFormOptionValue";
          id: string;
          value: string | null;
          formOption: {
            __typename: "ResaleFormOption";
            id: string;
            label: string;
            required: boolean;
            isMasterField: boolean;
          };
        }> | null;
        currentOwner: {
          __typename: "CurrentOwner";
          firstName: string | null;
          lastName: string | null;
          fullName: string | null;
          email: string | null;
          phone: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            city: string;
            state: string;
            zip: string;
            unit: string | null;
          };
        };
        buyer: {
          __typename: "Buyer";
          firstName: string | null;
          lastName: string | null;
          fullName: string | null;
          email: string | null;
          phone: string | null;
          addressInfo: {
            __typename: "BuyerAddress";
            streetAddress: string | null;
            unit: string | null;
            city: string | null;
            state: string | null;
            zip: string | null;
            country: string | null;
          } | null;
        } | null;
        individualItems: Array<{
          __typename: "OrderItem";
          category: ProductCategoryType;
        }>;
        bundles: Array<{
          __typename: "OrderBundle";
          items: Array<{
            __typename: "OrderItem";
            category: ProductCategoryType;
          }>;
        }>;
      }
    | null;
};

export type ModifyBuyerMutationVariables = Exact<{
  orderUUID: Scalars["String"]["input"];
  transactionDetails: ModifyBuyerTransactionDetailsInput;
}>;

export type ModifyBuyerMutation = {
  modifyBuyer: {
    __typename: "ModifyBuyerMutationResponse";
    success: boolean;
    error: {
      __typename: "ModifyBuyerMutationError";
      code: ModifyBuyerErrorCode;
      message: string;
    } | null;
    order:
      | {
          __typename: "CancelledOrder";
          uuid: string;
          buyerOccupied: boolean | null;
          loanNumber: string | null;
          buyer: {
            __typename: "Buyer";
            firstName: string | null;
            lastName: string | null;
            fullName: string | null;
            email: string | null;
            phone: string | null;
            addressInfo: {
              __typename: "BuyerAddress";
              streetAddress: string | null;
              unit: string | null;
              city: string | null;
              state: string | null;
              zip: string | null;
              country: string | null;
            } | null;
          } | null;
          resaleFormOptionValues: Array<{
            __typename: "ResaleFormOptionValue";
            id: string;
            value: string | null;
            formOption: {
              __typename: "ResaleFormOption";
              id: string;
              label: string;
              required: boolean;
              isMasterField: boolean;
            };
          }> | null;
        }
      | {
          __typename: "CompletedOrder";
          uuid: string;
          buyerOccupied: boolean | null;
          loanNumber: string | null;
          buyer: {
            __typename: "Buyer";
            firstName: string | null;
            lastName: string | null;
            fullName: string | null;
            email: string | null;
            phone: string | null;
            addressInfo: {
              __typename: "BuyerAddress";
              streetAddress: string | null;
              unit: string | null;
              city: string | null;
              state: string | null;
              zip: string | null;
              country: string | null;
            } | null;
          } | null;
          resaleFormOptionValues: Array<{
            __typename: "ResaleFormOptionValue";
            id: string;
            value: string | null;
            formOption: {
              __typename: "ResaleFormOption";
              id: string;
              label: string;
              required: boolean;
              isMasterField: boolean;
            };
          }> | null;
        }
      | {
          __typename: "PendingOrder";
          uuid: string;
          buyerOccupied: boolean | null;
          loanNumber: string | null;
          buyer: {
            __typename: "Buyer";
            firstName: string | null;
            lastName: string | null;
            fullName: string | null;
            email: string | null;
            phone: string | null;
            addressInfo: {
              __typename: "BuyerAddress";
              streetAddress: string | null;
              unit: string | null;
              city: string | null;
              state: string | null;
              zip: string | null;
              country: string | null;
            } | null;
          } | null;
          resaleFormOptionValues: Array<{
            __typename: "ResaleFormOptionValue";
            id: string;
            value: string | null;
            formOption: {
              __typename: "ResaleFormOption";
              id: string;
              label: string;
              required: boolean;
              isMasterField: boolean;
            };
          }> | null;
        }
      | {
          __typename: "UnpaidOrder";
          uuid: string;
          buyerOccupied: boolean | null;
          loanNumber: string | null;
          buyer: {
            __typename: "Buyer";
            firstName: string | null;
            lastName: string | null;
            fullName: string | null;
            email: string | null;
            phone: string | null;
            addressInfo: {
              __typename: "BuyerAddress";
              streetAddress: string | null;
              unit: string | null;
              city: string | null;
              state: string | null;
              zip: string | null;
              country: string | null;
            } | null;
          } | null;
          resaleFormOptionValues: Array<{
            __typename: "ResaleFormOptionValue";
            id: string;
            value: string | null;
            formOption: {
              __typename: "ResaleFormOption";
              id: string;
              label: string;
              required: boolean;
              isMasterField: boolean;
            };
          }> | null;
        }
      | null;
  };
};

export type ModifyOrderOrderTransactionDetailsQueryVariables = Exact<{
  orderUUID: Scalars["String"]["input"];
}>;

export type ModifyOrderOrderTransactionDetailsQuery = {
  order:
    | {
        __typename: "CancelledOrder";
        uuid: string;
        transactionType: TransactionType;
        buyerOccupied: boolean | null;
        loanNumber: string | null;
        resaleFormOptionValues: Array<{
          __typename: "ResaleFormOptionValue";
          id: string;
          value: string | null;
          formOption: {
            __typename: "ResaleFormOption";
            id: string;
            label: string;
            required: boolean;
            isMasterField: boolean;
          };
        }> | null;
        currentOwner: {
          __typename: "CurrentOwner";
          firstName: string | null;
          lastName: string | null;
          fullName: string | null;
          email: string | null;
          phone: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            city: string;
            state: string;
            zip: string;
            unit: string | null;
          };
        };
        buyer: {
          __typename: "Buyer";
          firstName: string | null;
          lastName: string | null;
          fullName: string | null;
          email: string | null;
          phone: string | null;
          addressInfo: {
            __typename: "BuyerAddress";
            streetAddress: string | null;
            unit: string | null;
            city: string | null;
            state: string | null;
            zip: string | null;
            country: string | null;
          } | null;
        } | null;
        individualItems: Array<{
          __typename: "OrderItem";
          category: ProductCategoryType;
        }>;
        bundles: Array<{
          __typename: "OrderBundle";
          items: Array<{
            __typename: "OrderItem";
            category: ProductCategoryType;
          }>;
        }>;
      }
    | {
        __typename: "CompletedOrder";
        uuid: string;
        transactionType: TransactionType;
        buyerOccupied: boolean | null;
        loanNumber: string | null;
        resaleFormOptionValues: Array<{
          __typename: "ResaleFormOptionValue";
          id: string;
          value: string | null;
          formOption: {
            __typename: "ResaleFormOption";
            id: string;
            label: string;
            required: boolean;
            isMasterField: boolean;
          };
        }> | null;
        currentOwner: {
          __typename: "CurrentOwner";
          firstName: string | null;
          lastName: string | null;
          fullName: string | null;
          email: string | null;
          phone: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            city: string;
            state: string;
            zip: string;
            unit: string | null;
          };
        };
        buyer: {
          __typename: "Buyer";
          firstName: string | null;
          lastName: string | null;
          fullName: string | null;
          email: string | null;
          phone: string | null;
          addressInfo: {
            __typename: "BuyerAddress";
            streetAddress: string | null;
            unit: string | null;
            city: string | null;
            state: string | null;
            zip: string | null;
            country: string | null;
          } | null;
        } | null;
        individualItems: Array<{
          __typename: "OrderItem";
          category: ProductCategoryType;
        }>;
        bundles: Array<{
          __typename: "OrderBundle";
          items: Array<{
            __typename: "OrderItem";
            category: ProductCategoryType;
          }>;
        }>;
      }
    | {
        __typename: "PendingOrder";
        uuid: string;
        transactionType: TransactionType;
        buyerOccupied: boolean | null;
        loanNumber: string | null;
        resaleFormOptionValues: Array<{
          __typename: "ResaleFormOptionValue";
          id: string;
          value: string | null;
          formOption: {
            __typename: "ResaleFormOption";
            id: string;
            label: string;
            required: boolean;
            isMasterField: boolean;
          };
        }> | null;
        currentOwner: {
          __typename: "CurrentOwner";
          firstName: string | null;
          lastName: string | null;
          fullName: string | null;
          email: string | null;
          phone: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            city: string;
            state: string;
            zip: string;
            unit: string | null;
          };
        };
        buyer: {
          __typename: "Buyer";
          firstName: string | null;
          lastName: string | null;
          fullName: string | null;
          email: string | null;
          phone: string | null;
          addressInfo: {
            __typename: "BuyerAddress";
            streetAddress: string | null;
            unit: string | null;
            city: string | null;
            state: string | null;
            zip: string | null;
            country: string | null;
          } | null;
        } | null;
        individualItems: Array<{
          __typename: "OrderItem";
          category: ProductCategoryType;
        }>;
        bundles: Array<{
          __typename: "OrderBundle";
          items: Array<{
            __typename: "OrderItem";
            category: ProductCategoryType;
          }>;
        }>;
      }
    | {
        __typename: "UnpaidOrder";
        uuid: string;
        transactionType: TransactionType;
        buyerOccupied: boolean | null;
        loanNumber: string | null;
        resaleFormOptionValues: Array<{
          __typename: "ResaleFormOptionValue";
          id: string;
          value: string | null;
          formOption: {
            __typename: "ResaleFormOption";
            id: string;
            label: string;
            required: boolean;
            isMasterField: boolean;
          };
        }> | null;
        currentOwner: {
          __typename: "CurrentOwner";
          firstName: string | null;
          lastName: string | null;
          fullName: string | null;
          email: string | null;
          phone: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            city: string;
            state: string;
            zip: string;
            unit: string | null;
          };
        };
        buyer: {
          __typename: "Buyer";
          firstName: string | null;
          lastName: string | null;
          fullName: string | null;
          email: string | null;
          phone: string | null;
          addressInfo: {
            __typename: "BuyerAddress";
            streetAddress: string | null;
            unit: string | null;
            city: string | null;
            state: string | null;
            zip: string | null;
            country: string | null;
          } | null;
        } | null;
        individualItems: Array<{
          __typename: "OrderItem";
          category: ProductCategoryType;
        }>;
        bundles: Array<{
          __typename: "OrderBundle";
          items: Array<{
            __typename: "OrderItem";
            category: ProductCategoryType;
          }>;
        }>;
      }
    | null;
};

export type ModifyOrderMutationVariables = Exact<{
  orderUUID: Scalars["String"]["input"];
  transactionDetails: TransactionDetailsInput;
}>;

export type ModifyOrderMutation = {
  modifyOrder: {
    __typename: "ModifyOrderMutationResponse";
    success: boolean;
    error: {
      __typename: "ModifyOrderMutationError";
      code: ModifyOrderErrorCode;
      message: string;
    } | null;
    order:
      | {
          __typename: "CancelledOrder";
          uuid: string;
          buyerOccupied: boolean | null;
          loanNumber: string | null;
          currentOwner: {
            __typename: "CurrentOwner";
            firstName: string | null;
            lastName: string | null;
            fullName: string | null;
            email: string | null;
            phone: string | null;
            addressInfo: {
              __typename: "CurrentOwnerAddress";
              streetAddress: string;
              city: string;
              state: string;
              zip: string;
              unit: string | null;
            };
          };
          buyer: {
            __typename: "Buyer";
            firstName: string | null;
            lastName: string | null;
            fullName: string | null;
            email: string | null;
            phone: string | null;
            addressInfo: {
              __typename: "BuyerAddress";
              streetAddress: string | null;
              unit: string | null;
              city: string | null;
              state: string | null;
              zip: string | null;
              country: string | null;
            } | null;
          } | null;
          resaleFormOptionValues: Array<{
            __typename: "ResaleFormOptionValue";
            id: string;
            value: string | null;
            formOption: {
              __typename: "ResaleFormOption";
              id: string;
              label: string;
              required: boolean;
              isMasterField: boolean;
            };
          }> | null;
        }
      | {
          __typename: "CompletedOrder";
          uuid: string;
          buyerOccupied: boolean | null;
          loanNumber: string | null;
          currentOwner: {
            __typename: "CurrentOwner";
            firstName: string | null;
            lastName: string | null;
            fullName: string | null;
            email: string | null;
            phone: string | null;
            addressInfo: {
              __typename: "CurrentOwnerAddress";
              streetAddress: string;
              city: string;
              state: string;
              zip: string;
              unit: string | null;
            };
          };
          buyer: {
            __typename: "Buyer";
            firstName: string | null;
            lastName: string | null;
            fullName: string | null;
            email: string | null;
            phone: string | null;
            addressInfo: {
              __typename: "BuyerAddress";
              streetAddress: string | null;
              unit: string | null;
              city: string | null;
              state: string | null;
              zip: string | null;
              country: string | null;
            } | null;
          } | null;
          resaleFormOptionValues: Array<{
            __typename: "ResaleFormOptionValue";
            id: string;
            value: string | null;
            formOption: {
              __typename: "ResaleFormOption";
              id: string;
              label: string;
              required: boolean;
              isMasterField: boolean;
            };
          }> | null;
        }
      | {
          __typename: "PendingOrder";
          uuid: string;
          buyerOccupied: boolean | null;
          loanNumber: string | null;
          currentOwner: {
            __typename: "CurrentOwner";
            firstName: string | null;
            lastName: string | null;
            fullName: string | null;
            email: string | null;
            phone: string | null;
            addressInfo: {
              __typename: "CurrentOwnerAddress";
              streetAddress: string;
              city: string;
              state: string;
              zip: string;
              unit: string | null;
            };
          };
          buyer: {
            __typename: "Buyer";
            firstName: string | null;
            lastName: string | null;
            fullName: string | null;
            email: string | null;
            phone: string | null;
            addressInfo: {
              __typename: "BuyerAddress";
              streetAddress: string | null;
              unit: string | null;
              city: string | null;
              state: string | null;
              zip: string | null;
              country: string | null;
            } | null;
          } | null;
          resaleFormOptionValues: Array<{
            __typename: "ResaleFormOptionValue";
            id: string;
            value: string | null;
            formOption: {
              __typename: "ResaleFormOption";
              id: string;
              label: string;
              required: boolean;
              isMasterField: boolean;
            };
          }> | null;
        }
      | {
          __typename: "UnpaidOrder";
          uuid: string;
          buyerOccupied: boolean | null;
          loanNumber: string | null;
          currentOwner: {
            __typename: "CurrentOwner";
            firstName: string | null;
            lastName: string | null;
            fullName: string | null;
            email: string | null;
            phone: string | null;
            addressInfo: {
              __typename: "CurrentOwnerAddress";
              streetAddress: string;
              city: string;
              state: string;
              zip: string;
              unit: string | null;
            };
          };
          buyer: {
            __typename: "Buyer";
            firstName: string | null;
            lastName: string | null;
            fullName: string | null;
            email: string | null;
            phone: string | null;
            addressInfo: {
              __typename: "BuyerAddress";
              streetAddress: string | null;
              unit: string | null;
              city: string | null;
              state: string | null;
              zip: string | null;
              country: string | null;
            } | null;
          } | null;
          resaleFormOptionValues: Array<{
            __typename: "ResaleFormOptionValue";
            id: string;
            value: string | null;
            formOption: {
              __typename: "ResaleFormOption";
              id: string;
              label: string;
              required: boolean;
              isMasterField: boolean;
            };
          }> | null;
        }
      | null;
  };
};

export type OrderDetailsQueryVariables = Exact<{
  uuid: Scalars["String"]["input"];
  userIsOnOrderStatusPage: Scalars["Boolean"]["input"];
}>;

export type OrderDetailsQuery = {
  order:
    | {
        __typename: "CancelledOrder";
        uuid: string;
        transactionType: TransactionType;
        estimatedClosingDate: string | null;
        confirmationNumber: string;
        orderDate: string;
        paidAt: string | null;
        rushAddedAt: string | null;
        loanNumber?: string | null;
        buyerOccupied?: boolean | null;
        shareWithEmails?: Array<string> | null;
        acknowledgedNotice?: string | null;
        acknowledgedAt?: string | null;
        cancellationDate: string;
        cancellationReason: CancellationReason | null;
        hoa: {
          __typename: "HOA";
          uuid: string;
          name: string;
          company: {
            __typename: "ManagementCompany";
            uuid: string;
            name: string;
          };
        };
        resaleFormOptionValues?: Array<{
          __typename: "ResaleFormOptionValue";
          id: string;
          value: string | null;
          formOption: {
            __typename: "ResaleFormOption";
            id: string;
            label: string;
            required: boolean;
            isMasterField: boolean;
          };
        }> | null;
        currentOwner: {
          __typename: "CurrentOwner";
          fullName: string | null;
          phone?: string | null;
          email?: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            city: string;
            state: string;
            zip: string;
            unit: string | null;
          };
        };
        buyer: {
          __typename: "Buyer";
          fullName: string | null;
          email?: string | null;
          phone?: string | null;
          addressInfo?: {
            __typename: "BuyerAddress";
            streetAddress: string | null;
            unit: string | null;
            city: string | null;
            state: string | null;
            zip: string | null;
            country: string | null;
          } | null;
        } | null;
        requestor: {
          __typename: "Requestor";
          uuid: string;
          fullName: string;
          streetAddress?: string;
          city?: string;
          state?: string;
          postalCode?: string;
          email?: string;
          phone?: string | null;
          org: { __typename: "RequestorOrg"; id: string; name: string };
        };
        shippingInfo: {
          __typename: "ShippingInfo";
          shippingMethodWithLegacySupport: ShippingMethodWithLegacySupport | null;
          shippingCompany: string | null;
          trackingNumber: string | null;
          dateShipped: string | null;
          name?: string;
          streetAddress?: string;
          unit?: string | null;
          city?: string;
          state?: string;
          postalCode?: string;
          country?: string;
        } | null;
        presaleDocumentsInfo: Array<{
          __typename: "PresaleDocumentsGroup";
          mgmtCompanyComment: string | null;
          documents: Array<{
            __typename: "PresaleDocument";
            id: string;
            label: string;
            url: string;
          }>;
        }> | null;
      }
    | {
        __typename: "CompletedOrder";
        uuid: string;
        transactionType: TransactionType;
        estimatedClosingDate: string | null;
        confirmationNumber: string;
        orderDate: string;
        paidAt: string | null;
        rushAddedAt: string | null;
        loanNumber?: string | null;
        buyerOccupied?: boolean | null;
        shareWithEmails?: Array<string> | null;
        acknowledgedNotice?: string | null;
        acknowledgedAt?: string | null;
        completedAt: string;
        downloadAllUrl: string;
        hoa: {
          __typename: "HOA";
          uuid: string;
          name: string;
          company: {
            __typename: "ManagementCompany";
            uuid: string;
            name: string;
          };
          creditCardFeeInfo:
            | { __typename: "CreditCardFeeDisabled"; _: string | null }
            | {
                __typename: "FlatCreditCardFee";
                label: string;
                totalFee: number;
                paymentDue: PaymentDueType;
              };
        };
        resaleFormOptionValues?: Array<{
          __typename: "ResaleFormOptionValue";
          id: string;
          value: string | null;
          formOption: {
            __typename: "ResaleFormOption";
            id: string;
            label: string;
            required: boolean;
            isMasterField: boolean;
          };
        }> | null;
        currentOwner: {
          __typename: "CurrentOwner";
          fullName: string | null;
          phone?: string | null;
          email?: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            city: string;
            state: string;
            zip: string;
            unit: string | null;
          };
        };
        buyer: {
          __typename: "Buyer";
          fullName: string | null;
          email?: string | null;
          phone?: string | null;
          addressInfo?: {
            __typename: "BuyerAddress";
            streetAddress: string | null;
            unit: string | null;
            city: string | null;
            state: string | null;
            zip: string | null;
            country: string | null;
          } | null;
        } | null;
        requestor: {
          __typename: "Requestor";
          uuid: string;
          fullName: string;
          streetAddress?: string;
          city?: string;
          state?: string;
          postalCode?: string;
          email?: string;
          phone?: string | null;
          org: { __typename: "RequestorOrg"; id: string; name: string };
        };
        shippingInfo: {
          __typename: "ShippingInfo";
          shippingMethodWithLegacySupport: ShippingMethodWithLegacySupport | null;
          shippingCompany: string | null;
          trackingNumber: string | null;
          dateShipped: string | null;
          name?: string;
          streetAddress?: string;
          unit?: string | null;
          city?: string;
          state?: string;
          postalCode?: string;
          country?: string;
        } | null;
        presaleDocumentsInfo: Array<{
          __typename: "PresaleDocumentsGroup";
          mgmtCompanyComment: string | null;
          documents: Array<{
            __typename: "PresaleDocument";
            id: string;
            label: string;
            url: string;
          }>;
        }> | null;
        modifyBuyerActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
      }
    | {
        __typename: "PendingOrder";
        uuid: string;
        transactionType: TransactionType;
        estimatedClosingDate: string | null;
        confirmationNumber: string;
        orderDate: string;
        paidAt: string | null;
        rushAddedAt: string | null;
        loanNumber?: string | null;
        buyerOccupied?: boolean | null;
        shareWithEmails?: Array<string> | null;
        acknowledgedNotice?: string | null;
        acknowledgedAt?: string | null;
        dueDate: string;
        isDueDateReliable: boolean;
        processingStatus: PendingOrderProcessingStatus;
        partiallyReleased: boolean;
        downloadAllReleasedUrl: string | null;
        hoa: {
          __typename: "HOA";
          uuid: string;
          name: string;
          company: {
            __typename: "ManagementCompany";
            uuid: string;
            name: string;
          };
          creditCardFeeInfo:
            | { __typename: "CreditCardFeeDisabled"; _: string | null }
            | {
                __typename: "FlatCreditCardFee";
                label: string;
                totalFee: number;
                paymentDue: PaymentDueType;
              };
        };
        resaleFormOptionValues?: Array<{
          __typename: "ResaleFormOptionValue";
          id: string;
          value: string | null;
          formOption: {
            __typename: "ResaleFormOption";
            id: string;
            label: string;
            required: boolean;
            isMasterField: boolean;
          };
        }> | null;
        currentOwner: {
          __typename: "CurrentOwner";
          fullName: string | null;
          phone?: string | null;
          email?: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            city: string;
            state: string;
            zip: string;
            unit: string | null;
          };
        };
        buyer: {
          __typename: "Buyer";
          fullName: string | null;
          email?: string | null;
          phone?: string | null;
          addressInfo?: {
            __typename: "BuyerAddress";
            streetAddress: string | null;
            unit: string | null;
            city: string | null;
            state: string | null;
            zip: string | null;
            country: string | null;
          } | null;
        } | null;
        requestor: {
          __typename: "Requestor";
          uuid: string;
          fullName: string;
          streetAddress?: string;
          city?: string;
          state?: string;
          postalCode?: string;
          email?: string;
          phone?: string | null;
          org: { __typename: "RequestorOrg"; id: string; name: string };
        };
        shippingInfo: {
          __typename: "ShippingInfo";
          shippingMethodWithLegacySupport: ShippingMethodWithLegacySupport | null;
          shippingCompany: string | null;
          trackingNumber: string | null;
          dateShipped: string | null;
          name?: string;
          streetAddress?: string;
          unit?: string | null;
          city?: string;
          state?: string;
          postalCode?: string;
          country?: string;
        } | null;
        presaleDocumentsInfo: Array<{
          __typename: "PresaleDocumentsGroup";
          mgmtCompanyComment: string | null;
          documents: Array<{
            __typename: "PresaleDocument";
            id: string;
            label: string;
            url: string;
          }>;
        }> | null;
        modifyBuyerActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
        modifyOrderActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
        cancelOrderActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
        addRushActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
      }
    | {
        __typename: "UnpaidOrder";
        uuid: string;
        transactionType: TransactionType;
        estimatedClosingDate: string | null;
        confirmationNumber: string;
        orderDate: string;
        paidAt: string | null;
        rushAddedAt: string | null;
        loanNumber?: string | null;
        buyerOccupied?: boolean | null;
        shareWithEmails?: Array<string> | null;
        acknowledgedNotice?: string | null;
        acknowledgedAt?: string | null;
        hoa: {
          __typename: "HOA";
          uuid: string;
          name: string;
          company: {
            __typename: "ManagementCompany";
            uuid: string;
            name: string;
          };
        };
        resaleFormOptionValues?: Array<{
          __typename: "ResaleFormOptionValue";
          id: string;
          value: string | null;
          formOption: {
            __typename: "ResaleFormOption";
            id: string;
            label: string;
            required: boolean;
            isMasterField: boolean;
          };
        }> | null;
        currentOwner: {
          __typename: "CurrentOwner";
          fullName: string | null;
          phone?: string | null;
          email?: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            city: string;
            state: string;
            zip: string;
            unit: string | null;
          };
        };
        buyer: {
          __typename: "Buyer";
          fullName: string | null;
          email?: string | null;
          phone?: string | null;
          addressInfo?: {
            __typename: "BuyerAddress";
            streetAddress: string | null;
            unit: string | null;
            city: string | null;
            state: string | null;
            zip: string | null;
            country: string | null;
          } | null;
        } | null;
        requestor: {
          __typename: "Requestor";
          uuid: string;
          fullName: string;
          streetAddress?: string;
          city?: string;
          state?: string;
          postalCode?: string;
          email?: string;
          phone?: string | null;
          org: { __typename: "RequestorOrg"; id: string; name: string };
        };
        shippingInfo: {
          __typename: "ShippingInfo";
          shippingMethodWithLegacySupport: ShippingMethodWithLegacySupport | null;
          shippingCompany: string | null;
          trackingNumber: string | null;
          dateShipped: string | null;
          name?: string;
          streetAddress?: string;
          unit?: string | null;
          city?: string;
          state?: string;
          postalCode?: string;
          country?: string;
        } | null;
        presaleDocumentsInfo: Array<{
          __typename: "PresaleDocumentsGroup";
          mgmtCompanyComment: string | null;
          documents: Array<{
            __typename: "PresaleDocument";
            id: string;
            label: string;
            url: string;
          }>;
        }> | null;
      }
    | null;
};

type OrderTransactionDetails_CancelledOrder_ = {
  __typename: "CancelledOrder";
  uuid: string;
  transactionType: TransactionType;
  buyerOccupied: boolean | null;
  loanNumber: string | null;
  resaleFormOptionValues: Array<{
    __typename: "ResaleFormOptionValue";
    id: string;
    value: string | null;
    formOption: {
      __typename: "ResaleFormOption";
      id: string;
      label: string;
      required: boolean;
      isMasterField: boolean;
    };
  }> | null;
  currentOwner: {
    __typename: "CurrentOwner";
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    email: string | null;
    phone: string | null;
    addressInfo: {
      __typename: "CurrentOwnerAddress";
      streetAddress: string;
      city: string;
      state: string;
      zip: string;
      unit: string | null;
    };
  };
  buyer: {
    __typename: "Buyer";
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    email: string | null;
    phone: string | null;
    addressInfo: {
      __typename: "BuyerAddress";
      streetAddress: string | null;
      unit: string | null;
      city: string | null;
      state: string | null;
      zip: string | null;
      country: string | null;
    } | null;
  } | null;
  individualItems: Array<{
    __typename: "OrderItem";
    category: ProductCategoryType;
  }>;
  bundles: Array<{
    __typename: "OrderBundle";
    items: Array<{ __typename: "OrderItem"; category: ProductCategoryType }>;
  }>;
};

type OrderTransactionDetails_CompletedOrder_ = {
  __typename: "CompletedOrder";
  uuid: string;
  transactionType: TransactionType;
  buyerOccupied: boolean | null;
  loanNumber: string | null;
  resaleFormOptionValues: Array<{
    __typename: "ResaleFormOptionValue";
    id: string;
    value: string | null;
    formOption: {
      __typename: "ResaleFormOption";
      id: string;
      label: string;
      required: boolean;
      isMasterField: boolean;
    };
  }> | null;
  currentOwner: {
    __typename: "CurrentOwner";
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    email: string | null;
    phone: string | null;
    addressInfo: {
      __typename: "CurrentOwnerAddress";
      streetAddress: string;
      city: string;
      state: string;
      zip: string;
      unit: string | null;
    };
  };
  buyer: {
    __typename: "Buyer";
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    email: string | null;
    phone: string | null;
    addressInfo: {
      __typename: "BuyerAddress";
      streetAddress: string | null;
      unit: string | null;
      city: string | null;
      state: string | null;
      zip: string | null;
      country: string | null;
    } | null;
  } | null;
  individualItems: Array<{
    __typename: "OrderItem";
    category: ProductCategoryType;
  }>;
  bundles: Array<{
    __typename: "OrderBundle";
    items: Array<{ __typename: "OrderItem"; category: ProductCategoryType }>;
  }>;
};

type OrderTransactionDetails_PendingOrder_ = {
  __typename: "PendingOrder";
  uuid: string;
  transactionType: TransactionType;
  buyerOccupied: boolean | null;
  loanNumber: string | null;
  resaleFormOptionValues: Array<{
    __typename: "ResaleFormOptionValue";
    id: string;
    value: string | null;
    formOption: {
      __typename: "ResaleFormOption";
      id: string;
      label: string;
      required: boolean;
      isMasterField: boolean;
    };
  }> | null;
  currentOwner: {
    __typename: "CurrentOwner";
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    email: string | null;
    phone: string | null;
    addressInfo: {
      __typename: "CurrentOwnerAddress";
      streetAddress: string;
      city: string;
      state: string;
      zip: string;
      unit: string | null;
    };
  };
  buyer: {
    __typename: "Buyer";
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    email: string | null;
    phone: string | null;
    addressInfo: {
      __typename: "BuyerAddress";
      streetAddress: string | null;
      unit: string | null;
      city: string | null;
      state: string | null;
      zip: string | null;
      country: string | null;
    } | null;
  } | null;
  individualItems: Array<{
    __typename: "OrderItem";
    category: ProductCategoryType;
  }>;
  bundles: Array<{
    __typename: "OrderBundle";
    items: Array<{ __typename: "OrderItem"; category: ProductCategoryType }>;
  }>;
};

type OrderTransactionDetails_UnpaidOrder_ = {
  __typename: "UnpaidOrder";
  uuid: string;
  transactionType: TransactionType;
  buyerOccupied: boolean | null;
  loanNumber: string | null;
  resaleFormOptionValues: Array<{
    __typename: "ResaleFormOptionValue";
    id: string;
    value: string | null;
    formOption: {
      __typename: "ResaleFormOption";
      id: string;
      label: string;
      required: boolean;
      isMasterField: boolean;
    };
  }> | null;
  currentOwner: {
    __typename: "CurrentOwner";
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    email: string | null;
    phone: string | null;
    addressInfo: {
      __typename: "CurrentOwnerAddress";
      streetAddress: string;
      city: string;
      state: string;
      zip: string;
      unit: string | null;
    };
  };
  buyer: {
    __typename: "Buyer";
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    email: string | null;
    phone: string | null;
    addressInfo: {
      __typename: "BuyerAddress";
      streetAddress: string | null;
      unit: string | null;
      city: string | null;
      state: string | null;
      zip: string | null;
      country: string | null;
    } | null;
  } | null;
  individualItems: Array<{
    __typename: "OrderItem";
    category: ProductCategoryType;
  }>;
  bundles: Array<{
    __typename: "OrderBundle";
    items: Array<{ __typename: "OrderItem"; category: ProductCategoryType }>;
  }>;
};

export type OrderTransactionDetails =
  | OrderTransactionDetails_CancelledOrder_
  | OrderTransactionDetails_CompletedOrder_
  | OrderTransactionDetails_PendingOrder_
  | OrderTransactionDetails_UnpaidOrder_;

export type PresaleDocumentsInfoFragment = {
  __typename: "PresaleDocumentsGroup";
  mgmtCompanyComment: string | null;
  documents: Array<{
    __typename: "PresaleDocument";
    id: string;
    label: string;
    url: string;
  }>;
};

export type OrderItemFragment = {
  __typename: "OrderItem";
  id: string;
  label: string;
  url: string | null;
  canDownload: boolean;
  reReleasedAt: string | null;
  category: ProductCategoryType;
};

type BaseOrderFragment_CancelledOrder_ = {
  __typename: "CancelledOrder";
  uuid: string;
  transactionType: TransactionType;
  confirmationNumber: string;
  orderDate: string;
  estimatedClosingDate: string | null;
  hasRush: boolean;
  totalUpFrontFee: number;
  receiptUrl: string | null;
  currentOwner: {
    __typename: "CurrentOwner";
    fullName: string | null;
    addressInfo: {
      __typename: "CurrentOwnerAddress";
      streetAddress: string;
      unit: string | null;
    };
  };
  hoa: { __typename: "HOA"; uuid: string };
  attachments: Array<{
    __typename: "OrderAttachment";
    id: string;
    label: string;
    url: string;
  }>;
  individualItems: Array<{
    __typename: "OrderItem";
    orderedForMasterHoa: boolean;
    id: string;
    label: string;
    url: string | null;
    canDownload: boolean;
    reReleasedAt: string | null;
    category: ProductCategoryType;
  }>;
  bundles: Array<{
    __typename: "OrderBundle";
    id: string;
    label: string;
    orderedForMasterHoa: boolean;
    items: Array<{
      __typename: "OrderItem";
      id: string;
      label: string;
      url: string | null;
      canDownload: boolean;
      reReleasedAt: string | null;
      category: ProductCategoryType;
    }>;
  }>;
};

type BaseOrderFragment_CompletedOrder_ = {
  __typename: "CompletedOrder";
  uuid: string;
  transactionType: TransactionType;
  confirmationNumber: string;
  orderDate: string;
  estimatedClosingDate: string | null;
  hasRush: boolean;
  totalUpFrontFee: number;
  receiptUrl: string | null;
  currentOwner: {
    __typename: "CurrentOwner";
    fullName: string | null;
    addressInfo: {
      __typename: "CurrentOwnerAddress";
      streetAddress: string;
      unit: string | null;
    };
  };
  hoa: { __typename: "HOA"; uuid: string };
  attachments: Array<{
    __typename: "OrderAttachment";
    id: string;
    label: string;
    url: string;
  }>;
  individualItems: Array<{
    __typename: "OrderItem";
    orderedForMasterHoa: boolean;
    id: string;
    label: string;
    url: string | null;
    canDownload: boolean;
    reReleasedAt: string | null;
    category: ProductCategoryType;
  }>;
  bundles: Array<{
    __typename: "OrderBundle";
    id: string;
    label: string;
    orderedForMasterHoa: boolean;
    items: Array<{
      __typename: "OrderItem";
      id: string;
      label: string;
      url: string | null;
      canDownload: boolean;
      reReleasedAt: string | null;
      category: ProductCategoryType;
    }>;
  }>;
};

type BaseOrderFragment_PendingOrder_ = {
  __typename: "PendingOrder";
  uuid: string;
  transactionType: TransactionType;
  confirmationNumber: string;
  orderDate: string;
  estimatedClosingDate: string | null;
  hasRush: boolean;
  totalUpFrontFee: number;
  receiptUrl: string | null;
  currentOwner: {
    __typename: "CurrentOwner";
    fullName: string | null;
    addressInfo: {
      __typename: "CurrentOwnerAddress";
      streetAddress: string;
      unit: string | null;
    };
  };
  hoa: { __typename: "HOA"; uuid: string };
  attachments: Array<{
    __typename: "OrderAttachment";
    id: string;
    label: string;
    url: string;
  }>;
  individualItems: Array<{
    __typename: "OrderItem";
    orderedForMasterHoa: boolean;
    id: string;
    label: string;
    url: string | null;
    canDownload: boolean;
    reReleasedAt: string | null;
    category: ProductCategoryType;
  }>;
  bundles: Array<{
    __typename: "OrderBundle";
    id: string;
    label: string;
    orderedForMasterHoa: boolean;
    items: Array<{
      __typename: "OrderItem";
      id: string;
      label: string;
      url: string | null;
      canDownload: boolean;
      reReleasedAt: string | null;
      category: ProductCategoryType;
    }>;
  }>;
};

type BaseOrderFragment_UnpaidOrder_ = {
  __typename: "UnpaidOrder";
  uuid: string;
  transactionType: TransactionType;
  confirmationNumber: string;
  orderDate: string;
  estimatedClosingDate: string | null;
  hasRush: boolean;
  totalUpFrontFee: number;
  receiptUrl: string | null;
  currentOwner: {
    __typename: "CurrentOwner";
    fullName: string | null;
    addressInfo: {
      __typename: "CurrentOwnerAddress";
      streetAddress: string;
      unit: string | null;
    };
  };
  hoa: { __typename: "HOA"; uuid: string };
  attachments: Array<{
    __typename: "OrderAttachment";
    id: string;
    label: string;
    url: string;
  }>;
  individualItems: Array<{
    __typename: "OrderItem";
    orderedForMasterHoa: boolean;
    id: string;
    label: string;
    url: string | null;
    canDownload: boolean;
    reReleasedAt: string | null;
    category: ProductCategoryType;
  }>;
  bundles: Array<{
    __typename: "OrderBundle";
    id: string;
    label: string;
    orderedForMasterHoa: boolean;
    items: Array<{
      __typename: "OrderItem";
      id: string;
      label: string;
      url: string | null;
      canDownload: boolean;
      reReleasedAt: string | null;
      category: ProductCategoryType;
    }>;
  }>;
};

export type BaseOrderFragment =
  | BaseOrderFragment_CancelledOrder_
  | BaseOrderFragment_CompletedOrder_
  | BaseOrderFragment_PendingOrder_
  | BaseOrderFragment_UnpaidOrder_;

export type PendingOrderFragment = {
  __typename: "PendingOrder";
  dueDate: string;
  isDueDateReliable: boolean;
  processingStatus: PendingOrderProcessingStatus;
  partiallyReleased: boolean;
  downloadAllReleasedUrl: string | null;
  hoa: {
    __typename: "HOA";
    uuid: string;
    creditCardFeeInfo:
      | { __typename: "CreditCardFeeDisabled"; _: string | null }
      | {
          __typename: "FlatCreditCardFee";
          label: string;
          totalFee: number;
          paymentDue: PaymentDueType;
        };
  };
  modifyBuyerActionInfo: {
    __typename: "OrderActionInfo";
    isActionAvailable: boolean;
    reasonNotAvailable: string | null;
  };
  modifyOrderActionInfo: {
    __typename: "OrderActionInfo";
    isActionAvailable: boolean;
    reasonNotAvailable: string | null;
  };
  cancelOrderActionInfo: {
    __typename: "OrderActionInfo";
    isActionAvailable: boolean;
    reasonNotAvailable: string | null;
  };
  addRushActionInfo: {
    __typename: "OrderActionInfo";
    isActionAvailable: boolean;
    reasonNotAvailable: string | null;
  };
};

export type CompletedOrderFragment = {
  __typename: "CompletedOrder";
  completedAt: string;
  downloadAllUrl: string;
  hoa: {
    __typename: "HOA";
    uuid: string;
    creditCardFeeInfo:
      | { __typename: "CreditCardFeeDisabled"; _: string | null }
      | {
          __typename: "FlatCreditCardFee";
          label: string;
          totalFee: number;
          paymentDue: PaymentDueType;
        };
  };
  modifyBuyerActionInfo: {
    __typename: "OrderActionInfo";
    isActionAvailable: boolean;
    reasonNotAvailable: string | null;
  };
};

export type CanceledOrderFragment = {
  __typename: "CancelledOrder";
  cancellationDate: string;
  cancellationReason: CancellationReason | null;
  cancellationComments: string | null;
};

export type CanceledOrderSansCommentsFragment = {
  __typename: "CancelledOrder";
  cancellationDate: string;
  cancellationReason: CancellationReason | null;
};

export type UnpaidOrderFragment = {
  __typename: "UnpaidOrder";
  hoa: {
    __typename: "HOA";
    uuid: string;
    creditCardFeeInfo:
      | { __typename: "CreditCardFeeDisabled"; _: string | null }
      | {
          __typename: "FlatCreditCardFee";
          label: string;
          totalFee: number;
          paymentDue: PaymentDueType;
        };
  };
  cancelOrderActionInfo: {
    __typename: "OrderActionInfo";
    isActionAvailable: boolean;
    reasonNotAvailable: string | null;
  };
};

export type CurrentOwnerFragment = {
  __typename: "CurrentOwner";
  firstName: string | null;
  lastName: string | null;
  fullName: string | null;
  email: string | null;
  phone: string | null;
  addressInfo: {
    __typename: "CurrentOwnerAddress";
    streetAddress: string;
    city: string;
    state: string;
    zip: string;
    unit: string | null;
  };
};

export type ResaleFormOptionValuesFragment = {
  __typename: "ResaleFormOptionValue";
  id: string;
  value: string | null;
  formOption: {
    __typename: "ResaleFormOption";
    id: string;
    label: string;
    required: boolean;
    isMasterField: boolean;
  };
};

export type BuyerFragment = {
  __typename: "Buyer";
  firstName: string | null;
  lastName: string | null;
  fullName: string | null;
  email: string | null;
  phone: string | null;
  addressInfo: {
    __typename: "BuyerAddress";
    streetAddress: string | null;
    unit: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    country: string | null;
  } | null;
};

export type OrderStatusQueryVariables = Exact<{
  confirmationNumber: Scalars["String"]["input"];
}>;

export type OrderStatusQuery = {
  order:
    | {
        __typename: "CancelledOrder";
        uuid: string;
        transactionType: TransactionType;
        confirmationNumber: string;
        orderDate: string;
        estimatedClosingDate: string | null;
        hasRush: boolean;
        totalUpFrontFee: number;
        receiptUrl: string | null;
        cancellationDate: string;
        cancellationReason: CancellationReason | null;
        requestor: {
          __typename: "Requestor";
          uuid: string;
          org: { __typename: "RequestorOrg"; id: string };
        };
        currentOwner: {
          __typename: "CurrentOwner";
          fullName: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            unit: string | null;
          };
        };
        hoa: { __typename: "HOA"; uuid: string };
        attachments: Array<{
          __typename: "OrderAttachment";
          id: string;
          label: string;
          url: string;
        }>;
        individualItems: Array<{
          __typename: "OrderItem";
          orderedForMasterHoa: boolean;
          id: string;
          label: string;
          url: string | null;
          canDownload: boolean;
          reReleasedAt: string | null;
          category: ProductCategoryType;
        }>;
        bundles: Array<{
          __typename: "OrderBundle";
          id: string;
          label: string;
          orderedForMasterHoa: boolean;
          items: Array<{
            __typename: "OrderItem";
            id: string;
            label: string;
            url: string | null;
            canDownload: boolean;
            reReleasedAt: string | null;
            category: ProductCategoryType;
          }>;
        }>;
      }
    | {
        __typename: "CompletedOrder";
        uuid: string;
        transactionType: TransactionType;
        confirmationNumber: string;
        orderDate: string;
        estimatedClosingDate: string | null;
        hasRush: boolean;
        totalUpFrontFee: number;
        receiptUrl: string | null;
        completedAt: string;
        downloadAllUrl: string;
        requestor: {
          __typename: "Requestor";
          uuid: string;
          org: { __typename: "RequestorOrg"; id: string };
        };
        currentOwner: {
          __typename: "CurrentOwner";
          fullName: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            unit: string | null;
          };
        };
        hoa: {
          __typename: "HOA";
          uuid: string;
          creditCardFeeInfo:
            | { __typename: "CreditCardFeeDisabled"; _: string | null }
            | {
                __typename: "FlatCreditCardFee";
                label: string;
                totalFee: number;
                paymentDue: PaymentDueType;
              };
        };
        attachments: Array<{
          __typename: "OrderAttachment";
          id: string;
          label: string;
          url: string;
        }>;
        individualItems: Array<{
          __typename: "OrderItem";
          orderedForMasterHoa: boolean;
          id: string;
          label: string;
          url: string | null;
          canDownload: boolean;
          reReleasedAt: string | null;
          category: ProductCategoryType;
        }>;
        bundles: Array<{
          __typename: "OrderBundle";
          id: string;
          label: string;
          orderedForMasterHoa: boolean;
          items: Array<{
            __typename: "OrderItem";
            id: string;
            label: string;
            url: string | null;
            canDownload: boolean;
            reReleasedAt: string | null;
            category: ProductCategoryType;
          }>;
        }>;
        modifyBuyerActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
      }
    | {
        __typename: "PendingOrder";
        uuid: string;
        transactionType: TransactionType;
        confirmationNumber: string;
        orderDate: string;
        estimatedClosingDate: string | null;
        hasRush: boolean;
        totalUpFrontFee: number;
        receiptUrl: string | null;
        dueDate: string;
        isDueDateReliable: boolean;
        processingStatus: PendingOrderProcessingStatus;
        partiallyReleased: boolean;
        downloadAllReleasedUrl: string | null;
        requestor: {
          __typename: "Requestor";
          uuid: string;
          org: { __typename: "RequestorOrg"; id: string };
        };
        currentOwner: {
          __typename: "CurrentOwner";
          fullName: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            unit: string | null;
          };
        };
        hoa: {
          __typename: "HOA";
          uuid: string;
          creditCardFeeInfo:
            | { __typename: "CreditCardFeeDisabled"; _: string | null }
            | {
                __typename: "FlatCreditCardFee";
                label: string;
                totalFee: number;
                paymentDue: PaymentDueType;
              };
        };
        attachments: Array<{
          __typename: "OrderAttachment";
          id: string;
          label: string;
          url: string;
        }>;
        individualItems: Array<{
          __typename: "OrderItem";
          orderedForMasterHoa: boolean;
          id: string;
          label: string;
          url: string | null;
          canDownload: boolean;
          reReleasedAt: string | null;
          category: ProductCategoryType;
        }>;
        bundles: Array<{
          __typename: "OrderBundle";
          id: string;
          label: string;
          orderedForMasterHoa: boolean;
          items: Array<{
            __typename: "OrderItem";
            id: string;
            label: string;
            url: string | null;
            canDownload: boolean;
            reReleasedAt: string | null;
            category: ProductCategoryType;
          }>;
        }>;
        modifyBuyerActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
        modifyOrderActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
        cancelOrderActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
        addRushActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
      }
    | {
        __typename: "UnpaidOrder";
        uuid: string;
        transactionType: TransactionType;
        confirmationNumber: string;
        orderDate: string;
        estimatedClosingDate: string | null;
        hasRush: boolean;
        totalUpFrontFee: number;
        receiptUrl: string | null;
        requestor: {
          __typename: "Requestor";
          uuid: string;
          org: { __typename: "RequestorOrg"; id: string };
        };
        currentOwner: {
          __typename: "CurrentOwner";
          fullName: string | null;
          addressInfo: {
            __typename: "CurrentOwnerAddress";
            streetAddress: string;
            unit: string | null;
          };
        };
        hoa: {
          __typename: "HOA";
          uuid: string;
          creditCardFeeInfo:
            | { __typename: "CreditCardFeeDisabled"; _: string | null }
            | {
                __typename: "FlatCreditCardFee";
                label: string;
                totalFee: number;
                paymentDue: PaymentDueType;
              };
        };
        attachments: Array<{
          __typename: "OrderAttachment";
          id: string;
          label: string;
          url: string;
        }>;
        individualItems: Array<{
          __typename: "OrderItem";
          orderedForMasterHoa: boolean;
          id: string;
          label: string;
          url: string | null;
          canDownload: boolean;
          reReleasedAt: string | null;
          category: ProductCategoryType;
        }>;
        bundles: Array<{
          __typename: "OrderBundle";
          id: string;
          label: string;
          orderedForMasterHoa: boolean;
          items: Array<{
            __typename: "OrderItem";
            id: string;
            label: string;
            url: string | null;
            canDownload: boolean;
            reReleasedAt: string | null;
            category: ProductCategoryType;
          }>;
        }>;
        cancelOrderActionInfo: {
          __typename: "OrderActionInfo";
          isActionAvailable: boolean;
          reasonNotAvailable: string | null;
        };
      }
    | null;
};

export type AddRushPricingSummaryQueryVariables = Exact<{
  orderUUID: Scalars["ID"]["input"];
  rushId: Scalars["ID"]["input"];
}>;

export type AddRushPricingSummaryQuery = {
  addRushPricingSummary:
    | {
        __typename: "AddRushPricingSummary";
        taxDisplayName: string | null;
        paymentDue: PaymentDueType;
        rush: {
          __typename: "PricingSummaryBreakdown";
          beforeTax: { __typename: "Price"; amount: number; formatted: string };
          tax: { __typename: "Price"; amount: number; formatted: string };
          afterTax: { __typename: "Price"; amount: number; formatted: string };
        };
        creditCardFee: {
          __typename: "Price";
          amount: number;
          formatted: string;
        } | null;
        total: {
          __typename: "PricingSummaryBreakdown";
          beforeTax: { __typename: "Price"; amount: number; formatted: string };
          tax: { __typename: "Price"; amount: number; formatted: string };
          afterTax: { __typename: "Price"; amount: number; formatted: string };
        };
        refundPolicyInfo: {
          __typename: "RefundPolicyInfo";
          refundPolicyMessage: GQL_Client_NonEmptyString | null;
          nonRefundableFeesMessage: GQL_Client_NonEmptyString | null;
        };
      }
    | {
        __typename: "AddRushPricingSummaryError";
        code: AddRushPricingSummaryErrorCode;
        message: string;
      };
};

export type AddRushMutationVariables = Exact<{
  input: AddRushInput;
}>;

export type AddRushMutation = {
  addRush: {
    __typename: "AddRushMutationResponse";
    success: boolean;
    error: {
      __typename: "AddRushMutationError";
      code: AddRushErrorCode;
      message: string;
    } | null;
    updatedOrderData: {
      __typename: "AddRushMutationData";
      orderUUID: string;
      dueDate: string;
      rushAddedAt: string;
      totalUpFrontFee: number;
      totalAtCloseFee: number;
      totalPrice: number;
      totalPaid: number;
      paymentMethod: PaymentMethod;
      paidAt: string;
      prettyDueDate: string;
    } | null;
  };
};

export type AddedRushOrderData = {
  __typename: "PendingOrder";
  dueDate: string;
  hasRush: boolean;
  rushAddedAt: string | null;
  totalUpFrontFee: number;
  totalAtCloseFee: number;
  totalPrice: number;
  totalPaid: number;
  paymentMethod: PaymentMethod;
  paidAt: string | null;
};

export type OrderUpdatePricingSummaryQueryVariables = Exact<{
  input: RequestOrderUpdatePricingSummaryInput;
}>;

export type OrderUpdatePricingSummaryQuery = {
  requestOrderUpdatePricingSummary:
    | {
        __typename: "OrderPricingSummary";
        taxDisplayName: string | null;
        total: {
          __typename: "PricingSummaryBreakdown";
          beforeTax: { __typename: "Price"; amount: number; formatted: string };
          tax: { __typename: "Price"; amount: number; formatted: string };
          afterTax: { __typename: "Price"; amount: number; formatted: string };
        };
        upFront: {
          __typename: "PricingSummaryBreakdown";
          beforeTax: { __typename: "Price"; amount: number; formatted: string };
          tax: { __typename: "Price"; amount: number; formatted: string };
          afterTax: { __typename: "Price"; amount: number; formatted: string };
        };
        atClose: {
          __typename: "PricingSummaryBreakdown";
          beforeTax: { __typename: "Price"; amount: number; formatted: string };
          tax: { __typename: "Price"; amount: number; formatted: string };
          afterTax: { __typename: "Price"; amount: number; formatted: string };
        };
        refundPolicyInfo: {
          __typename: "RefundPolicyInfo";
          refundPolicyMessage: GQL_Client_NonEmptyString | null;
          nonRefundableFeesMessage: GQL_Client_NonEmptyString | null;
        };
      }
    | {
        __typename: "RequestOrderUpdatePricingSummaryError";
        code: RequestOrderUpdateErrorCode;
        message: string;
      };
};

export type RequestOrderUpdateMutationVariables = Exact<{
  input: RequestOrderUpdateInput;
}>;

export type RequestOrderUpdateMutation = {
  requestOrderUpdate: {
    __typename: "RequestOrderUpdateMutationResponse";
    success: boolean;
    error: {
      __typename: "RequestOrderUpdateMutationError";
      code: RequestOrderUpdateErrorCode;
      message: string;
    } | null;
    order: {
      __typename: "PlacedOrderInfo";
      hoaUUID: string;
      confirmationNumber: string;
      confirmationEmailSentTo: string;
      totalAmount: number;
      totalPaid: number;
      totalDueAtClose: number;
      completionStatus: OrderCompletionStatus;
      dueDate: string | null;
      presaleDocumentsInfo: Array<{
        __typename: "PresaleDocumentsGroup";
        mgmtCompanyComment: string | null;
        documents: Array<{
          __typename: "PresaleDocument";
          id: string;
          label: string;
          url: string;
        }>;
      }> | null;
    } | null;
  };
};

export type PayForOrderPricingSummaryQueryVariables = Exact<{
  orderUUID: Scalars["ID"]["input"];
  paymentMethod: PaymentMethod;
}>;

export type PayForOrderPricingSummaryQuery = {
  payForOrderPricingSummary:
    | {
        __typename: "PayForOrderPricingSummary";
        taxDisplayName: string | null;
        order: {
          __typename: "PricingSummaryBreakdown";
          beforeTax: { __typename: "Price"; amount: number; formatted: string };
          tax: { __typename: "Price"; amount: number; formatted: string };
          afterTax: { __typename: "Price"; amount: number; formatted: string };
        };
        creditCardFee: {
          __typename: "Price";
          amount: number;
          formatted: string;
        } | null;
        total: {
          __typename: "PricingSummaryBreakdown";
          beforeTax: { __typename: "Price"; amount: number; formatted: string };
          tax: { __typename: "Price"; amount: number; formatted: string };
          afterTax: { __typename: "Price"; amount: number; formatted: string };
        };
        refundPolicyInfo: {
          __typename: "RefundPolicyInfo";
          refundPolicyMessage: GQL_Client_NonEmptyString | null;
          nonRefundableFeesMessage: GQL_Client_NonEmptyString | null;
        };
      }
    | {
        __typename: "PayForOrderPricingSummaryError";
        code: PayForOrderPricingSummaryErrorCode;
        message: string;
      };
};

export type PayForOrderMutationVariables = Exact<{
  input: PayForOrderInput;
}>;

export type PayForOrderMutation = {
  payForOrder: {
    __typename: "PayForOrderMutationResponse";
    success: boolean;
    error: { __typename: "GenericMutationError"; message: string } | null;
    data: { __typename: "PayForOrderMutationData"; orderUUID: string } | null;
  };
};

export type IsPayNowEnabledQueryVariables = Exact<{
  hoaUUID: Scalars["ID"]["input"];
}>;

export type IsPayNowEnabledQuery = {
  hoa: { __typename: "HOA"; uuid: string; isPayNowEnabled: boolean };
};

export type DeveloperQueryVariables = Exact<{
  hoaUUID: Scalars["ID"]["input"];
}>;

export type DeveloperQuery = {
  hoa: {
    __typename: "HOA";
    uuid: string;
    allDevelopers: Array<{
      __typename: "Developer";
      id: string;
      name: string;
    }> | null;
  };
};

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars["String"]["input"];
  passwordResetToken: Scalars["String"]["input"];
}>;

export type ResetPasswordMutation = {
  resetPassword: {
    __typename: "ResetPasswordMutationResponse";
    success: boolean;
    error: { __typename: "GenericMutationError"; message: string } | null;
  };
};

export type VerifyAddressQueryVariables = Exact<{
  input: VerifyAddressInput;
}>;

export type VerifyAddressQuery = {
  verifyAddress:
    | {
        __typename: "AddressVerificationError";
        errorCode: AddressVerificationErrorCode;
      }
    | {
        __typename: "VerifiedHomeWiseAddress";
        hoaUnit: {
          __typename: "HOAUnit";
          uuid: string;
          streetAddress: string;
          unit: string | null;
          city: string;
          state: string;
          zip: string;
          hoa: {
            __typename: "HOA";
            uuid: string;
            name: string;
            status: HoaStatus;
            city: string;
            state: string;
            zip: string;
            allDevelopers: Array<{
              __typename: "Developer";
              id: string;
              name: string;
            }> | null;
            company: {
              __typename: "ManagementCompany";
              uuid: string;
              name: string;
              logoUrl: string | null;
            };
          };
        };
      }
    | {
        __typename: "VerifiedHomeWiseAddressWrongHOA";
        matchedAddress: {
          __typename: "MatchedAddress";
          streetAddress: string;
          unit: string | null;
          city: string;
          state: string;
          zip: string;
        };
        matchedHoaUnits: Array<{
          __typename: "HOAUnit";
          uuid: string;
          streetAddress: string;
          unit: string | null;
          city: string;
          state: string;
          zip: string;
          hoa: {
            __typename: "HOA";
            uuid: string;
            name: string;
            status: HoaStatus;
            city: string;
            state: string;
            zip: string;
            allDevelopers: Array<{
              __typename: "Developer";
              id: string;
              name: string;
            }> | null;
            company: {
              __typename: "ManagementCompany";
              uuid: string;
              name: string;
              logoUrl: string | null;
            };
          };
        }>;
      }
    | {
        __typename: "VerifiedPostalAddress";
        matchType: PostalServiceMatchType;
        matchedAddress: {
          __typename: "MatchedAddress";
          streetAddress: string;
          unit: string | null;
          city: string;
          state: string;
          zip: string;
        };
      };
};

export type HoaSearchResultsQueryVariables = Exact<{
  hoaUUID: Scalars["ID"]["input"];
}>;

export type HoaSearchResultsQuery = {
  hoa: {
    __typename: "HOA";
    uuid: string;
    name: string;
    status: HoaStatus;
    city: string;
    state: string;
    zip: string;
    allDevelopers: Array<{
      __typename: "Developer";
      id: string;
      name: string;
    }> | null;
    company: {
      __typename: "ManagementCompany";
      uuid: string;
      name: string;
      logoUrl: string | null;
    };
  };
};

export type HoaUnitSearchResultsQueryVariables = Exact<{
  hoaUnitUUID: Scalars["ID"]["input"];
}>;

export type HoaUnitSearchResultsQuery = {
  hoaUnit: {
    __typename: "HOAUnit";
    status: AddressStatus;
    uuid: string;
    streetAddress: string;
    unit: string | null;
    city: string;
    state: string;
    zip: string;
    hoa: {
      __typename: "HOA";
      uuid: string;
      name: string;
      status: HoaStatus;
      city: string;
      state: string;
      zip: string;
      allDevelopers: Array<{
        __typename: "Developer";
        id: string;
        name: string;
      }> | null;
      company: {
        __typename: "ManagementCompany";
        uuid: string;
        name: string;
        logoUrl: string | null;
      };
    };
  };
};

export type HoaLocationFragment = {
  __typename: "HOA";
  city: string;
  state: string;
  zip: string;
};

export type HoaAndCompanyFragment = {
  __typename: "HOA";
  uuid: string;
  name: string;
  status: HoaStatus;
  city: string;
  state: string;
  zip: string;
  allDevelopers: Array<{
    __typename: "Developer";
    id: string;
    name: string;
  }> | null;
  company: {
    __typename: "ManagementCompany";
    uuid: string;
    name: string;
    logoUrl: string | null;
  };
};

export type HoaUnitAddressFragment = {
  __typename: "HOAUnit";
  uuid: string;
  streetAddress: string;
  unit: string | null;
  city: string;
  state: string;
  zip: string;
};

export type SignUpMutationVariables = Exact<{
  input: SignUpMutationInput;
}>;

export type SignUpMutation = {
  signUp: {
    __typename: "SignUpMutationResponse";
    success: boolean;
    error: { __typename: "GenericMutationError"; message: string } | null;
  };
};

export type TransactionInfoFieldsFragment = {
  __typename: "TransactionInfoFields";
  displayBuyerOccupied: boolean;
  displayLoanNumber: boolean;
  resaleFormOptions: Array<{
    __typename: "ResaleFormOption";
    id: string;
    label: string;
    required: boolean;
    isMasterField: boolean;
  }>;
};

export type TransactionDetailsQueryVariables = Exact<{
  hoaUUID: Scalars["ID"]["input"];
  selectedCategories: Array<ProductCategoryType> | ProductCategoryType;
  transactionType: TransactionType;
}>;

export type TransactionDetailsQuery = {
  hoa: {
    __typename: "HOA";
    uuid: string;
    acknowledgements: Array<{
      __typename: "Acknowledgement";
      id: string;
      message: string;
    }> | null;
    transactionInfoFields: {
      __typename: "TransactionInfoFields";
      displayBuyerOccupied: boolean;
      displayLoanNumber: boolean;
      resaleFormOptions: Array<{
        __typename: "ResaleFormOption";
        id: string;
        label: string;
        required: boolean;
        isMasterField: boolean;
      }>;
    };
  };
};

export const namedOperations = {
  Query: {
    AddedRushOptionsQuery: "AddedRushOptionsQuery",
    CreditCardFeeInfoQuery: "CreditCardFeeInfoQuery",
    HoaAndCompanyNameQuery: "HoaAndCompanyNameQuery",
    InfoForUpdateRequestQuery: "InfoForUpdateRequestQuery",
    LendersAndLsqFeeQuery: "LendersAndLsqFeeQuery",
    PayByInvoiceQuery: "PayByInvoiceQuery",
    OrderPricingSummaryQuery: "OrderPricingSummaryQuery",
    ProductsAndBundlesQuery: "ProductsAndBundlesQuery",
    ResaleFormOptionsQuery: "ResaleFormOptionsQuery",
    SubAndMasterHoasQuery: "SubAndMasterHoasQuery",
    UpFrontFormFeesQuery: "UpFrontFormFeesQuery",
    ProcessingOptionsAndShippingFeesQuery:
      "ProcessingOptionsAndShippingFeesQuery",
    AnnouncementQuery: "AnnouncementQuery",
    EditProfileQuery: "EditProfileQuery",
    ViewProfileQuery: "ViewProfileQuery",
    UsersListQuery: "UsersListQuery",
    MyOrdersQuery: "MyOrdersQuery",
    OrderRefundPolicyQuery: "OrderRefundPolicyQuery",
    ModifyBuyerOrderTransactionDetailsQuery:
      "ModifyBuyerOrderTransactionDetailsQuery",
    ModifyOrderOrderTransactionDetailsQuery:
      "ModifyOrderOrderTransactionDetailsQuery",
    OrderDetailsQuery: "OrderDetailsQuery",
    OrderStatusQuery: "OrderStatusQuery",
    AddRushPricingSummaryQuery: "AddRushPricingSummaryQuery",
    OrderUpdatePricingSummaryQuery: "OrderUpdatePricingSummaryQuery",
    PayForOrderPricingSummaryQuery: "PayForOrderPricingSummaryQuery",
    IsPayNowEnabledQuery: "IsPayNowEnabledQuery",
    DeveloperQuery: "DeveloperQuery",
    VerifyAddressQuery: "VerifyAddressQuery",
    HoaSearchResultsQuery: "HoaSearchResultsQuery",
    HoaUnitSearchResultsQuery: "HoaUnitSearchResultsQuery",
    TransactionDetailsQuery: "TransactionDetailsQuery",
  },
  Mutation: {
    PlaceOrderMutation: "PlaceOrderMutation",
    RequestPasswordResetEmailMutation: "RequestPasswordResetEmailMutation",
    RequestForgotUsernameEmailMutation: "RequestForgotUsernameEmailMutation",
    UploadLsqMutation: "UploadLsqMutation",
    LoginMutation: "LoginMutation",
    ChangePasswordMutation: "ChangePasswordMutation",
    CreateUserMutation: "CreateUserMutation",
    UpdateProfileMutation: "UpdateProfileMutation",
    DeleteUserMutation: "DeleteUserMutation",
    AttachDocumentToOrderMutation: "AttachDocumentToOrderMutation",
    CancelOrderMutation: "CancelOrderMutation",
    ModifyBuyerMutation: "ModifyBuyerMutation",
    ModifyOrderMutation: "ModifyOrderMutation",
    AddRushMutation: "AddRushMutation",
    RequestOrderUpdateMutation: "RequestOrderUpdateMutation",
    PayForOrderMutation: "PayForOrderMutation",
    ResetPasswordMutation: "ResetPasswordMutation",
    SignUpMutation: "SignUpMutation",
  },
  Fragment: {
    CreditCardFeeInfoFragment: "CreditCardFeeInfoFragment",
    FormUpdateInfoFragment: "FormUpdateInfoFragment",
    PricingSummaryBreakdownFragment: "PricingSummaryBreakdownFragment",
    PriceFragment: "PriceFragment",
    RefundPolicyInfoFragment: "RefundPolicyInfoFragment",
    IndividualItemFragment: "IndividualItemFragment",
    BundleFragment: "BundleFragment",
    ResaleFormOptionsFragment: "ResaleFormOptionsFragment",
    UpFrontFormFeeFragment: "UpFrontFormFeeFragment",
    PlacedOrderInfoFragment: "PlacedOrderInfoFragment",
    RushOptionFragment: "RushOptionFragment",
    RequestorFragment: "RequestorFragment",
    OrderTransactionDetails: "OrderTransactionDetails",
    PresaleDocumentsInfoFragment: "PresaleDocumentsInfoFragment",
    OrderItemFragment: "OrderItemFragment",
    BaseOrderFragment: "BaseOrderFragment",
    PendingOrderFragment: "PendingOrderFragment",
    CompletedOrderFragment: "CompletedOrderFragment",
    CanceledOrderFragment: "CanceledOrderFragment",
    CanceledOrderSansCommentsFragment: "CanceledOrderSansCommentsFragment",
    UnpaidOrderFragment: "UnpaidOrderFragment",
    CurrentOwnerFragment: "CurrentOwnerFragment",
    ResaleFormOptionValuesFragment: "ResaleFormOptionValuesFragment",
    BuyerFragment: "BuyerFragment",
    AddedRushOrderData: "AddedRushOrderData",
    HoaLocationFragment: "HoaLocationFragment",
    HoaAndCompanyFragment: "HoaAndCompanyFragment",
    HoaUnitAddressFragment: "HoaUnitAddressFragment",
    TransactionInfoFieldsFragment: "TransactionInfoFieldsFragment",
  },
};
